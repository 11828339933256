import { Skeleton } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Button,
    Drawer,
    CheckPicker,
    DatePicker,
    SelectPicker,
    Input,
    Alert,
} from "rsuite";
import { AuthContext } from "../../../../auth/AuthContext";
import { useWindowsSize } from "../../../../hooks/WindowsSize";
import { BASE_PATH } from "../../../constants";
import ScheduleCard from "./components/card";
import { RiAddFill } from "react-icons/ri";
import {
    dateOptions,
    daysOfWeek,
    daysOfWeekObject,
    getFormatTime,
    getMaximDueDate,
    periodicities,
    periodicitiesObject,
    setTimeInDate,
} from "./utils";

const initStateScheduleForm = {
    periodicity: "",
    time: {
        dateFormat: new Date(),
        timeFormat: "",
    },
    days: [],
    title: "",
    from: new Date(),
    dueDate: getMaximDueDate(new Date()),
    start: "",
};

const Schedules = () => {
    const { experienciaId } = useParams();
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const [wSize] = useWindowsSize();

    const [experienceData, setexperienceData] = useState(null);
    const [schedules, setschedules] = useState([]);
    const [loading, setloading] = useState(true);
    const [showModal, setshowModal] = useState(false);

    const [scheduleForm, setScheduleForm] = useState(initStateScheduleForm);

    const getExperience = async () => {
        try {
            const resp = await axios.get(`${BASE_PATH}/experiences/${experienciaId}`);
            if (resp.status === 200) {
                setexperienceData(resp.data);
                getSchedules();
            }
        } catch (e) {
            console.log(e);
            // history.replace({ pathname: `/host/_/${user.data.user.id}` });
        }
    };

    const getSchedules = async () => {
        try {
            const resp = await axios.get(
                `${BASE_PATH}/schedules?experience=${experienciaId}`
            );

            if (resp.status === 200) {
                setschedules(resp.data.rows.filter((el) => el.isEnabled));
                setloading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };



    const navigateToBack = () => {
        history.replace({ pathname: `/host/experiencia/_/${experienciaId}` });
    };

    const handleChangeFormatHours = (time) => {
        let newDate = new Date(time);
        let hours = newDate.getHours(),
            minutes = newDate.getMinutes();
        let newTime = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
        console.log(newTime);
        setScheduleForm({
            ...scheduleForm,
            time: { dateFormat: time, timeFormat: newTime },
        });
    };

    const handleChangeScheduleForm = (name, value) => {
        if (name === "from") {
            setScheduleForm({
                ...scheduleForm,
                [name]: value,
                dueDate: getMaximDueDate(new Date(value)),
            });
        } else if (name === "periodicity" && value === "daily") {
            setScheduleForm({
                ...scheduleForm,
                [name]: value,
                days: [0, 1, 2, 3, 4, 5, 6],
            });
        } else {
            setScheduleForm({ ...scheduleForm, [name]: value });
        }
    };

    //62f14e5c9bc9920824907ed8

    const selectSchedule = (schedule) => {
        let time = setTimeInDate(schedule.start || "00:00", new Date());
        let data = {
            periodicity: schedule.periodicity.repeat,
            time: {
                dateFormat: time,
                timeFormat: schedule.start,
            },
            days: schedule.periodicity.days,
            title: schedule.name,
            from: schedule.date,
            dueDate: schedule.dueDate || getMaximDueDate(new Date(schedule.date)),
            id: schedule.id,
        };

        setScheduleForm(data);
        setshowModal(true);
    };

    const onSaveNewSchedule = async () => {
        const { days, dueDate, periodicity, from, time, title } = scheduleForm;

        if (
            !!!days ||
            !!!periodicity ||
            !!!from ||
            !!!dueDate ||
            !!!title ||
            !!!time.dateFormat
        ) {
            Alert.warning("Completa todos los campos", 3000);
            return;
        }

        const newScheduleData = {
            experience: experienciaId,
            user: user.data.user.id,
            periodicity: {
                days,
                repeat: periodicity,
            },
            dueDate,
            date: from,
            name: title,
            start: getFormatTime(time.timeFormat),
        };
        try {
            const resp = await axios.post(`${BASE_PATH}/schedules`, newScheduleData, {
                headers: {
                    Authorization: `Bearer ${user.data.token}`,
                },
            });

            if (resp.status === 201) {
                getSchedules();
                setshowModal(false);
                setScheduleForm(initStateScheduleForm);
                Alert.success("Se ha creado el nuevo horario", 3000);
            } else {
                Alert.error("Algo salió mal, inténtalo de nuevo", 3000);
            }
        } catch (error) {
            console.log(error);
            Alert.error("Algo salió mal, inténtalo de nuevo", 3000);
        }
    };

    const onUpdateSchedule = async () => {
        const { days, dueDate, periodicity, from, time, title, id } = scheduleForm;

        if (
            !!!days ||
            !!!periodicity ||
            !!!from ||
            !!!dueDate ||
            !!!title ||
            !!!time.dateFormat
        ) {
            Alert.warning("Completa todos los campos", 3000);
            return;
        }

        try {
            const newScheduleData = {
                experience: experienciaId,
                periodicity: {
                    days,
                    repeat: periodicity,
                },
                dueDate,
                date: from,
                name: title,
                start: getFormatTime(time.timeFormat),
            };

            const resp = await axios.put(
                `${BASE_PATH}/schedules/${id}`,
                newScheduleData,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            );

            if (resp.status === 200) {
                getSchedules();
                setshowModal(false);
                setScheduleForm(initStateScheduleForm);
                Alert.success("Se ha actualizado el horario", 3000);
            } else {
                Alert.error("Algo salió mal, inténtalo de nuevo", 3000);
            }
        } catch (error) {
            console.log(error);
            Alert.error("Algo salió mal, inténtalo de nuevo", 3000);
        }
    };

    const handleClickSave = () => {
        const { id } = scheduleForm;
        if (!id) {
            onSaveNewSchedule();
            return;
        }

        onUpdateSchedule();
        //
    };

    const handleNewSchedule = () => {
        setScheduleForm(initStateScheduleForm);
        setshowModal(true);
    };

    const handeDeleteSchedule = async (id) => {
        const resp = await axios.delete(
            `${BASE_PATH}/schedules/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${user.data.token}`,
                },
            }
        );

        if(resp.status === 204){
            getSchedules()
        }
    }

    const evaluateDays = (uniqueDate) => {
        return (
            new Date(uniqueDate).getTime() <= new Date(scheduleForm.from).getTime() ||
            new Date(uniqueDate).getTime() >=
            new Date(getMaximDueDate(scheduleForm.from).getTime())
        );
    };

    useEffect(() => {
        if (user.logged) {
            getExperience();
        }
    }, [user]);

    return (
        <>
            <nav className="navbar-titles navbar-light d-flex align-items-center">
                <button
                    type="button"
                    className="back-window-navbar"
                    onClick={navigateToBack}
                >
                    <i className="s icon-left-arrow"></i>
                </button>
                <h5 className="bold">
                    Agrega o edita horarios {/* disponible para{" "}
                    {experienceData?.name || ""}*/}
                </h5>
            </nav>

            <div className="schedules-page-content-list">
                <div className="schedules-page-content-list-grid">
                    {loading &&
                        [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                            <article className="schedule-item" key={index}>
                                <Skeleton title={false} loading={loading} active></Skeleton>
                                <Skeleton title={false} loading={loading} active></Skeleton>
                                <Skeleton title={false} loading={loading} active></Skeleton>
                            </article>
                        ))}
                </div>

                <div className="schedules-page-content-list-grid">
                    {!loading &&
                        schedules.map((item, i) => (
                            <ScheduleCard
                                key={i}
                                title={item.name || "Horario sin título"}
                                days={
                                    item.periodicity
                                        ? item.periodicity.days
                                            .map((day) => daysOfWeekObject[day])
                                            .join(", ")
                                        : "no se han definido"
                                }
                                time={item.start || "no se ha definido"}
                                from={new Date(item.date).toLocaleDateString(
                                    "es-ES",
                                    dateOptions
                                )}
                                to={new Date(item.dueDate).toLocaleDateString(
                                    "es-ES",
                                    dateOptions
                                )}
                                repeat={
                                    periodicitiesObject[item.periodicity.repeat] ||
                                    "no se ha definido"
                                }
                                onSelect={() => selectSchedule(item)}
                                onDelete={handeDeleteSchedule}
                                id={item.id}
                            />
                        ))}
                </div>

                {!loading && schedules.length === 0 && (
                    <h6
                        style={{ textAlign: "center", marginTop: "100px" }}
                        className="bold"
                    >
                        Aún no has agregado horarios
                    </h6>
                )}
            </div>

            <Drawer
                size="sm"
                full={wSize <= 800}
                placement={wSize > 800 ? "right" : "bottom"}
                show={showModal}
                onHide={() => setshowModal(false)}
            >
                <Drawer.Header>
                    <Drawer.Title className="bold" style={{ fontSize: "20px" }}>
                        ¡{scheduleForm.id ? "Actualiza este" : "Agrega un nuevo"} horario!
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className="schedules-drawer-body">
                    <div className="schedules-form-control">
                        <span>Agrega un título para que se te haga fácil recordarlo!</span>
                        <Input
                            placeholder="Titulo"
                            block
                            value={scheduleForm.title}
                            onChange={(value) => handleChangeScheduleForm("title", value)}
                        />
                    </div>

                    <div className="schedules-form-control schedules-form-control-mt20">
                        <span>Repetir cada</span>
                        <SelectPicker
                            placeholder="Seleccionar"
                            placement="autoVertical"
                            searchable={false}
                            data={periodicities}
                            block
                            value={scheduleForm.periodicity}
                            onChange={(value) =>
                                handleChangeScheduleForm("periodicity", value)
                            }
                        />
                    </div>

                    <div className="schedules-form-control schedules-form-control-mt20">
                        <span>Elige los dias para tu horario</span>
                        <CheckPicker
                            placeholder="Seleccionar"
                            placement="autoVertical"
                            searchable={false}
                            data={daysOfWeek}
                            block
                            value={scheduleForm.days}
                            onChange={(value) => handleChangeScheduleForm("days", value)}
                        />
                    </div>

                    <div className="schedules-form-control schedules-form-control-mt20">
                        <span>Hora de Inicio</span>
                        <DatePicker
                            placeholder="Seleccionar"
                            placement="autoVertical"
                            format="HH:mm"
                            cleanable={false}
                            value={new Date(scheduleForm.time.dateFormat)}
                            block
                            ranges={[]}
                            showMeridian
                            onChange={handleChangeFormatHours}
                        />
                    </div>

                    <div className="schedules-form-control schedules-form-control-mt20">
                        <span>Fecha de inicio</span>
                        <DatePicker
                            placeholder="Seleccionar"
                            block
                            placement="autoVertical"
                            cleanable={false}
                            value={new Date(scheduleForm.from)}
                            onChange={(value) => handleChangeScheduleForm("from", value)}
                            disabledDate={(uniqueDate) =>
                                new Date(uniqueDate).getTime() <= new Date().getTime()
                            }
                        />
                    </div>

                    <div
                        className="schedules-form-control schedules-form-control-mt20"
                        style={{ marginBottom: "25px" }}
                    >
                        <span>
                            Disponible hasta (hasta 6 meses despues de la fecha de inicio)
                        </span>
                        <DatePicker
                            placeholder="Seleccionar"
                            block
                            placement="autoVertical"
                            value={new Date(scheduleForm.dueDate)}
                            cleanable={false}
                            onChange={(value) => handleChangeScheduleForm("dueDate", value)}
                            disabledDate={evaluateDays}
                        />
                    </div>
                </Drawer.Body>
                <Drawer.Footer>
                    <Button className="schedule-neon-btn" onClick={handleClickSave}>
                        Guardar
                    </Button>
                    <Button onClick={() => setshowModal(false)} appearance="subtle">
                        Cancelar
                    </Button>
                </Drawer.Footer>
            </Drawer>

            <div className="d-flex justify-content-center exp-fixed-upload">
                <button
                    type="button"
                    className="exp-fixed-bottom-open-host-upload"
                    onClick={handleNewSchedule}
                >
                    <RiAddFill style={{ fontSize: 40 }} />
                </button>
            </div>
        </>
    );
};

export default Schedules;
