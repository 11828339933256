import React from 'react'

const ScheduleCard = ({ dayString, dayNumber, time, onSelect, isSelected }) => {
    return (
        <article className={`${isSelected ? "schedule-item schedule-item-hover schedule-item-active" : "schedule-item schedule-item-hover"}`} onClick={onSelect}>

            <div className="bold schedule-item-year-name__day">
                <span>{dayString}</span>
            </div>
            <div className="heavy schedule-item-year-name">
                <text className='red'>{dayNumber}</text>
            </div>
            <div className=" schedule-item-year-name__time">
                <span>{time}</span>
            </div>
        </article>
    )
}

ScheduleCard.defaultProps = {
    dayString: "",
    dayNumber : "",
    time: "",
    onSelect: () => { },
    isSelected: false
}


export default ScheduleCard