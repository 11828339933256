import React, { useState, useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ListaExperiencias } from "../Experiencias/exp/ListaExperiencias";
import axios from "axios";
import { BASE_PATH } from "../constants";

import { Message, Icon } from "rsuite";
import { Search } from "@chatscope/chat-ui-kit-react";

import logo from "../../assets/images/icono.svg";
import noEncontrado from "../../assets/images/noEncontrado.png";
import Viajero from "../Navbars/Viajero";

// import logo from "../../assets/images/icono.svg";
// import noEncontrado from "../../assets/images/noEncontrado.svg";

const Explora = () => {
  const [searcher, setSearcher] = useState("");
  const [experiencias, setExperiencias] = useState([]);
  const [expFilter, setExpFilter] = useState([]);
  const [bestRating, setbestRating] = useState([]);

  const searchExp = (word) => {
    //console.log('Estoy buscado algo')
    setSearcher(word);
    findExp(word);
  };

  const findExp = (word) => {
    let filter = experiencias.filter((e) =>
      e.name.toString().toLowerCase().includes(word.toLowerCase())
    );
    setExpFilter(filter);
  };

  const getExperiencias = async () => {
    try {
      let res = await axios.get(`${BASE_PATH}/experiences`);
      if (res.status === 200) {
        // console.log("todas => ", [...res.data.rows]);
        setExperiencias([...res.data.rows]);
        let bestR = res.data.rows
          .sort((a, b) => a.rating - b.rating)
          .reverse()
          .splice(0, 5);
        //console.log("lo mejor => ", [...bestR]);
        setbestRating([...bestR]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getExperiencias();
  }, []);

  return (
    <div>
      <div
        className="search-movil d-none d-md-block d-lg-none d-sm-block 
            d-md-none d-block d-sm-none"
      >
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className="navbar-sm-more navbar-light fixed-top"
        >
          <img src={logo} className="logo_in2" alt="logo" />
          <Search
            style={{ marginLeft: "1.5rem", fontSize: "25px" }}
            placeholder="Prueba Surf..."
            onChange={searchExp}
          />
        </nav>
      </div>

      <div>
        <Viajero searchExp={searchExp} />
      </div>

      {searcher != "" && (
        <div className="search-instant">
          <ListaExperiencias exploring experiencias={expFilter}></ListaExperiencias>
        </div>
      )}

      {searcher != "" && expFilter.length === 0 && (
        <div style={{ display: "block", textAlign: "center", width: "100%" }}>
          <Message
            type="info"
            title=""
            description="¡Aún no contamos con esta experiencia!, tomaremos nota."
            style={{
              background: "none",
            }}
          ></Message>
          <img src={noEncontrado} alt="No encontrado" className="notfound" />
        </div>
      )}

      <section className="container">
        <h1 className="bold explora-titulos mb-2">
          Experiencias <br />
          <p>mejor evaluadas</p>
        </h1>

        {bestRating.length > 0 && (
          <OwlCarousel
            className="owl-carousel"
            loop={bestRating.length < 5 ? false : true}
            margin={20}
            dots={true}
            responsive={{
              1200: {
                items: 4,
              },
              800: {
                items: 4,
              },
              650: {
                items: 3,
              },
              450: {
                items: 2,
              },
              300: {
                items: 2,
              },
            }}
          >
            {bestRating.map((item) => (
              <Link to={`/experiencia/${item.id}`} key={item.id}>
                <div>
                  <Card className="pointer border-0 image04">
                    <Card.Img
                      src={item.pictures[0]?.path}
                      className="card-border"
                    />
                    <Card.ImgOverlay>
                      <Image
                        src={item.user?.infoExtra?.picture ? item.user.infoExtra.picture : item.user.picture }
                        roundedCircle
                        className="avatar"
                      />
                      <div className="card-bottom">
                        <h3 className="bold text-white card-titulo">
                          {item.name}
                        </h3>
                        <text
                          className="text-white bold d-none 
                                                    d-md-none d-lg-block card-precio"
                        >
                          $<p>{item.price.adultPriceWithCommissions || item.price.adultPrice}</p> por persona
                        </text>
                        <p className="place">
                          <i className="icon-marker"></i>
                          {"   "}
                          {item.direction}
                        </p>
                        <p
                          className="text-white bold 
                                                    d-none d-md-none d-lg-block"
                        >
                          <i className="icon-star orange"></i>
                          {"   "}
                          {item.rating}
                        </p>
                      </div>
                    </Card.ImgOverlay>
                  </Card>
                </div>
              </Link>
            ))}
          </OwlCarousel>
        )}
        <div className="text-center">
          {/*<Icon icon="arrows-h" size="3x" className="text-gray" />
          <br />*/}
          <text style={{ fontSize: "12px", color: "grey" }}>
            Desliza las experiencias de lado a lado
          </text>
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};

export default Explora;
