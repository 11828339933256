import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import axios from "axios";
import moment from "moment";
import { AuthContext } from "../../auth/AuthContext";
import { BASE_PATH } from "../constants";
import { Button, Alert, Modal, Checkbox, Loader, InputPicker } from "rsuite";

// Importar Recursos
import Viajero from "../Navbars/Viajero";
import logo from "../../assets/images/icono.svg";
import nft from "../../assets/images/nft.png";
import money from "../../assets/images/money-bag.png";

const NFT = () => {
 
  return (
    <section className="page-back-1">
    <div className="container" style={{ paddingBottom: 140 }}>
      <Viajero />
      <section className="sm_nav">
        <div className="d-none d-md-block d-lg-none d-sm-block d-md-none d-block d-sm-none">
          <nav
            className=" navbar-sm-more navbar-light fixed-top"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              width: "100%",
              paddingLeft: "3.5rem",
            }}
          >
            <img src={logo} className="logo_in2" alt="logo" />
            <h4 className="bold" style={{ paddingTop: "1rem" }}>
              Tus NFT's
            </h4>
          </nav>
        </div>
        <br />
        <br />
      </section>
      <section>
      <div className="page-header-container-nft">
        <Row>
          <Col lg={7} xs={12}>
            <h3 className="black heavy">PRÓXIMAMENTE</h3>
            <p className="bold black">
            <text className="heavy">Tuguis Coleccionables</text>, con ellos acumularás ganancias monetarias que podrás canjear por experiencias turísticas
            de la mano de nuestros anfitriones.
            </p>
            <p className="bold black">
              ¡Podrás autofinanciar tu vida aventurera con nuestra <h4 className="heavy black">¡COLECCIÓN NFT!</h4>
            </p>
          </Col>
          <Col>
          <Row className="mb-3 ">
          <Col lg={4} xs={4} className="text-grey bold nft_data_inside"><img src={nft} className="nft"/> 0</Col>
          <Col className="text-grey bold nft_data_inside"><img src={money} className="nft"/> $ 0000.00</Col>
        </Row>
        <text className="text-grey bold">Ganancias Disponibles</text>
         <h3 className="bold orange">$ 0.00</h3>
          </Col>
        </Row>
        
        
      </div>
      </section>
    </div>
    </section>
  );
};

export default NFT;
