
import React, { useState, useEffect, useRef } from 'react'
import { Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import axios from 'axios';
import { BASE_PATH } from '../constants';
import { Alert, Form, Schema, FormGroup, FormControl } from 'rsuite';
import { StringType } from "schema-typed";
import "./style.css";

//Importar Recursos
import logo from '../../assets/images/icono.svg';

const CreateUser = () => {

    const history = useHistory()
    const ToBack = () => {
        history.replace({ pathname: "/register" })
    }


    // states
    const [phone, setPhone] = useState('')
    //for the user form...
    const userForm = {
        email: '',
        password: '',
        password_confirmation: '',
        name: '',
        phone: ''

    }
    const modelForm = Schema.Model({

        name: StringType().isRequired("Campo obligatorio."),
        password: StringType().addRule((value, data) => {

            return value.length > 6

        }, 'Elija una contraseña mas segura').isRequired('Campo obligatorio'),
        phone: StringType().isRequired('Campo obligatorio'),
        email: StringType().isRequired('Campo obligatorio'),
        password_confirmation: StringType().addRule((value, data) => {
            //console.log(data);
            return value === data.password
        }, 'Las contraseñas no coinciden').isRequired('Campo obligatorio')
    })
    const getRequestBody = () => {

        return {

            "name": modalForm.name,
            "password": modalForm.password,
            "phone": modalForm.phone,
            "email": modalForm.email

        }

    }
    const formRef = useRef()
    const [modalForm, setModalForm] = useState(userForm)

    const setNewUser = async () => {

        setModalForm({...modalForm, phone:phone})


        if (!formRef.current.check()) {
            Alert.warning("Campos requeridos vacios.", 2000)

        } else {
            try {
                let tutor = await axios.post(`${BASE_PATH}/users`, getRequestBody())
                if (tutor.status === 201) {
                    Alert.success("Usuario creado exitosamente", 2000)
                    sessionStorage.removeItem('phone');
                    history.replace({ pathname: "/login" })

                }
            } catch (e) {
                console.log(e)
                if (e.response) {
                    if (e.response.status === 404 || e.response.status === 400) {
                        //  console.log(e.response.data.message);
                        Alert.success(e.response.data.message, 2000)
                        //console.log(e.response.data.message)
                    }

                }

            }
        }
    }

        //get phone number
        useEffect(() => {
            setPhone(sessionStorage.getItem('phone'))
            setModalForm({...modalForm, phone:sessionStorage.getItem('phone')})
    
        }, [])
    return (

        <div className="d-flex justify-content-center
            d-flex align-items-center vh-100">
            <button type="button" className="back-window fixed-top" onClick={ToBack}>
                <i className="demo-icon-exp  icon-left-arrow"></i>
            </button>
            <Card style={{ width: '18em' }} className="border-0">
                <img src={logo} className="login-logo" alt="logo" />
                <Card.Body>
                    <Card.Title className="bold login-title text-center">
                        Termina de ingresar tus datos personales
                        <br />

                    </Card.Title>

                    <Card.Text>
                        <Form layout="horizontal" ref={formRef}
                            fluid
                            onChange={(formValue) => setModalForm(formValue)}
                            model={modelForm}
                            formValue={modalForm}>
                            <FormGroup>
                                <FormControl name="phone" value={phone} placeholder="Tel" disable />
                            </FormGroup>
                            <FormGroup>
                                <FormControl placeholder="Nombre" name="name" />
                            </FormGroup>
                            <FormGroup>
                                <FormControl name="email" placeholder="Email" type="email" />
                            </FormGroup>
                            <FormGroup>
                                <FormControl name="password" placeholder="Contraseña" type="password" />
                            </FormGroup>
                            <FormGroup>
                                <FormControl name="password_confirmation" placeholder="Repetir contraseña" type="password" />
                            </FormGroup>

                            <p className="lite login-text-redes text-center" class="btn btn-primary" onClick={setNewUser}
                                style={{ marginTop: 10 }}>
                                Guardar
                            </p>
                        </Form>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CreateUser;