import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { BASE_PATH, appIDFacebook } from "../constants";
import axios from "axios";
import { Button } from "react-bootstrap";

//Importar Recursos
import logo from "../../assets/images/icono.svg";
import { Alert, Notification, ButtonToolbar } from "rsuite";
import { AuthContext } from "../../auth/AuthContext";
import { types } from "../../types/types";

const Login_code = () => {
  const { dispatch } = useContext(AuthContext);

  const history = useHistory();
  const responseGoogle = async (response) => {
    try {
      let Login = await axios.post(`${BASE_PATH}/auth/google`, {
        access_token: response.accessToken,
      });
      if (Login.status === 201) {
        let user = Login.data.user;
        if (
          !user.alias ||
          !user.birthday ||
          !user.description ||
          !user.direction ||
          !user.gender ||
          !user.phone
        ) {
          setNotification();
        } else {
          Alert.success("Logeado!", 2000);
        }
        dispatch({
          type: types.login,
          payload: Login,
        });
        history.push("/");
      }
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 404 || e.response.status === 400) {
          //  console.log(e.response.data.message);
          Alert.warning(e.response.data.message, 2000);
        }
      }
    }
  };

  const responseFacebook = async (response) => {
    try {
      let loginFacebook = await axios.post(`${BASE_PATH}/auth/facebook`, {
        access_token: response.accessToken,
      });

      if (loginFacebook.status == 201) {
        let user = loginFacebook.data.user;
        if (
          !user.alias ||
          !user.birthday ||
          !user.description ||
          !user.direction ||
          !user.gender ||
          !user.phone
        ) {
          setNotification();
        } else {
          Alert.success("Logeado!", 2000);
        }
        dispatch({
          type: types.login,
          payload: loginFacebook,
        });
        history.push("/");
      } else {
        Alert.warning("Fallo en la inicio de sesion, vuelva a intentar", 2000);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 404 || e.response.status === 400) {
          Alert.warning(e.response.data.message, 2000);
        }
      }
    }
  };

  const setNotification = () => {
    Notification.open({
      title: "Recordatorio",
      duration: 30000,
      description: (
        <div>
          <p>Termina de configurar tu cuenta!</p>
          <ButtonToolbar>
            <Button
              className="notification-btn"
              onClick={() => {
                history.push("/configuraciones");
                Notification.close();
              }}
            >
              Ir
            </Button>
            <Button
              className="notification-btn"
              onClick={() => {
                Notification.close();
              }}
            >
              Luego
            </Button>
          </ButtonToolbar>
        </div>
      ),
    });
  };

  const HomeB = () => {
    history.replace({ pathname: "/register" });
  };

  return (
    <div
      className="d-flex justify-content-center
      d-flex align-items-center vh-100"
    >
      <button type="button" className="back-window fixed-top" onClick={HomeB}>
        <i className="demo-icon-exp  icon-left-arrow"></i>
      </button>
      <Card style={{ width: "18em" }} className="border-0">
        <img src={logo} className="login-logo" alt="logo" />
        <Card.Body>
          <Card.Title className="Medium login-title-code"></Card.Title>
          <Card.Text>
            <FacebookLogin
              appId={appIDFacebook}
              callback={responseFacebook}
              render={(renderProps) => (
                <button
                  type="button"
                  className="btn bold  
                    btn-lg btn-social text-left"
                  onClick={renderProps.onClick}
                  isDisabled={renderProps.isDisabled}
                >
                  <i className="demo-icon-social icon-facebook text-primary"></i>
                  facebook
                </button>
              )}
            />

            <div className="divider-blank"></div>
            <GoogleLogin
              clientId="820836317775-da5ht99v02j9d0mb8j636vm5on2oa5mk.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  className="btn bold 
                  btn-lg btn-social text-left"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <i className="demo-icon-social icon-google text-danger"></i>
                  Google
                </button>
              )}
              buttonText="Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
            />
            {/*
              <Link
                  to="#">
                    <button type="button" 
                    class="btn bold 
                    btn-lg btn-social text-left">
                      <i className="demo-icon-social icon-google text-danger"></i>
                      Google
                    </button>
              </Link>
              */}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login_code;
