import React from "react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FileImageOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MessageItem = ({
  textMsg,
  image,
  time,
  type,
  position,
  id,
  onDelete,
  onViewer,
  eliminate,
  reservation,
}) => {
  const handleDelete = () => {
    onDelete(id);
  };

  const handleViewImage = () => {
    onViewer(image);
  };
  return (
    <>
      <div className={`messageItem message-${position}`}>
        <ContextMenuTrigger id={`contextMenu-${id}`}>
          <div className={`messageItem-content ${position}`}>
            {type === "image" && !reservation && (
              <div className="message-image-content">
                <img className="message-image " src={image} alt="image" />
              </div>
            )}
            {reservation && (
              <div className="message-image-content text-center">
                <FontAwesomeIcon
                  style={{ fontSize: "100px", padding: "10px" }}
                  icon={faThumbsUp}
                  className="orange"
                />
              </div>
            )}

            <div
              className={`message-text ${!textMsg ? "message-no-text " : " "} ${
                eliminate ? "message-text-eliminate " : ""
              }`}
            >
              {textMsg}
            </div>
            <div className="message-footer">{moment(time).fromNow()}</div>
          </div>
        </ContextMenuTrigger>
      </div>

      {!eliminate && !reservation && (
        <ContextMenu id={`contextMenu-${id}`} className="message-contextMenu">
          {position === "outgoing" && (
            <MenuItem
              className="message-contextMenu-item"
              onClick={handleDelete}
            >
              <DeleteOutlined /> Eliminar mensaje
            </MenuItem>
          )}
          {type === "image" && (
            <MenuItem
              className="message-contextMenu-item"
              onClick={handleViewImage}
            >
              <FileImageOutlined /> Ver imagen
            </MenuItem>
          )}
        </ContextMenu>
      )}
    </>
  );
};

MessageItem.defaultProps = {
  textMsg: "",
  image: "",
  time: "",
  type: "text",
  position: "message-incoming",
  id: "",
  onViewer: () => {},
  onDelete: () => {},
  eliminate: false,
  reservation: false,
};

export default MessageItem;
