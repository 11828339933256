import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Alert, Drawer, Progress } from "rsuite";
import { BASE_PATH } from "../../constants";
import { AuthContext } from "../../../auth/AuthContext";

import ExperienciaNewContent from "../../Anfitrion/Experiencias/ExperienciaNewContent";


export const ExperienciaEditar = () => {
  const { experienciaid } = useParams();
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const { Circle } = Progress;

  const [experiencia, setExperiencia] = useState([]);
  const [form, setForm] = useState(false);
  const [ubicacion, setUbicacion] = useState(false);
  const [loader, setLoader] = useState(false);
  const [percent, setpercent] = useState(1);


  const Datos = (form, ubicacion) => {
    setForm(form);
    setUbicacion(ubicacion);
  };



  const Back = () => {
    history.push("/host/experiencia/_/" + experienciaid);
  };


  
  const getExperiencias = async () => {
    try {
      let res = await axios.get(`${BASE_PATH}/experiences/${experienciaid}`);
      if (res.status === 200) {
        let exp = [res.data];
        setExperiencia(exp);

      }
    } catch (e) {
      console.log(e);

      history.push(`/host/_/${user.data.user.id}`);

    }
  };

  const updateExp = async () => {



    try {
      setLoader(true);
      setpercent(10);

      if (
        !!!form.name ||
        !!!form.description ||
        !!!form.start ||
        !!!form.end ||
        !!!form.minQuotas ||
        !!!form.maxQuotas ||
        !!!form.direction ||
        !!!form.duration ||
        !!!ubicacion
      ) {
        Alert.warning("Campos  requeridos vacíos. a", 2000);
        return
      }

      const experienciaUp = await axios.put(
        `${BASE_PATH}/experiences/${experienciaid}`,
        {
          name: form.name,
          description: form.description,
          duration: `${form.duration} horas`,
          direction: form.direction,
          lat: ubicacion.lat,
          long: ubicacion.lng,
          quotas: {
            min: form.minQuotas,
            max: form.maxQuotas
          },
          start: form.start,
          end: form.end,
          extra: form.extra,
        },

        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );
      if (experienciaUp.status === 200) {
        setpercent(99);

        setTimeout(() => {

          setLoader(false);
          Alert.success("Se actualizo tu experiencia!", 2000);
          Back();

        }, 1000);
      }

    } catch (error) {
      setLoader(false);
      console.log(error);
      Alert.error("Ha ocurrido un error en el servidor", 3000);
    }
  };

  useEffect(() => {
    getExperiencias();
  }, []);


  return (
    <>
      {loader && (
        // <Loader size="lg" backdrop center content="Guardando. Por favor espera..." vertical />
        <div className="progress-loader">
          <div className="progress-loader-content">
            <Circle
              percent={percent}
              strokeColor="#262DFF"
              status={percent < 99 ? "active" : "success"}
            />
          </div>
        </div>
      )}
      {experiencia[0] && (
        <Drawer
          placement="bottom"
          closable={false}
          show={true}
          full={true}
          style={{ width: "100%" }}
          backdrop={true}
          drawerStyle={{ background: "#17191A" }}
        >
            <button
              type="button"
              style={{ position: "absolute", top: "25px", left: "25px" }}
              className="back-window-navbar-drawer"
              onClick={Back}
            >
              <i className=" icon-left-arrow"></i>
            </button>
           

          <Drawer.Body>
            <>
              <div className="steps-content">

                <ExperienciaNewContent
                  edit={true}
                  exp={experiencia[0]}
                  nextContent={Datos}

                />

              </div>
              <div className="steps-action bold navbar-sm-more-host exp-fixed-bottom">
                <Button
                  className="bold exp-pasos btn-primary-host btn-reserva"
                  type="primary"
                  onClick={updateExp}
                >
                  Actualizar
                </Button>
              </div>
            </>
          </Drawer.Body>
        </Drawer>
      )}
    </>
  );
};
