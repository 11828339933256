

const VsNetReducer = (state, action) => {
    switch (action.type) {
        case 'deviceID':
            return action.deviceFingerprintID

        default:
            return state
    }
}

export default VsNetReducer