import React, { useEffect, useState, useContext } from "react";
import { Drawer, Alert, Divider } from "rsuite";
import "../../assets/css/App.css";
import "antd/dist/antd.css";
import axios from "axios";
import { API_COUNTRIES, BASE_PATH } from "../constants";
import { AuthContext } from "../../auth/AuthContext";
import { RiAddFill } from "react-icons/ri";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

//Importar Recursos
import CardItem from "./elementos/CardItem";
import { FormTarjeta } from "./elementos/FormTarjeta";
// import creditcard from '../../assets/images/credit-card.svg';

export const AgregarTarjeta = ({ onSave, stylesclass, listcards = false }) => {
  const { user } = useContext(AuthContext);

  const [payment, setPayment] = useState({
    card: "",
    date: "",
    cvv: "",
    type: "",
    first_name: "",
    last_name: "",
    country: "",
    location: "",
    postalCode: "",
  });
  const [listCardUser, setListCardUser] = useState([]);

  const [action, setAction] = useState("Guardar");
  const [modal, setModal] = useState(false);
  const [change, setchange] = useState(false);
  const [countries, setcountries] = useState([]);

  const actionNew = () => {
    setPayment({
      card: "",
      date: "",
      cvv: "",
      first_name: "",
      last_name: "",
      country: "",
      location: "",
      postalCode: "",
      address: "",
    });
    setAction("Guardar");
    showModal();
  };

  const showModal = () => {
    modal == true ? setModal(false) : setModal(true);
  };

  const getCards = async () => {
    try {
      const respCard = await axios.get(`${BASE_PATH}/users/my-cards`, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });

      if (respCard.status === 201) {
        setListCardUser(respCard.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveCard = async (card) => {
    //console.log(card);
    if (card.card === "" || card.date === "" || card.cvv === "") {
      //console.log('Formato Invalido')
      Alert.error("Formato Invalido, revise bien los datos", 5000);
    } else {
      try {
        const save = await axios.post(`${BASE_PATH}/cards`, {
          access_token: user.data.token,
          ...card,
          area: card.location,
        });
        //console.log(save)
        if (save.status === 201) {
          setPayment({
            card: "",
            date: "",
            cvv: "",
            type: "",
            first_name: "",
            last_name: "",
            country: "",
            location: "",
            postalCode: "",
            address: "",
          });
          setAction("Guardar");
          getCards();
          showModal();
          Alert.success("Metodo de pago guardado", 5000);
          if (typeof onSave == "function") {
            onSave();
          }
        } else {
          Alert.error("Error al guardar metodo de pago", 5000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChange = () => {
    setchange(true);
  };

  const updateCard = async (card) => {
    try {
      if (change) {
        if (card.date === "") {
          Alert.error("Formato Invalido, revise bien los datos", 5000);
        } else {
          const save = await axios.put(`${BASE_PATH}/cards/${card._id}`, {
            access_token: user.data.token,
            ...card,
            area: card.location,
          });
          //console.log(save)
          if (save.status === 200) {
            setPayment({
              card: "",
              date: "",
              cvv: "",
              type: "",
              first_name: "",
              last_name: "",
              country: "",
              location: "",
              postalCode: "",
              address: "",
              state: "",
            });

            setAction("Guardar");
            getCards();
            showModal();
            Alert.success("Metodo de pago Actualizado", 5000);
          } else {
            Alert.error("Error al actualizar metodo de pago", 5000);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const actionCard = (card) => {
    action === "Guardar" ? saveCard(card) : updateCard(card);
  };

  const selectCard = async (id, lastNumbers) => {
    setchange(false);
    const getCard = await axios.get(
      `${BASE_PATH}/users/card/${user.data.user.id}/${id}/${lastNumbers}`,
      {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      }
    );

    if (getCard.status === 201) {
      setAction("Actualizar");
      setPayment(getCard.data);
      showModal();
    }
  };

  const getCountries = async () => {
    let response = await axios.get(`${API_COUNTRIES}`);
    let allcountries = response.data.reduce((a, b) => a.concat(b), []);
    let allNames = allcountries.map((el) => {
      return { name: el.name.common, value: el.cca2 };
    });
    const sorted = allNames.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }

      return 0;
    });
    setcountries(sorted);
  };

  useEffect(() => {
    getCards();
    getCountries();
  }, []);

  return (
    <div>
      {listcards && (
        <div style={{ paddingTop: "110px" }}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 1200: 2 }}
          >
            <Masonry>
              {listCardUser.map((data) => {
                return (
                  <div key={data.id}>
                    <CardItem
                      dataCard={data}
                      changeCard={getCards}
                      selectCard={selectCard}
                    />
                    <Divider />
                  </div>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>

          {listCardUser.length === 0 && (
            <h5 className="bold" style={{ textAlign: "center" }}>
              No hay datos para mostrar
            </h5>
          )}
        </div>
      )}

      <p type="button" onClick={actionNew} className={stylesclass}>
        {listcards ? (
          <RiAddFill style={{ fontSize: 40 }} />
        ) : (
          "+ Agregar Tarjeta"
        )}
      </p>

      <Drawer
        full
        placement="bottom"
        show={modal}
        onHide={() => setModal(false)}
      >
        <Drawer.Header>
          <Drawer.Title>
            <h5 className="bold">¡Agrega una nueva tarjeta!</h5>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <FormTarjeta
            payment={payment}
            handleChange={handleChange}
            actionCard={actionCard}
            action={action}
            countries={countries}
          />
        </Drawer.Body>
      </Drawer>
    </div>
  );
};
