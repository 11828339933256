import React, { useContext } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Imagen2 from "../../../assets/images/icono.svg";

import { InputPicker } from "rsuite";
import { AuthContext } from "../../../auth/AuthContext";

export const ListaExperiencias = ({
  experiencias = [],
  categorias = [],
  filterCategory,
  gustos = [],
  exploring = false,
  loading = false,
  defaultSelect = "Todas"
}) => {
  const history = useHistory();

  const selectCategory = (_, item) => filterCategory(item);

  const cleanFilter = () => filterCategory({ rol: "all", value: "Todas" });

  const { user } = useContext(AuthContext);

  return (
    <section className="container page-parati">
      {categorias.length > 0 && user?.logged && (
        <div className="categoria-parati">
          <InputPicker
            data={categorias}
            value={defaultSelect}
            onSelect={selectCategory}
            onClean={cleanFilter}
            placement="bottom"
          ></InputPicker>
        </div>
      )}
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 2, 1200: 3 }}>
        <Masonry gutter={20}>
          {!loading &&
            experiencias.map((item) => {
              let user = item.user.infoExtra ? item.user.infoExtra : item.user;
              return (
                <Link
                  key={item.id}
                  onClick={() => history.push("/experiencia/" + item.id)}
                >
                  <div>
                    <Card className="image04">
                      <Card.Img
                        src={item.pictures[0] ? item.pictures[0].path : Imagen2}

                      />
                      <Card.ImgOverlay>
                        <Image
                          src={user.picture ? user.picture : item.user.picture}
                          roundedCircle
                          className="avatar"

                        />

                        <div className="card-bottom">
                          <h3 className="bold text-white card-titulo">
                            {item.name}
                          </h3>
                          <p
                            className="text-white bold d-none d-md-none d-lg-block card-precio"
                            style={{ lineHeight: "18px" }}
                          >
                            <text className="orange__gradient heavy" style={{ fontSize: "25px" }}>
                              ${item.price.adultPriceWithCommissions || item.price.adultPrice.toString()}{" "}
                            <text className="lite" style={{ fontSize: "16px" }}>
                              por persona
                            </text></text>
                          </p>
                          <p className="place">
                            <i className="icon-marker"></i>
                            {"   "}
                            {item.direction}
                          </p>
                        </div>
                      </Card.ImgOverlay>
                    </Card>
                  </div>
                </Link>
              );
            })}
        </Masonry>
      </ResponsiveMasonry>

      {experiencias.length === 0 && !exploring && !loading && (
        <h2 style={{ textAlign: "center", padding: "20px", fontSize: "18px" }}>
          No hay experiencias para mostrar. Prueba eligiendo otra categoría
        </h2>
      )}
    </section>
  );
};
