import React, { useState, useContext, useEffect, useRef } from "react";
// import axios from "axios";
import Viewer from "react-viewer";
import { BASE_PATH } from "../../constants";
import axios from "axios";
import {
  collection,
  doc,
  deleteDoc,
  setDoc,
  query,
  orderBy,
} from "@firebase/firestore";
import { useFirestoreCollection, useFirestore, useStorage } from "reactfire";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
} from "@chatscope/chat-ui-kit-react";

import { AuthContext } from "../../../auth/AuthContext";
import ModalImageView from "../../ModalImageView";
import MessageItem from "../../Anfitrion/Chat/elementos/MessageItem";
const defaultProfile =
  "https://tugofiles.fra1.digitaloceanspaces.com/1630952868200-Icono_512x512.png";

const Chat = ({ dataUser, closeChat }) => {
  const uploaderRef = useRef(null);

  const { user } = useContext(AuthContext);
  const { name, picture, _id } = dataUser;

  const refOwnerCollection = collection(
    useFirestore(),
    `chat/messages/${_id}/${user.data.user.id}/messagesList`
  );
  const refTravelersDoc = collection(
    useFirestore(),
    `chat/chatList/${user.data.user.id}/traveler/list`
  );

  const refOwnersDoc = collection(
    useFirestore(),
    `chat/chatList/${_id}/owner/list`
  );

  const [file, setfile] = useState(false);
  const [preURLFile, setpreURLFile] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [uploading, setuploading] = useState(false);
  const [viewImage, setviewImage] = useState({ src: "", show: false });

  let referenceStorage = ref(
    useStorage(),
    `chats/${_id}/resources/${file?.file?.name}`
  );

  const { status, data } = useFirestoreCollection(
    query(refOwnerCollection, orderBy("time", "asc"))
  );

  const [messageInputValue, setMessageInputValue] = useState("");
  const close = () => {
    closeChat();
  };

  const sendMessage = async (fileURL = "") => {
    const dateMsg = new Date().toISOString()

    let msg = {
      active: true,
      image: fileURL,
      text: file.file ? (file.text ? file.text : "") : messageInputValue,
      time: dateMsg,
      userby: user.data.user.id,
      type: file.file ? "2" : "1",
    };

    setMessageInputValue("");
    await setDoc(doc(refOwnerCollection), msg).then(async () => {
      await setDoc(doc(refTravelersDoc, _id), {
        msg: file.file ? "Envió una imagen" : msg.text,
        time: dateMsg,
        name: name ? name : '',
        image: picture ? picture : 'nopicture',
      }).then(async () => {
        await setDoc(doc(refOwnersDoc, user?.data.user.id), {
          msg: file.file ? "Envió un mensaje con imagen" : msg.text,
          time: dateMsg,
          name: user?.data.user.name,
          image: user?.data.user.picture,
        }).then(() => {
          //console.log("enviando al owner..");
          setfile({ file: null, text: "" });
          setpreURLFile("");
          setuploading(false);

          sendNotification(_id, file.file ? "Envió un mensaje con imagen" : msg.text);
        });
      });
    });
  };

  const uploadFile = async () => {
    if (file.file) {
      setuploading(true);
      setshowModal(false);
      await uploadBytes(referenceStorage, file.file);
      await getDownloadURL(referenceStorage).then((url) => {
        //console.log(url);
        sendMessage(url);
      });
    }
  };
  const getFileForUpload = (e) => {
    //console.log(e.target.files[0]);
    setfile({ ...file, file: e.target.files[0] });
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setpreURLFile(imageUrl ? imageUrl : "");
    ShowModal();
  };
  const onChangeFile = (name, value) => {
    setfile({ ...file, [name]: value });
  };

  const ShowModal = () => {
    if (showModal === true) {
      setshowModal(false);
      setfile({ file: null, text: "" });
      setpreURLFile("");
    } else {
      setshowModal(true);
    }
  };

  const handleOpenFileExplorer = () => {
    setfile({ file: null, text: "" });
    uploaderRef.current.value = null;
    uploaderRef.current && uploaderRef.current.click();
  };

  const handleInactiveMSG = async (id) => {
    const { type, text, image, userby, time } = data.docs
      .filter((item) => item.id === id)[0]
      .data();

    let msgEdit = {
      type,
      text,
      image,
      userby,
      active: false,
      time,
    };
    await setDoc(doc(refOwnerCollection, id), msgEdit).catch((err) =>
      console.log(err)
    );
  };

  const handleViewImage = (src) => {
    setviewImage({ src, show: true });
  };

  const sendNotification = async (userId, message) => {
    // console.log(window.location.origin);
    await axios
      .post(
        `${BASE_PATH}/notifications/send`,
        {
          userId: userId,
          message: message,
          url: `${window.location.origin}/host/chat`,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.data.token}`,
          },
        }
      )
      .then(() => { })
      .catch((err) => console.log(err));

    //console.log(send);
  };

  // useEffect(() => {}, [status === "success"]);

  return (
    <MainContainer>
      <ChatContainer>
        <ConversationHeader>
          <ConversationHeader.Back onClick={close} />
          <Avatar src={picture ? picture : defaultProfile} name={"user"} />
          <ConversationHeader.Content userName={name ? name : 'Anfitrion'} />
        </ConversationHeader>
        <MessageList loading={uploading}>
          {status === "success" &&
            data.docs.map((item) => {
              const { type, text, image, userby, active, time, reservation } = item.data();
              let direction = user.data.user.id === userby ? "outgoing" : "incoming";
              if (active === true) {
                return (
                  <MessageItem
                    key={item.id}
                    id={item.id}
                    time={time}
                    textMsg={text}
                    image={image}
                    position={direction}
                    type={type === "2" ? "image" : "text"}
                    onDelete={handleInactiveMSG}
                    onViewer={handleViewImage}
                    reservation={reservation}
                  />
                );
              } else if (active === false) {
                return (
                  <MessageItem
                    key={item.id}
                    time={time}
                    textMsg="Este mensaje fue eliminado."
                    eliminate
                    position={direction}

                  />
                );
              }
            })}
        </MessageList>

        <MessageInput
          className="d-flex justify-content-start"
          placeholder="Escribe a tu anfitrión"
          value={messageInputValue}
          onChange={(_, text) => setMessageInputValue(text)}
          onSend={() => sendMessage()}
          sendButton={true}
          attachButton={true}
          fancyScroll={true}
          onAttachClick={() => handleOpenFileExplorer()}
          disabled={showModal}
        />
      </ChatContainer>
      <input
        ref={uploaderRef}
        type="file"
        accept="image/*"
        name="file"
        style={{ display: "none" }}
        onChange={getFileForUpload}
      />

      <ModalImageView
        text={file?.text}
        preURLFile={preURLFile}
        change={onChangeFile}
        send={uploadFile}
        close={ShowModal}
        show={showModal}
      />
      <Viewer
        zIndex={9999}
        visible={viewImage.show}
        onClose={() => {
          setviewImage({ ...viewImage, show: false });
        }}
        images={[viewImage]}
      />
    </MainContainer>
  );
};

export default Chat;
