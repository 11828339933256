import React  from "react";
import { MessageInput, MessageSeparator, SendButton } from "@chatscope/chat-ui-kit-react";
import { IconButton } from "rsuite";
import { RiCloseCircleFill } from "react-icons/ri";

const ModalImageView = ({ show = false, text, change, send, close, preURLFile = "", full = false }) => {


  return (
    
      <div className={full ? 'ModalImageView ModalImageViewFull' : 'ModalImageView' } style= {{display: show ? 'block' : 'none'}}>
        <div style={{ position: "relative", width: "100%" }}>
           <h4>Envia una imagen</h4> 
          <IconButton
            style={{ position: "absolute", right: "0", top: '0' }}
            circle
            size="lg"
            icon={<RiCloseCircleFill />}
            onClick={() => close()}
          />
        </div>

        <img
          style={{ width: "100%", maxHeight: '450px', marginTop: "10px" }}
          src={preURLFile}
          alt="upload image"
        />
        <MessageSeparator />
        <div style={{display:'flex', width: '100%'}}>
        <MessageInput
        
          className="d-flex justify-content-start inputModalIageView"
          placeholder="Agrega un comentario"
          value={text}
          onChange={(_ ,val) => change("text", val)}
          onSend={() => send()}
          sendButton={false}
          attachButton={false}
        />
        <SendButton onClick={() => send()} />
        </div>

      </div>
  );
};

export default ModalImageView;
