import React, { useState, useContext, useRef } from "react";
import { AuthContext } from "../../../../auth/AuthContext";
import Viewer from "react-viewer";
import { BASE_PATH } from "../../../constants";
import axios from "axios";

import {
  ChatContainer,
  MessageList,
  MessageInput,
  ConversationHeader,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import MessageAlert from "./MessageAlert";
import noMessages from "../../../../assets/images/sinMensaje.svg";
import iniciarSession from "../../../../assets/images/iniciarSesion.svg";
import {
  collection,
  doc,
  setDoc,
  query,
  orderBy,
} from "@firebase/firestore";
import { useFirestoreCollection, useFirestore, useStorage } from "reactfire";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import ModalImageView from "../../../ModalImageView";
import MessageItem from "./MessageItem";
const defaultProfile =
  "https://tugofiles.fra1.digitaloceanspaces.com/1630952868200-Icono_512x512.png";

export const ChatBody = ({
  changevisible = () => {},
  classname = "",
  selected = { image: "", id: "", name: "" },
}) => {
  const { user } = useContext(AuthContext);
  const uploaderRef = useRef(null);
  const [file, setfile] = useState(false);
  const [preURLFile, setpreURLFile] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [uploading, setuploading] = useState(false);
  const [viewImage, setviewImage] = useState({ src: "", show: false });
  let ownerId = user?.data
    ? user.data.user.role === "owner"
      ? user.data.user.id
      : selected?.id
      ? selected.id
      : "default"
    : "default";

  let travelerId = user?.data
    ? user.data.user.role !== "owner"
      ? user.data.user.id
      : selected?.id
      ? selected.id
      : "default"
    : "default";

  const refOwnerCollection = collection(
    useFirestore(),
    `chat/messages/${ownerId}/${travelerId}/messagesList`
  );

  const refTravelersDoc = collection(
    useFirestore(),
    `chat/chatList/${travelerId}/traveler/list`
  );

  const refOwnersDoc = collection(
    useFirestore(),
    `chat/chatList/${ownerId}/owner/list`
  );

  let referenceStorage = ref(
    useStorage(),
    `chats/${selected?.id}/resources/${file?.file?.name}`
  );

  const { status, data } = useFirestoreCollection(
    query(refOwnerCollection, orderBy("time", "asc"))
  );

  const [messageInputValue, setMessageInputValue] = useState("");

  const sendMessage = async (fileURL = "") => {
    let iOwner = user?.data.user.role === "owner" ? true : false;

    //console.log(iOwner, selected.name, selected.image);
    const dateMsg = new Date().toISOString()

    let msg = {
      active: true,
      image: fileURL,
      text: file.file ? (file.text ? file.text : "") : messageInputValue,
      time: dateMsg,
      userby: user.data.user.id,
      type: file.file ? "2" : "1",
    };
    setMessageInputValue("");
    await setDoc(doc(refOwnerCollection), msg).then(async () => {
      await setDoc(doc(refTravelersDoc, ownerId), {
        msg: file.file ? "Envió una imagen" : msg.text,
        time: dateMsg,
        name:
          iOwner === true
            ? user?.data.user.infoExtra?.name
              ? user.data.user.infoExtra?.name
              : user.data.user.name
            : selected?.name,
        image:
          iOwner === true
            ? user?.data.user.infoExtra.picture
              ? user.data.user.infoExtra.picture
              : user.data.user.picture
            : selected?.image,
      }).then(async () => {
        await setDoc(doc(refOwnersDoc, travelerId), {
          msg: file.file ? "Envió un mensaje con imagen" : msg.text,
          time: dateMsg,
          name: iOwner === true ? selected?.name : user?.data.user.name,
          image: iOwner === true ? selected?.image : user?.data.user.picture,
        }).then(() => {
          //console.log("enviado a owner.");
          setfile({ file: null, text: "" });
          setpreURLFile("");
          setuploading(false);

          sendNotification(
            selected?.id,
            file.file ? "Envió un mensaje con imagen" : msg.text
          );
        });
      });
    });
  };

  const uploadFile = async () => {
    if (file.file) {
      setuploading(true);
      setshowModal(false);
      await uploadBytes(referenceStorage, file.file);
      await getDownloadURL(referenceStorage).then((url) => {
        //console.log(url);
        sendMessage(url);
      });
    }
  };
  const getFileForUpload = (e) => {
    //console.log(e.target.files[0]);
    setfile({ ...file, file: e.target.files[0] });
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setpreURLFile(imageUrl ? imageUrl : "");
    ShowModal();
  };
  const onChangeFile = (name, value) => {
    setfile({ ...file, [name]: value });
  };

  const ShowModal = () => {
    if (showModal) {
      setshowModal(false);
      setfile({ file: null, text: "" });
      setpreURLFile("");
    } else {
      setshowModal(true);
    }
  };

  const handleOpenFileExplorer = () => {
    setfile({ file: null, text: "" });
    uploaderRef.current.value = null;
    uploaderRef.current && uploaderRef.current.click();
  };

  const handleInactiveMSG = async (id) => {
    const { type, text, image, userby, time } = data.docs
      .filter((item) => item.id === id)[0]
      .data();

    let msgEdit = {
      type,
      text,
      image,
      userby,
      active: false,
      time,
    };
    await setDoc(doc(refOwnerCollection, id), msgEdit).catch((err) =>
      console.log(err)
    );
  };

  const handleViewImage = (src) => {
    setviewImage({ src, show: true });
  };

  const sendNotification = async (userId, message) => {
    let path = user?.data.user.role === "owner" ? "/chat" : "/host/chat";
    await axios
      .post(
        `${BASE_PATH}/notifications/send`,
        {
          userId: userId,
          message: message,
          url: `${window.location.origin}${path}`,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.data.token}`,
          },
        }
      ).catch((err) => console.log(err));
    //console.log(send);
  };

  return (
    <>
      <ChatContainer className={`${classname} chatcontainer`}>
        <ConversationHeader className="chat-header">
          <ConversationHeader.Back
            className="conversationHeaderBack"
            onClick={() => changevisible()}
          />
          <Avatar
            src={selected?.image ? selected.image : defaultProfile}
            name={selected.name}
          />
          <ConversationHeader.Content
            userName={selected?.name ? selected.name : "Nombre de usuario"}
          />
        </ConversationHeader>

        <MessageList className="messagelist" loading={uploading}>
          {status === "success" &&
            data.docs.map((item) => {
              const { type, text, image, userby, active, time, reservation } = item.data();
              let direction = user.data.user.id === userby ? "outgoing" : "incoming";

              if (active === true) {
                return (
                    <MessageItem
                      key={item.id}
                      id={item.id}
                      time={time}
                      textMsg={text}
                      image={image}
                      position={direction}
                      type={type === "2" ? "image" : "text"}
                      onDelete={handleInactiveMSG}
                      onViewer={handleViewImage}
                      reservation={reservation}
                    />
                );
              } else if (active === false) {
                return (
                  <MessageItem
                    key={item.id}
                    time={time}
                    textMsg="Este mensaje ha sido eliminado"
                    position={direction}
                    eliminate
                  />
                );
              }
            })}

          {user.logged && data?.docs?.length === 0 && (
            <div
              style={{
                display: "block",
                textAlign: "center",
                width: "100%",
                paddingTop: "10%",
              }}
            >
              <img
                src={noMessages}
                alt="No Mesages"
                style={{ width: "80px" }}
              />
              <MessageAlert
                showIcon={false}
                type="info"
                title="Informacion"
                description="No hay mensajes para mostrar"
              />
            </div>
          )}
          {!user.logged && (
            <div
              style={{
                display: "block",
                textAlign: "center",
                width: "100%",
                paddingTop: "10%",
              }}
            >
              <img
                src={iniciarSession}
                alt="No Mesages"
                style={{ width: "80px" }}
              />
              <MessageAlert
                showIcon={false}
                type="info"
                title="Informacion"
                description="Para ver tus mensajes inicia sesion con tu cuenta"
              />
            </div>
          )}
        </MessageList>

        {user.logged && selected?.id !== "" && (
          <MessageInput
            className="d-flex justify-content-start  "
            placeholder="Escribe a tu anfitrión"
            onChange={(_, text) => setMessageInputValue(text)}
            onSend={() => sendMessage()}
            sendButton={true}
            onAttachClick={() => handleOpenFileExplorer()}
            disabled={showModal}
          />
        )}
      </ChatContainer>
      <input
        ref={uploaderRef}
        type="file"
        accept="image/*"
        name="file"
        style={{ display: "none" }}
        onChange={getFileForUpload}
      />
      <ModalImageView
        text={file?.text}
        preURLFile={preURLFile}
        change={onChangeFile}
        send={uploadFile}
        close={ShowModal}
        show={showModal}
        full
      />

      <Viewer
        zIndex={9999}
        visible={viewImage.show}
        onClose={() => {
          setviewImage({ ...viewImage, show: false });
        }}
        images={[viewImage]}
      />
    </>
  );
};
