import React from 'react'
import { Card, Image} from 'react-bootstrap';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

//Importar Recursos
import Imagen from '../../assets/images/cards/surf.jpg';
import Avatar1 from '../../assets/images/avatar1.jpg';
import Viajero from '../Navbars/Viajero';

const VR = () => {
    return (
      <div className="bg-orange-gradient">
            <div>
                <Viajero />
            </div>
            <section className="page-header-vr container-fluid">
                    <div className="bold exp-titulo container
                    d-flex justify-content-center">
                        <p>Experiencias de viaje en realidad virtual</p>  
                    </div>
            </section>
              <ResponsiveMasonry
                  columnsCountBreakPoints={{350: 2, 750: 3, 1200: 4}}
                  className="container page-parati"
                  >
                    <Masonry gutter={20} >
                        <a  href="https://nikgraf.github.io/webvr-experiments/HelloWorld/v3/">
                            <div key="key1">
                                <Card className="pointer border-0 image04 ">
                                    <Card.Img src={Imagen} className="card-border"  />
                                        <Card.ImgOverlay>
                                            <Image src={Avatar1} roundedCircle className="avatar"/>
                                                <div className="card-bottom">
                                                    <h3 className="heavy text-white card-titulo">
                                                        Las mejores olas domadas por vos
                                                    </h3>
                                                    <text className="text-white bold d-none 
                                                    d-md-none d-lg-block card-precio">
                                                        $<number>25</number> por persona
                                                    </text>
                                                    <p className="place">
                                                        <i className="demo-icon icon-marker"></i>{'   '}
                                                        Escuintla
                                                    </p>
                                                    <p className="text-white bold 
                                                    d-none d-md-none d-lg-block ">
                                                        <i className="icon-star orange"></i>{'   '}5
                                                    </p>
                                                </div>
                                        </Card.ImgOverlay>
                                </Card>
                            </div>
                        </a>
                        
                    </Masonry>
                </ResponsiveMasonry>

      </div>
    )
}

export default VR