import React, { useState, useRef, useContext, useEffect } from 'react'
import { Button, Icon, Rate, Drawer, Schema, FormGroup, FormControl, ControlLabel, Form, Alert } from 'rsuite';
import { StringType } from "schema-typed";
import axios from 'axios';
import { BASE_PATH } from '../constants';
import { AuthContext } from '../../auth/AuthContext';

const textStyle = {
    verticalAlign: 'top',
    lineHeight: '42px',
    display: 'inline-block'
};
const texts = {
    1: 'Muy mal',
    2: 'Mal',
    3: 'Regular',
    4: 'Bien',
    5: 'Excelente'
};
export const CalificarExp = ({ update, experience, dataReserva = false }) => {

    const formRef = useRef()
    const initForm = {
        title: "",
        desc: "",

    }
    const { user } = useContext(AuthContext);

    const [modal, setModal] = useState(false)
    const [rating, setRating] = useState(3);
    const [form, setForm] = useState(initForm)
    const [reserva, setReserva] = useState(dataReserva)
    const [guardando, setGuardando] = useState(false)
    const model = Schema.Model({
        title: StringType().isRequired("Campo obligatorio."),
        desc: StringType().isRequired("Campo obligatorio."),

    })
    const showModal = () => {
        setModal(true)
    }

    const getReservas = async () => {
        if (user.logged && !!dataReserva) {

            setReserva(new Date(dataReserva.date[0]).getTime())

        }
    }
    const Evaluar = async () => {

        if (!formRef.current.check()) {
            Alert.warning("Campos requeridos vacíos.", 100)
            return
        }

        setGuardando(true)

        let resp = await axios.post(`${BASE_PATH}/reviews`,

            {
                "experiences": experience,
                "title": form.title,
                "description": form.desc,
                "star": rating
            }, {
            headers: {
                Authorization: `Bearer ${user.data.token}`
            }
        }
        )

        if (resp.status == 200) {
            Alert.success("Se guardo tu Review!.", 1500)
            setGuardando(false)
            update()
            setModal(false)
        }
        // console.log(reserva)
        // if (reserva) {

        //     let dateToday = new Date().getTime()

        //     if (reserva <= dateToday) {

        //     } else if (reserva > dateToday) {

        //         Alert.error('Esta experiencia no ha terminado')

        //     }

        // } else {
        //     Alert.error('No tienes una reservacion hecha')
        // }

    }

    useEffect(() => {
        getReservas()

    }, [])
    return (
        <div>
            {/* {reserva && reserva <= new Date().getTime() && user.logged &&
                <Button color="red" appearance="ghost" onClick={showModal} >
                    <Icon icon="star" />
                    {' '}Calificar
                </Button>
            } */}

            {user.logged &&
                <Button color="red" appearance="ghost" onClick={showModal} >
                    <Icon icon="star" />
                    Calificar
                </Button>
            }



            <Drawer placement='bottom' size='full' show={modal} onHide={(() => setModal(false))}>
                <Drawer.Header>
                    <Drawer.Title>¿Cómo calificas esta experiencia?</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body style={{ overflow: 'hidden', justifyContent: 'center', fontSize: '14px' }}>

                    <Form
                        ref={formRef}
                        fluid
                        model={model}
                        onChange={(formValue) => {
                            setForm(formValue)

                        }}
                        formValue={form}>

                        <FormGroup style={{ paddingTop: '20px', textAlign: 'center' }} >
                            <Rate size="lg" color="red" defaultValue={3} onChange={((value) => { setRating(value); console.log(value); })} />{' '}
                        </FormGroup>
                        <FormGroup style={{ textAlign: 'center', marginTop: '-15px' }}>
                            <span style={textStyle}>{texts[rating]}</span>
                        </FormGroup>
                        <FormGroup className='container-lg'>
                            <ControlLabel>Titulo</ControlLabel>
                            <FormControl style={{ fontSize: '14px' }} className="form-control" value={form.title} name={"title"} placeholder={"Agrega un titulo a tu review"} />
                        </FormGroup>

                        <FormGroup className='container-lg'>
                            <ControlLabel>Descripcion</ControlLabel>
                            <FormControl  className="form-control-textarea" componentClass="textarea" value={form.desc} name={"desc"} placeholder={"Agrega una  descripcion"} />
                        </FormGroup>

                    </Form>

                </Drawer.Body>
                <Drawer.Footer style={{ padding: '25px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button loading={false} color="red" onClick={Evaluar}>
                        Guardar
                    </Button>
                    <Button onClick={(() => setModal(false))} appearance="subtle" >
                        Cancelar
                    </Button>
                </Drawer.Footer>
            </Drawer>
        </div>
    )
}
