import React, { Component, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Icon } from "rsuite";

const AnyReactComponent = ({ text }) => (
  <div>
    <Icon style={{ color: "#fe5000" }} icon="dot-circle-o" size="3x" />
  </div>
);

const SimpleMap = ({ isCenter }) => {
  const [coordenates, setCoordenates] = useState({
    lat: 14.6349149,
    lng: -90.5068824,
  });

  useEffect(() => {
    if (isCenter && !isNaN(isCenter.lat) & !isNaN(isCenter.lng)) {
      setCoordenates(isCenter);
    }
  }, [isCenter]);

  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBfuzf_YkehUolTYZqVAtPd5lNOBYy5Zd8" }}
        center={coordenates}
        //center
        defaultZoom={11}
      >
        <AnyReactComponent
          lat={coordenates.lat}
          lng={coordenates.lng}
          text="Mi ubicación"
        />
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
