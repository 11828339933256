import React from 'react'
import { Icon } from 'rsuite';
import { monthsInYear } from '../../../Anfitrion/Experiencias/schedules/utils'

const MonthCard = ({ month, year, onSelect }) => {
  return (
    <article className="schedule-item" onClick={onSelect}>

      {/*<div className=" bold schedule-item-year">
        <span><Icon className='orange' size='lg' icon='calendar'/></span>
    </div>*/}
      <div className=" bold schedule-item-year-name">
        <span>{monthsInYear[month]}</span>
      </div>
      <div className="bold schedule-item-year-number">
        <span>{year}</span>
      </div>
    </article>
  )
}

MonthCard.defaultProps = {
  month: "",
  year: "",
  onSelect: () => { }
}

export default MonthCard