import React, { useState, useContext, useEffect } from "react";
import { } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import "../../../assets/css/App.css";
import "antd/dist/antd.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  RiCalendarFill,
  RiTimer2Fill,
  RiUserSmileFill,
  RiMoneyDollarCircleFill,
} from "react-icons/ri";
import { AuthContext } from "../../../auth/AuthContext";
import axios from "axios";
import moment from "moment";
import { BASE_PATH } from "../../constants";

//Importar Recursos
import Maps from "../../Experiencias/maps";
import { Icon } from "rsuite";

const ExperienciaDetailsOpen = (props) => {
  const [experiencia, setExperiencia] = useState([]);
  const [ubicacion, setUbicacion] = useState();
  const [quotes, setquotes] = useState({ available: 0, reserved: 0 });
  const { user } = useContext(AuthContext);
  const [extras, setExtras] = useState([]);

  const getExperiencia = async () => {
    try {
      let res = await axios.get(`${BASE_PATH}/experiences/${props.data}`);
      if (res.status === 200) {
        const exp = [res.data];
        setExperiencia(exp);
        setUbicacion({
          lat: parseFloat(exp[0].lat),
          lng: parseFloat(exp[0].long),
        });
        let selectes = [];
        exp[0].extra.forEach((item) => {
          selectes.push(item);
        });
        setExtras(selectes);
        let schedules = await axios.get(
          `${BASE_PATH}/schedules?experience=${props.data}`,
          {
            headers: {
              Authorization: `Bearer ${user.data.token}`,
            },
          }
        );
        const allSchedules = schedules.data.rows;

        const bookedQuotas = allSchedules.reduce((acc, current) => {

          const { bookings } = current

          const booked = bookings.reduce((bookingAcc, bookingCurrent) => {

            const { bookedQuotas } = bookingCurrent
            return parseFloat(bookingAcc) + parseFloat(bookedQuotas)

          }, 0)

          return parseFloat(acc) + parseFloat(booked)

        }, 0)

        setquotes({ reserved: bookedQuotas, available: '-' });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getExperiencia();
  }, []);

  return (
    <>
      {experiencia.length > 0 && (
        <div className="exp-details">
          <section>
            <text>{experiencia.length > 0 ? experiencia[0].name : <></>}</text>
            <p className="bold">
              <text style={{ fontSize: 20 }}>Inicio: </text>
              {experiencia.length > 0 ? experiencia[0].start : <></>}
              <br />
              <text style={{ fontSize: 20 }}>Final: </text>
              {experiencia.length > 0 ? experiencia[0].end : <></>}
            </p>
            <Row md={4}>
              <Col md={4}>
                <Row className="card-details">
                  <Col xs={3}>
                    <RiCalendarFill className="demo-icon-details neon_blue" />
                  </Col>
                  <Col>
                    <text>Fecha</text>
                    <p className="bold">
                      Consultar horarios*

                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="card-details">
                  <Col xs={3}>
                    <RiTimer2Fill className="demo-icon-details neon_blue" />
                  </Col>
                  <Col>
                    <text>Horario</text>
                    <p className="bold">
                      Consultar horarios*
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="card-details">
                  <Col xs={3}>
                    <RiMoneyDollarCircleFill className="demo-icon-details neon_blue" />
                  </Col>
                  <Col>
                    <text>Precio</text>
                    <p className="bold">
                      Adultos ${" "}
                      {experiencia.length > 0 ? (
                        experiencia[0].price.adultPriceWithCommissions ||
                        experiencia[0].price.adultPrice
                      ) : (
                        <></>
                      )}
                      , Niños ${" "}
                      {experiencia.length > 0 ? (
                        experiencia[0].price.childrenPriceWithCommissions ||
                        experiencia[0].price.childrenPrice
                      ) : (
                        <></>
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="card-details">
                  <Col xs={3}>
                    <RiUserSmileFill className="demo-icon-details neon_blue" />
                  </Col>
                  <Col>
                    <text>Cupones</text>
                    <p className="bold">
                      {experiencia.length > 0 ? (
                        experiencia[0].quotas.min + " - "
                      ) : (
                        <></>
                      )}
                      {experiencia.length > 0 ? (
                        experiencia[0].quotas.max + " "
                      ) : (
                        <></>
                      )}
                      personas por horario
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="card-details">
                  <Col xs={3}>
                    <RiUserSmileFill className="demo-icon-details neon_blue" />
                  </Col>
                  <Col>
                    <text>Reservados</text>
                    <p className="bold">{quotes.reserved}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="card-details">
                  <Col xs={3}>
                    <RiUserSmileFill className="demo-icon-details neon_blue" />
                  </Col>
                  <Col>
                    <text>Disponibles</text>
                    <p className="bold">{quotes.available}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          <section>
            <h3 className="bold">Lo que haremos</h3>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  experiencia.length > 0
                    ? experiencia[0].description.replace(/\n/g, "<br />")
                    : "",
              }}
            ></p>
          </section>

          <li className="dropdown-divider"></li>

          <section>
            <h3 className="bold">Ubicación</h3>

            <div style={{ height: "10rem" }}>
              <Maps isCenter={ubicacion} />
            </div>
          </section>

          <li className="dropdown-divider"></li>

          <section>
            <h3 className="bold">Incluye</h3>
            <OwlCarousel
              className="owl-carousel"
              margin={20}
              nav={false}
              dots={true}
              responsive={{
                1200: {
                  items: 4,
                },
                300: {
                  items: 2,
                },
              }}
            >
              {extras &&
                extras.map((item, i) => (
                  <div
                    className="item card2 text-center"
                    key={`${item.valor}_${item.icon}_${i}`}
                  >
                    <Card>
                      <Card.Body className="bold">
                        <Icon icon={item.icon} size="3x" />
                        <br />
                        <h6 style={{ paddingTop: 20, marginBottom: 1 }}>
                          {item.valor}
                        </h6>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
            </OwlCarousel>
          </section>
        </div>
      )}
    </>
  );
};

export default ExperienciaDetailsOpen;
