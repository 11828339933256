import React from 'react'
import { message, Popconfirm } from 'antd';
const ScheduleCard = ({ title, days, time, from, to, repeat, onSelect, onDelete, id }) => {

    const onConfirm = () => {
        onDelete(id)
    }

    return (
        <article className="schedule-item schedule-item-padding">
            <div onClick={onSelect}>
                <header className="schedule-item-header">
                    <span>{title}</span>
                </header>
                <main className="schedule-item-content">
                    <span><b>Días:</b> {days}</span><br />
                    <span><b>Hora:</b> {time}</span><br />
                    <span><b>Desde:</b> {from}</span><br />
                    <span><b>Hasta:</b> {to}</span><br />
                    <span className="schedule-item-content-repeat">Se repite cada {repeat}</span>
                </main>

            </div>

            <Popconfirm
                title="¿Estás seguro de eliminar este horario?"
                onConfirm={onConfirm}
                onCancel={()=>{}}
                okText="Si"
                cancelText="No"
            >
                <button className="bg-orange schedule-item-btn">
                    <i className="icon-cancel"></i>
                </button>
            </Popconfirm>

        </article>
    )
}

ScheduleCard.defaultProps = {
    title: "",
    days: "",
    time: "",
    from: "",
    to: "",
    repeat: "",
    id: "",
    onDelete: () => { },
    onSelect: () => { }
}

export default ScheduleCard