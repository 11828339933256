import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ListaExperiencias } from "./exp/ListaExperiencias";
import { Avatar, Alert } from "antd";
import { Row, Col, Card } from "react-bootstrap";
import Viajero from "../Navbars/Viajero";
import logo from "../../assets/images/logo.svg";
import nft1 from "../../assets/images/nft.png";
import money from "../../assets/images/money-bag.png";
import semanasanta from "../../assets/images/legado.jpg";
import semanasanta2 from "../../assets/images/legado2.jpg";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_PATH } from "../constants";
import { AuthContext } from "../../auth/AuthContext";
import { Steps } from "intro.js-react";
import tuto1 from "../../assets/images/go.png";
import { Message, Loader } from "rsuite";
import TranslateButton from '../GoogleTraslate/TranslateButton';


const Parati = () => {


  const { user } = useContext(AuthContext);

  const history = useHistory();
  const [experiencias, setExperiencias] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [misgustos, setmisgustos] = useState([]);
  const [alerta, setalerta] = useState(false);
  const [invalidSesion, setinvalidSesion] = useState(undefined);
  const [loading, setloading] = useState(true);
  const [defaultCategory, setdefaultCategory] = useState("Todas")
  const [showTutorial, setShowTutorial] = useState(false);

  const login = () => {
    history.replace({ pathname: "/login" });
  };
  const nft = () => {
    history.replace({ pathname: "/nft" });
  };


  const getExperiencias = async () => {
    setloading(true);

    try {
      let res = await axios.get(`${BASE_PATH}/experiences`);
      if (res.status === 200) {
        setExperiencias(res.data.rows);
        setloading(false);
      }
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };

  const getCategorys = async () => {
    try {
      if (user.logged === true) {
        let result = await axios.get(`${BASE_PATH}/categories`, {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        });
        if (result.status === 200) {
          let onlyCategoryName = result.data.rows.reverse().map((item) => {
            return { value: item.category, label: item.category, rol: item.id };
          });
          onlyCategoryName.push({
            value: "Mis gustos",
            label: "Mis gustos",
            rol: "my",
          });
          onlyCategoryName.push({ value: "Todas", label: "Todas", rol: "all" });
          onlyCategoryName.reverse();
          setCategorys(onlyCategoryName);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExpCategory = async (cats) => {
    //console.log(cats);
    setloading(true);

    try {
      let result = await axios.post(
        `${BASE_PATH}/experiences/by-category`,
        {
          categories: cats,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );
      if (result.status === 200) {
        setExperiencias(result.data.rows);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const actionFilter = ({ rol, value }) => {
    setdefaultCategory(value)
    if (rol === "all") {
      getExperiencias();
    } else if (rol === "my") {
      misgustos && misgustos.length > 0
        ? getExpCategory(misgustos)
        : getExperiencias();
    } else {
      getExpCategory([rol]);
    }
  };

  const getMyGustos = async () => {
    try {
      let myGustos = await axios.get(
        `${BASE_PATH}/users/categories/${user.data.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      if (myGustos.status == 201) {

        const gustos = myGustos.data.row.categories.map((e) => e.id);

        if (gustos.length > 0) {

          setmisgustos(gustos)
          getExpCategory(gustos)

        } else {

          setdefaultCategory("Todas")
          getExperiencias()

        }

      }
    } catch (error) {
      console.log(error);
    }
  };

  // 0 === usuario no logeado
  // 1 === usuario logeado, sesion y token validos
  // 2 === usuario logeado, sesion y token expirados

  const handleValidateToken = async () => {
    let token = user?.data?.token;
    if (token !== undefined) {
      axios
        .post(`${BASE_PATH}/users/verifyTokenJwt`, { token })
        .then((resp) => {
          if (resp.status === 200 && resp.data.msg === "Token valid") {
            setinvalidSesion(false);
          } else {
            setinvalidSesion(true);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("token invalido");
          setinvalidSesion(true);
        });
    }
  };


  useEffect(() => {
    if (
      user.logged &&
      (user.data.user.state === 1 || user.data.user.role === "owner")
    ) {
      history.replace({ pathname: `/host/_/${user.data.user.id}` });
    }
  }, [user]);

  //Aqui va la configuracion de los pasos del tutorial
  const stepsTutorial = [
    {
      element: ".hola",
      intro: (
        <div className="text-center bg__tutorial text-grey">
          <h2 className="heavy orange__gradient">VIVE EXPERIENCIAS</h2>
          <h6>
            Conecta con Guías Turísticos Locales, Profesionales Gastronomicos o
            Deportistas Extremos de toda Centroamérica y El Caribe, nos importa
            demasiado la <span className="bold">inclusión social</span>.
          </h6>
          <br />
          <h6>
            Somos <span className="bold">¡TURISMOS SOSTENIBLE!.</span>
          </h6>
        </div>
      ),
    },
    {
      element: ".hola",
      intro: (
        <div className="text-center bg__tutorial text-grey">
          <h2 className="heavy orange__gradient">CHATÉA CON TU ANFITRIÓN</h2>
          <h6>
            ¿Necesitas más detalles?, ¿Quieres saber si puedes llevar a tu
            mascota?.
          </h6>
          <br />
          <h6>
            Experimenta adrenalina desde tener una{" "}
            <span className="bold">CONVERSACIÓN</span> con tu anfitrión local al
            instante, resolviendo tus preguntas hasta{" "}
            <span className="bold">VIVIR</span> tu próxima aventura de forma
            simple y segura. 😎.{" "}
          </h6>
        </div>
      ),
    },
    {
      element: ".hola",
      intro: (
        <div className="text-center bg__tutorial text-grey">
          <h2 className="heavy orange__gradient">RESERVA AL INSTANTE</h2>
          <h6>
            En 3 simples pasos podrás reservar esa anhelada experiencia
            turística de forma segura dentro de nuestra plataforma.
          </h6>
          <br />
          <h6>
            Contamos con un sistema de cobros en línea antifraude integrado,
            garantizamos tu tranquilidad para cuando llegues al destino tu
            anfitrión este esperando.{" "}
          </h6>
        </div>
      ),
    },
    {
      element: ".hola",
      intro: (
        <div className="text-center bg__tutorial text-grey">
          {/*<img src={tuto1} className="img__tutos"  style={{paddingBottom:'2rem'}}/>*/}
          <h2 className="heavy orange__gradient">CREA TUS PROPIOS TOURS</h2>
          <h6>
            Genera tus propios <span className="bold">ITINERARIOS</span> con las
            experiencias que encuentras en <span className="bold">Tugo</span>,
            las cuales son de diferentes anfitriones y destinos.
          </h6>
          <br />
          <h6>
            Crea tus <span className="bold">RUTAS PROPIAS</span>, para ponerte
            en contexto, nosotros llamamos{" "}
            <span className="bold">ANFITRIONES</span> a nuestros Guías
            Turísticos Locales, ¡Estarás en buenas manos!.{" "}
          </h6>
        </div>
      ),
    },
    {
      element: ".hola",
      intro: (
        <div className="text-center text-grey ">
          <img src={tuto1} className="img__tutos" />
          {/*<Button onClick={home} className="btn-primary2 mt-3 bold">Empezar <RiArrowRightCircleFill/></Button>*/}
        </div>
      ),
    },
  ];


  useEffect(() => {

    if (invalidSesion !== undefined && user.logged) {

      if (!invalidSesion) {
        getCategorys();
        setdefaultCategory("Mis gustos")
        getMyGustos();

      } else {
        setdefaultCategory("Todas")
        getExperiencias();

      }
    } else if (invalidSesion === undefined && !user.logged) {
      setdefaultCategory("Todas")
      getExperiencias();
    }

  }, [invalidSesion])


  useEffect(() => {
    handleValidateToken();

    if (sessionStorage.getItem("alerta")) {
      if (user?.data?.user) {
        if (
          !user.data.user.alias ||
          !user.data.user.birthday ||
          !user.data.user.description ||
          !user.data.user.direction ||
          !user.data.user.gender ||
          !user.data.user.phone
        ) {
          setalerta(true);
          sessionStorage.clear();
        }
      }
    }
  }, []);

  const Tutorial = () => {
    history.replace({ pathname: "/tuto" });
  };




  return (
    <>
      <Steps
        enabled={showTutorial}
        steps={stepsTutorial}
        initialStep={0}
        onExit={() => {
          setShowTutorial(false);
          sessionStorage.removeItem("status");
        }}
        onComplete={true}
      />{" "}
      {alerta && (
        <Alert
          onClick={() => history.replace({ pathname: "/configuraciones" })}
          style={{ position: "fixed", bottom: "0" }}
          message="¡Bienvenido a nuestra #comunidadtugo!, completa tu perfil de viajero AQUÍ"
          showIcon
          type="warning"
          closable
          className="fixed-bottom bold bg-orange-gradient border-0 alert__orange"
        />
      )}
      <div>
        <Viajero />
      </div>

      <div style={{ paddingBottom: 140 }}>
        <div className="d-none d-md-none d-lg-block">
          <Row className="page-header-container">
            <Col /*lg={9}*/>
              <ListaExperiencias
                experiencias={experiencias}
                categorias={categorys}
                filterCategory={actionFilter}
                gustos={misgustos}
                loading={loading}
                defaultSelect={defaultCategory}
              />
            </Col>
            <Col lg={3} href="https://www.muniantigua.gob.gt/legado/#/">
              <div className="card-nft-ss" >
                <h2 className="bold">Semana Santa 2023</h2>
                <img src={semanasanta} className="ss" />
                <br />
                <div className="d-flex justify-content-center">
                  <img src={semanasanta2} className="logoAyuntamiento" />
                </div>
                <a className="btn-circle-link" href="https://www.muniantigua.gob.gt/legado" target="_blank">
                  <i className="icon-right-arrow"></i>
                </a>

                {/*<Row className="mb-3">
                  <Col className="text-grey bold nft_data"><img src={nft1} className="nft"/>0</Col>
                  <Col className="text-grey bold nft_data"><img src={money} className="nft"/>$ 0000.00</Col>
                </Row>
                
                <text className="text-grey bold">Ganancias Disponibles</text>
                <h3 className="bold orange">$ 0.00</h3>
                
                <button className="btn-circle" onClick={nft}>
                  <i className="icon-right-arrow"></i>
                </button>*/}
              </div>
            </Col>
          </Row>
        </div>

        <div className="search-movil d-none d-md-block d-lg-none d-sm-block d-md-none d-block d-sm-none">
          <nav
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            className="navbar-sm-more navbar-light fixed-top"
          >
            <img src={logo} className="logo_in2" alt="logo" />
            <div className="avatar_sm">



              {user.logged === false ? (
                <div>
                  <TranslateButton />

                  <Link to="/login">
                    <button
                      type="button"
                      className="btn btn-primary-reserva bold"
                      variant="primary">
                      Ingresar
                    </button>

                  </Link>
                  <a
                    className=" btn-antigua bold text-center"
                    href="https://www.muniantigua.gob.gt/legado" target="_blank"
                  >
                    <img src={semanasanta2} className="logoAyuntamiento2" /> SEMANA SANTA 2023 <i className="icon-right-arrow"></i>
                  </a>



                  {/*<button
                    className="btn-primary btn-login bold"
                    onClick={Tutorial}
                  >
                    ¿Qué es Tugo? <i className="icon-right-arrow"></i>


                </button>*/}


                </div>
              ) : (

                <></>

              )}
              {user.logged === true ? (
                <Link to="/configuraciones" className=" btn-primary-menu">
                  <Avatar
                    size={40}
                    src={
                      user.data.state == 1
                        ? user.data.user.infoExtra
                          ? user.data.user.infoExtra.picture
                            ? user.data.user.infoExtra.picture
                            : user.data.user.picture
                          : user.data.user.picture
                        : user.data.user.picture
                    }
                  />
                </Link>
              ) : (
                <></>
              )}{" "}


            </div>
          </nav>
          <ListaExperiencias
            experiencias={experiencias}
            categorias={categorys}
            filterCategory={actionFilter}
            gustos={misgustos}
            loading={loading}
            defaultSelect={defaultCategory}
          />
        </div>
        {loading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            <Loader
              inverse
              center
              size="md"
              content="Cargando..."
              className="text-white"
            />
          </div>
        )}

      </div>
      {/*<Alert
        className="exp-fixed-top-notice"
        banner
        message={
          <Marquee gradient={false}>
            <strong className="bold">¡Hola #TUGER!</strong>, Nuestra app aún no
            esta terminada, nos encontramos realizando los últimos ajustes para
            lanzar en Junio 2022 el app, estáte atento a nuestras redes
            sociales.....
          </Marquee>
        }
      />*/}

      {invalidSesion && (
        <Message
          style={{
            position: "fixed",
            margin: "20px",
            width: "",
            top: "3rem",
            height: "",
            color: "#FFF",
            zIndex: '10'
          }}

          type="warning"
          header="warning"
          description={
            <p className="bold">
              Tu sesión ha caducado. Por favor vuelve a logearte!
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary-reserva bold mt-3 " href="/login" onClick={login}> Iniciar Sesión </button>
              </div>
            </p>
          }
        ></Message>
      )}
    </>
  );
};

export default Parati;
