import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";



import AppTugo from "./AppTugo";
import * as serviceWorker from "./serviceWorkerRegistration";

import { FirebaseAppProvider } from "reactfire";
import { VsNetContext } from "./contexts/VsNetContext";

const firebaseConfig = {
  apiKey: "AIzaSyBfuzf_YkehUolTYZqVAtPd5lNOBYy5Zd8",
  authDomain: "tugo-a.firebaseapp.com",
  projectId: "tugo-a",
  storageBucket: "tugo-a.appspot.com",
  messagingSenderId: "528668811942",
  appId: "1:528668811942:web:815214986e4cc29e4c8a67",
  measurementId: "G-DX7DCJK94D",
};



ReactDOM.render(

  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AppTugo />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();
