import React, { useState, useEffect, useContext } from "react";
import { BASE_PATH } from "../../constants";
import axios from "axios";
import { AuthContext } from "../../../auth/AuthContext";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

import logo from "../../../assets/images/icono.svg";

export const Favoritos = () => {
    const { user } = useContext(AuthContext);
    const [favorites, setFavorites] = useState([]);

    const getFavorites = async () => {
        try {
            let result = await axios.get(
                `${BASE_PATH}/favorites?user=${user.data.user.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            );

            if (result.status === 200) {
                setFavorites(result.data.rows);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getFavorites();
    }, []);

    return (
        <div>
            {favorites.length > 0 &&
                favorites.map((item) => {
                    //console.log(item.experiences.id)
                    return (
                        <Link
                            key={item.experiences.id}
                            to={`/experiencia/${item.experiences.id}`}
                        >
                            <div key={item.id}>
                                <Card className="pointer border-0 image04 card-fav">
                                    <Card.Img
                                        src={
                                            item.experiences.pictures && item.experiences.pictures[0]
                                                ? item.experiences.pictures[0].path
                                                : logo
                                        }
                                        className=""
                                    />
                                    <Card.ImgOverlay>
                                        <div className="card-bottom-fav">
                                            <p className="bold text-white card-titulo-fav">
                                                {item.experiences.name}
                                            </p>
                                            <p className="place">
                                                <i className="icon-marker"></i>
                                                {"   "}
                                                {item.experiences.direction}
                                            </p>
                                        </div>
                                    </Card.ImgOverlay>
                                </Card>
                            </div>
                        </Link>
                    );
                })}
        </div>
    );
};
