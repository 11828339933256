import React, {  } from "react";
import { Avatar, Alert, Steps, Button, message } from "antd";
import logo from "../../assets/images/logo.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "../../auth/AuthContext";
import "../Experiencias/introjs.css";
import { Row, Col } from 'react-bootstrap';


const Tutorial = () => {
  const history = useHistory();
  const Parati = () => {
    history.replace({ pathname: "/" });
  };
  const [current, setCurrent] = React.useState(0);
  
    const next = () => {
      setCurrent(current + 1);
    };
  
    const prev = () => {
      setCurrent(current - 1);
    };
    
  const { Step } = Steps;

  const steps = [
    {
      title: '',
      content: (
      <div>
         {/* PARA MOVIL */}
         <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none"> 
         <Row>
           <Col md={12} className=" bg__tuto1__movil"></Col>
            <Col>
            <div className="text-center bg__tutorial text-grey container">
              <h2 className="heavy orange__gradient">VIVE EXPERIENCIAS</h2>
              <h5>
                Conecta con Guías Turísticos Locales, Profesionales Gastronomicos o
                Deportistas Extremos de toda Centroamérica y El Caribe, nos importa
                demasiado la <span className="bold">inclusión social</span>.
              </h5>
              <br />
              <h5>
                Somos <span className="bold">¡TURISMO SOSTENIBLE!.</span>
              </h5>
            </div>
          </Col>
         </Row>

        </div>
        
        {/* PARA WEB */}
        <div className="d-none d-md-none d-lg-block "> 
          <Row>
            <Col lg={6} className="d-flex align-items-center container__tuto  bg__tutorial">
              <div className=" text-grey">
                <h1 className="heavy orange__gradient">VIVE EXPERIENCIAS</h1>
                <h3>
                  Conecta con Guías Turísticos Locales, Profesionales Gastronomicos o
                  Deportistas Extremos de toda Centroamérica y El Caribe, nos importa
                  demasiado la <span className="bold">inclusión social</span>.
                </h3>
                <br />
                <h3>
                  Somos <span className="bold">¡TURISMO SOSTENIBLE!.</span>
                </h3>
              </div>
            </Col>
            <Col lg={6} className="bg__tuto1"></Col>
          </Row>
        </div>
      </div>
      ),
    },
    {
      title: '',
      content: (
      <div>
       {/* PARA MOVIL */}
       <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none">
        <Row>
          <Col md={12} className=" bg__tuto2__movil"></Col>
          <Col>
            <div className="text-center bg__tutorial text-grey container">
            <h2 className="heavy orange__gradient">CHATÉA CON TU ANFITRIÓN</h2>
            <h5>
              ¿Necesitas más detalles?, ¿Quieres saber si puedes llevar a tu
              mascota?.
            </h5>
            <br />
            <h5>
              Experimenta adrenalina desde tener una{" "}
              <span className="bold">CONVERSACIÓN</span> con tu anfitrión local al
              instante, resolviendo tus preguntas hasta{" "}
              <span className="bold">VIVIR</span> tu próxima aventura de forma
              simple y segura. 😎.{" "}
            </h5>
            </div>
          </Col>
          </Row>
        </div>

        {/* PARA WEB */}
        <div className="d-none d-md-none d-lg-block "> 
          <Row>
            <Col lg={6} className="d-flex align-items-center container__tuto  bg__tutorial">
              <div className=" text-grey">
                <h1 className="heavy orange__gradient">CHATÉA CON TU ANFITRIÓN</h1>
                <h3>
                ¿Necesitas más detalles?, ¿Quieres saber si puedes llevar a tu
                mascota?.
                </h3>
                <br />
                <h3>
                Experimenta adrenalina desde tener una{" "}
                <span className="bold">CONVERSACIÓN</span> con tu anfitrión local al
                instante, resolviendo tus preguntas hasta{" "}
                <span className="bold">VIVIR</span> tu próxima aventura de forma
                simple y segura. 😎.{" "}
                </h3>
              </div>
            </Col>
            <Col lg={6} className="bg__tuto2"></Col>
          </Row>
        </div>
      </div>
      ),
    },
    {
      title: '',
      content: (
        <div>
        {/* PARA MOVIL */}
        <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none">
        <Row>
          <Col md={12} className=" bg__tuto3__movil"></Col>
          <Col>
          <div className="text-center bg__tutorial text-grey container">
          <h2 className="heavy orange__gradient">RESERVA AL INSTANTE</h2>
          <h5>
            En simples pasos podrás reservar experiencias
            turísticas de forma segura.
          </h5>
          <br />
          <h5>
            Contamos con un sistema de cobros en línea antifraude,
            garantizamos tu tranquilidad para cuando llegues al destino tu
            anfitrión este esperando.{" "}
          </h5>
        </div>
        </Col>
        </Row>
      </div>

       {/* PARA WEB */}
       <div className="d-none d-md-none d-lg-block "> 
       <Row>
         <Col lg={6} className="d-flex align-items-center container__tuto  bg__tutorial">
           <div className=" text-grey">
             <h1 className="heavy orange__gradient">RESERVA AL INSTANTE</h1>
             <h3>
             En 3 simples pasos podrás reservar esa anhelada experiencia
            turística de forma segura dentro de nuestra plataforma.
             </h3>
             <br />
             <h3>
             Contamos con un sistema de cobros en línea antifraude integrado,
            garantizamos tu tranquilidad para cuando llegues al destino tu
            anfitrión este esperando.{" "}
             </h3>
           </div>
         </Col>
         <Col lg={6} className="bg__tuto3"></Col>
       </Row>
     </div>
     </div>
      ),
    },
    {
      title: '',
      content: (
        <div>
        {/* PARA MÓVIL */}
        <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none">
        <Row>
          <Col md={12} className=" bg__tuto4__movil"></Col>
          <Col>
          <div className="text-center bg__tutorial text-grey container">
          {/*<img src={tuto1} className="img__tutos"  style={{paddingBottom:'2rem'}}/>*/}
          <h2 className="heavy orange__gradient">CREA TUS PROPIOS TOURS</h2>
          <h5>
            Genera tus propios <span className="bold">ITINERARIOS</span> con las
            experiencias que encuentres en <span className="bold">Tugo</span>,
            las cuales son de diferentes anfitriones y destinos.
          </h5>
          <br />
          <h5>
            Crea tus <span className="bold">RUTAS PROPIAS</span>, para ponerte
            en contexto, nosotros llamamos{" "}
            <span className="bold">ANFITRIONES</span> a nuestros Guías
            Turísticos Locales, ¡Estarás en buenas manos!.{" "}
          </h5>
        </div>
        </Col>
        </Row>
      </div>

      {/* PARA WEB */}
      <div className="d-none d-md-none d-lg-block "> 
      <Row>
        <Col lg={6} className="d-flex align-items-center container__tuto  bg__tutorial">
          <div className=" text-grey">
            <h1 className="heavy orange__gradient">CREA TUS PROPIOS TOURS</h1>
            <h3>
            Genera tus propios <span className="bold">ITINERARIOS</span> con las
            experiencias que encuentres en <span className="bold">Tugo</span>,
            las cuales son de diferentes anfitriones y destinos.
            </h3>
            <br />
            <h3>
            Crea tus <span className="bold">RUTAS PROPIAS</span>, para ponerte
            en contexto, nosotros llamamos{" "}
            <span className="bold">ANFITRIONES</span> a nuestros Guías
            Turísticos Locales, ¡Estarás en buenas manos!.{" "}
            </h3>
          </div>
        </Col>
        <Col lg={6} className="bg__tuto4"></Col>
      </Row>
    </div>
    </div>
      ),
    },
  ];

 

  return (
    <div className="tutorial d-flex justify-content-center
      d-flex align-items-center vh-100 fixed-top">

       {/* PARA MÓVIL */}
      <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none">
        <div className="steps-content">{steps[current].content}</div>
          <div className="fixed-bottom  mb-3">
          <div className="steps-action text-center mt-3 mb-4">
            {current > 0 && (
              <button className="btn btn-primary-goback bold" style={{ margin: '0 8px' }} onClick={() => prev()}>
               ←
              </button>
            )}
            {current < steps.length - 1 && (
              <button className="btn btn-primary-reserva bold"  onClick={() => next()}>
                Siguiente
              </button>
            )}
            {current === steps.length - 1 && (
              <button className="btn btn-primary-reserva bold"  onClick={Parati}>
                ¡Listo!
              </button>
            )}
          </div>
        </div>
      </div>

       {/* PARA WEB */}
      <div className="d-none d-md-none d-lg-block">
        <div className="steps-content">{steps[current].content}</div>
          <div className="fixed-bottom  mb-3">
          <Row>
            <Col>
              <div className="steps-action text-center mt-3 mb-4">
                {current > 0 && (
                  <button className="btn btn-primary-goback bold" style={{ margin: '0 8px' }} onClick={() => prev()}>
                  ←
                  </button>
                )}
                {current < steps.length - 1 && (
                  <button className="btn btn-primary-reserva bold"  onClick={() => next()}>
                    Siguiente
                  </button>
                )}
                {current === steps.length - 1 && (
                  <button className="btn btn-primary-reserva bold"  onClick={Parati}>
                    ¡Listo!
                  </button>
                )}
              </div>
            </Col>
            <Col></Col>
          </Row>

        </div>
      </div>
      
    </div>
  );
};

export default Tutorial;
