import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "antd/dist/antd.css";
import { TabLink, TabContent, Tabs } from "react-tabs-redux";
import { RiEdit2Fill } from "react-icons/ri";
import axios from "axios";
import { Media, Row, Col } from "react-bootstrap";
import { Popover } from 'antd';
import { Alert, Button, Drawer, Icon, IconButton, Loader, } from "rsuite";

//Importar Recursos
import País from "../../../assets/images/paises/banderas/guate.svg";
import Background from "../../../assets/images/cards/barrio.jpg";
import ExperienciaGaleria from "../../Experiencias/ExperienciaGaleria";
import ExperienciaDetails from "./ExperienciaDetails";
import ExperienciaReview from "../../Experiencias/ExperienciaReview";
import { BASE_PATH } from "../../constants";

import { AuthContext } from "../../../auth/AuthContext";
import logo from "../../../assets/images/logo.svg";
import ExperienciaNewGustos from "./ExperienciaNewGustos";
import ExperienciaNewPhotos from "./ExperienciaNewPhotos";
import ExperienciaNewPagar from "./ExperienciaNewPagar";


const InitShowModalState = {
  open: false,
  photos: false,
  categories: false,
  prices: false
}

const ExperienciaSubida = () => {

  const { user } = useContext(AuthContext);
  const history = useHistory();
  const { experienciaid } = useParams();

  const [selectedCategories, setselectedCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [coupon, setcoupon] = useState(false);
  const [discount, setdiscount] = useState(false);
  const [precios, setPrecios] = useState(false);
  const [selectedPhotos, setselectedPhotos] = useState([])
  const [experiencia, setExperiencia] = useState([]);
  const [schedulesExist, setschedulesExist] = useState(false)
  const [showModal, setshowModal] = useState(InitShowModalState)
  const [visualizePrices, setVisualizePrices] = useState(false)

  const HomeB = () => history.replace({ pathname: `/host/_/${user.data.user.id}` });

  const Editar = () => history.replace({ pathname: `/host/editar/_/${experienciaid}` });

  const Schedules = () => history.replace({ pathname: `/experiencia/horarios/_/${experienciaid}` });


  const setCoupon = (cupon) => setcoupon(cupon);

  const setDiscount = (descuento) => setdiscount(descuento);

  const onPrecios = (precio) => setPrecios(precio);

  const handlePhotos = () => setshowModal({ open: true, photos: true })

  const handleCategories = () => setshowModal({ open: true, categories: true })

  const handlePrices = () => setshowModal({ open: true, prices: true })


  const getExperiencias = async () => {
    try {
      const res = await axios.get(`${BASE_PATH}/experiences/${experienciaid}`);
      if (res.status === 200) {

        setExperiencia([res.data]);
        const schedules = await getSchedules(res.data.id)
        setschedulesExist(schedules)
        setselectedCategories(res.data.category)

        const pics = res.data.pictures.map((pic) => {
          return {
            uid: Math.random(1, 99999),
            url: pic.path,
            id: pic.image_id,
          };
        });

        setselectedPhotos(pics);

        const { price } = res.data
        const { adultPrice, childrenPrice } = price

        if (adultPrice === "" || childrenPrice === "" || adultPrice === '0' || childrenPrice === '0') {
            setVisualizePrices(false)
        }else{
          setVisualizePrices(true)

        }

      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSchedules = async (id = experiencia[0]?.id) => {
    try {
      const resp = await axios.get(
        `${BASE_PATH}/schedules?experience=${id}`
      );

      if (resp.status === 200 && resp.data.count > 0) {
        return true
      }

      return false

    } catch (error) {

      console.log(error);
      return false

    }
  };

  const changeDraftMode = async () => {

    if (experiencia[0]?.isDraft) {

      const validSchedules = await getSchedules()
      const validCategories = selectedCategories.length > 0
      const validPhotos = selectedPhotos.length > 0
      const validPrices = visualizePrices

      if (!validSchedules) {
        Alert.info("Debes agregar horarios antes de publicar la experiencia", 5000)
        return
      }
      if (!validCategories) {
        Alert.info("Debes agregar categorías antes de publicar la experiencia", 5000)
        return
      }
      if (!validPhotos) {
        Alert.info("Debes agregar fotos antes de publicar la experiencia", 5000)
        return
      }
      if (!validPrices) {
        Alert.info("Debes agregar precios antes de publicar la experiencia", 5000)
        return
      }

      onChangeDraftMode()

    } else {

      onChangeDraftMode()

    }

  }

  const onChangeDraftMode = async () => {
    try {

      const resp = await axios.put(
        `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
        {
          isDraft: !experiencia[0]?.isDraft
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      if (resp.status === 200) {
        setExperiencia([resp.data]);
        Alert.success("Se realizó con éxito", 3000)
      }

    } catch (error) {
      console.log(error);
      Alert.error("Ha ocurrido un error en el servidor", 3000)

    }
  }

  const handleSaveCategories = async () => {
    setLoading(true)
    try {

      const resp = await axios.put(
        `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
        {
          category: selectedCategories
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      if (resp.status === 200) {
        setselectedCategories(resp.data.category)
        Alert.success("Se actualizaron las categorias", 3000)
        setLoading(false)
        setshowModal({ open: false })
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
      Alert.error("Ha ocurrido un error en el servidor", 3000)

    }
  }

  const handleAddNewPhotos = async () => {

    const filterPhotosPerUpload = selectedPhotos.filter((photo) => photo.originFileObj)

    if (filterPhotosPerUpload.length > 0) {
      setLoading(true)
      const fdata = new FormData();
      fdata.append("experiencesId", experiencia[0]?.id);

      filterPhotosPerUpload.forEach((foto) => {
        fdata.append("name", foto.originFileObj);
      });


      try {
        const imagesUp = await axios.post(`${BASE_PATH}/images`, fdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.data.token}`,
          },
        })


        if (imagesUp.status === 201) {

          const newPhotos = imagesUp.data.images_path.map((newPhoto) => {
            return {
              uid: Math.random(1, 99999),
              edit: true,
              url: newPhoto.path,
              id: newPhoto.image_id,
            };
          })

          const concatImages = selectedPhotos.filter((photo) => !photo.originFileObj).concat(newPhotos)
          setselectedPhotos(concatImages)

          Alert.success("Se actualizaron las fotos", 3000)
          setLoading(false)
          setshowModal({ open: false })

        }

      } catch (error) {
        setLoading(false)
        console.log(error);
        Alert.error("Ha ocurrido un error en el servidor", 3000)

      }
    }

  }

  const handleUpdatePrices = async () => {

    const { adultos, ninos } = precios
    const { price, people } = discount
    const { discount: discountPercent, code } = coupon

    if (adultos === "" || ninos === "" || adultos === '0' || ninos === '0') {
      Alert.warning("Completa los campos requeridos")
      return
    }
    setLoading(true)

    const newPrices = {
      adultPrice: adultos || 0,
      childrenPrice: ninos || 0,
      coupon: {
        code: code && discountPercent ? code : "",
        discount: code && discountPercent ? discountPercent : ""
      },
      discount: {
        price: price && people ? price : "",
        people: price && people ? people : "",
      },
    }

    try {

      const resp = await axios.put(
        `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
        {
          price: newPrices
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      if (resp.status === 200) {
        setExperiencia([resp.data])
        setVisualizePrices(true)
        Alert.success("Se actualizaron los precios", 3000)
        setLoading(false)
        setshowModal({ open: false })
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
      Alert.error("Ha ocurrido un error en el servidor", 3000)

    }

  }


  const PopoverEditContent = (
    <div className="popover-edit-content">
      <p><Button block onClick={changeDraftMode} color={experiencia[0]?.isDraft ? 'green' : 'red'} appearance="ghost">{experiencia[0]?.isDraft ? 'Publicar ahora' : 'Pasar a borrador'}</Button></p>
      <p><IconButton block onClick={Editar} icon={<Icon icon='check-circle' className="green-color" />}  >Información</IconButton></p>
      <p><IconButton block onClick={Schedules} icon={schedulesExist ? <Icon icon='check-circle' className="green-color" /> : <Icon icon='exclamation-circle' className="red-color" />} >Horarios</IconButton></p>
      <p><IconButton block onClick={handleCategories} icon={selectedCategories.length > 0 ? <Icon icon='check-circle' className="green-color" /> : <Icon icon='exclamation-circle' className="red-color" />}>Categorías</IconButton></p>
      <p><IconButton block onClick={handlePhotos} icon={selectedPhotos.length > 0 ? <Icon icon='check-circle' className="green-color" /> : <Icon icon='exclamation-circle' className="red-color" />}>Fotos</IconButton></p>
      <p><IconButton block onClick={handlePrices} icon={visualizePrices ? <Icon icon='check-circle' className="green-color" /> : <Icon icon='exclamation-circle' className="red-color" />}>Precios</IconButton></p>
    </div>
  );

  const evaluateClickActionInDrawer = () => {

    const { categories, photos, prices } = showModal


    if (categories) {
      handleSaveCategories()
    }

    if (photos) {
      handleAddNewPhotos()
    }

    if (prices) {
      handleUpdatePrices()
    }

  }


  useEffect(() => {

    getExperiencias();

  }, []);

  return (
    <div>
      <button
        style={{ zIndex: "99999" }}
        type="button"
        className="back-window fixed-top"
        onClick={HomeB}
      >
        <i className="  icon-left-arrow"></i>
      </button>
      <img src={logo} className="logo_in" alt="logo" />


      <section className="exp-header fixed-top d-none d-md-none d-lg-block">

        <Row >
          <Col>
            <div
              className="d-flex align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="bold exp-titulo exp-container">
                <Media>
                  <img
                    width={40}
                    height={40}
                    className="align-self-center mr-3 border-flag rounded-circle"
                    src={País}
                    alt="Generic placeholder"
                  />
                  <Media.Body>
                    <h5 className="text-white">
                      {experiencia.length > 0 ? (
                        experiencia[0].direction
                      ) : (
                        <></>
                      )}
                    </h5>
                  </Media.Body>
                </Media>
                <p style={{ fontSize: "70px" }}>
                  {experiencia.length > 0 ? `${experiencia[0].name} ` : <></>} <b className={experiencia[0]?.isDraft ? 'red-color' : 'green-color'}>{experiencia[0]?.isDraft ? '(borrador)' : '(publicada)'}</b>
                </p>

                <div className="review">
                  <i
                    className="icon-star"
                    style={{ color: "#fe5000", fontSize: "30px" }}
                  ></i>
                  <text className="bold" style={{ fontSize: "30px" }}>
                    {experiencia.length > 0 ? experiencia[0].rating : <></>}
                  </text>
                </div>

                <div>
                  <Row className="exp-details-about" style={{ width: "600px" }}>
                    <Col className="text-center">
                      <i className="icon-face" style={{ fontSize: "45px" }}></i>
                      <p style={{ fontSize: "16px", color: "grey" }}>Cupo</p>
                      <p className="bold">
                        {experiencia.length > 0 ? experiencia[0].quotas.min : <></>} -
                        {experiencia.length > 0 ? experiencia[0].quotas.max : <></>}
                        {" "}personas
                      </p>
                    </Col>
                    <Col className="text-center">
                      <i className="icon-time" style={{ fontSize: "45px" }}></i>
                      <p style={{ fontSize: "16px", color: "grey" }}>
                        Duración
                      </p>
                      <p className="bold">
                        {experiencia.length > 0 ? (
                          experiencia[0].duration
                        ) : (
                          <></>
                        )}
                      </p>
                    </Col>
                    <Col className="text-center">
                      <i
                        className="icon-money"
                        style={{ fontSize: "45px" }}
                      ></i>
                      <p style={{ fontSize: "16px", color: "grey" }}>
                        Duración
                      </p>
                      <p className="bold">
                        Adultos ${" "}
                        {experiencia.length > 0 ? (
                          experiencia[0].price.adultPriceWithCommissions || experiencia[0].price.adultPrice
                        ) : (
                          <></>
                        )}
                        , <br />
                        Niños ${" "}
                        {experiencia.length > 0 ? (
                          experiencia[0].price.childrenPriceWithCommissions || experiencia[0].price.childrenPrice
                        ) : (
                          <></>
                        )}{" "}
                        <></>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col
            className="exp-header-pic"
            lg={4}
            style={{
              backgroundImage: `url(${experiencia.length > 0 &&
                selectedPhotos[0] ? selectedPhotos[0].url : Background
                })`,
            }}
          ></Col>
        </Row>
        <i className="fi fi-br-angle-down neon_blue icon-font-exp text-center"></i>
      </section>
      <section
        className="exp-header fixed-top d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none"
        style={{
          height: "100vh",
          backgroundImage: `url(${experiencia.length > 0 && selectedPhotos[0] ? selectedPhotos[0].url : Background})`,
        }}
      >
        <div className="bold exp-titulo container-sm">
          <p className="text-left">
            {experiencia.length > 0 ? experiencia[0].name : <></>}
          </p>
        </div>
        <Media
          className="container"
          style={{ marginTop: "-2rem", position: "absolute", bottom: "2rem" }}
        >
          <Media.Body>
            <h5 className="text-white text-left mt-1">
              {experiencia.length > 0 ? experiencia[0].direction : <></>}
            </h5>
          </Media.Body>
        </Media>
      </section>

      <section className="exp-contenido2">
        <Tabs className="container-sm">
          <div className="sticky-top exp-tabs">
            <TabLink
              to="tab1"
              className="exp-contenido-btn exp-active"
              activeClassName="neon_blue"
            >
              <i className="  icon-details"></i> Info
            </TabLink>
            <TabLink
              to="tab2"
              className="exp-contenido-btn exp-active"
              activeClassName="neon_blue"
            >
              <i className=" icon-pics"></i>Pics
            </TabLink>
            <TabLink
              to="tab3"
              className="exp-contenido-btn exp-active"
              activeClassName="neon_blue"
            >
              <i className=" icon-star"></i>
            </TabLink>
          </div>
          <TabContent for="tab1">
            <ExperienciaDetails data={experienciaid} />
          </TabContent>
          <TabContent for="tab2" className="exp-galeria">
            <ExperienciaGaleria photos={selectedPhotos} />
          </TabContent>
          <TabContent for="tab3">
            <ExperienciaReview data={experienciaid} />
          </TabContent>
          <TabContent for="tab4"></TabContent>
        </Tabs>
        <>
          <Popover content={PopoverEditContent} trigger="click" placement="topRight">
            <button
              style={{ zIndex: "1000" }}
              type="button" 
              className="exp-fixed-bottom-open-host-upload"
            // onClick={Editar}
            >
              <RiEdit2Fill style={{ fontSize: 26 }} />
            </button>
          </Popover>


        </>
      </section>

      {experiencia[0] && (
        <Drawer
          placement="bottom"
          closable={false}
          show={showModal.open}
          full={true}
          style={{ width: "100%" }}
          backdrop={true}
          drawerStyle={{ background: "#17191A" }}
        >

          <nav className="navbar-titles navbar-light d-flex align-items-center">

            <button
              type="button"
              style={{ position: "absolute", top: "25px", left: "25px" }}
              className="back-window-navbar-drawer"
              onClick={() => setshowModal({ open: false })}
            >
              <i className=" icon-left-arrow"></i>
            </button>

            <h5 className="bold">

              {showModal.categories && ('¡Agrega categorías!')}
              {showModal.photos && ('¡Sube tus fotografías!')}
              {showModal.prices && ('¡Ajusta tus precios!')}

            </h5>
          </nav>

          <Drawer.Body>
            <>
              <div className="steps-content">

                {
                  loading && (
                    <Loader backdrop content="Cargando..." vertical />
                  )
                }

                {
                  showModal.categories && (
                    <ExperienciaNewGustos
                      cats={selectedCategories}
                      selectCategories={setselectedCategories}
                    />
                  )
                }

                {
                  showModal.photos && (
                    <ExperienciaNewPhotos
                      pictures={selectedPhotos}
                      selectPictures={setselectedPhotos}
                      experience={experiencia[0]?.id}
                    />
                  )
                }

                {
                  showModal.prices && (
                    <ExperienciaNewPagar
                      exp={experiencia[0]}
                      nextContent={onPrecios}
                      descuento={setDiscount}
                      cupon={setCoupon}
                    />
                  )
                }


              </div>

              <div className="steps-action bold navbar-sm-more-host exp-fixed-bottom">
                <Button
                  className="bold exp-pasos btn-primary-host btn-reserva"
                  type="primary"
                  onClick={evaluateClickActionInDrawer}
                >
                  Guardar
                </Button>
              </div>
            </>
          </Drawer.Body>
        </Drawer>
      )}
    </div>
  );
};

export default ExperienciaSubida;
