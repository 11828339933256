import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/App.css";
import "antd/dist/antd.css";

import { RiAddFill } from "react-icons/ri";

//Importar Recursos
import logo from "../../assets/images/logoDark.svg";
import "../../assets/css/iconos/css/fontello.css";
import { AuthContext } from "../../auth/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MenuDesplegable } from "./elementos/MenuDesplegable";

const menuItems = [
  {
    label: "Experiencias",
    to: "/host/_",
    icon: "demo-icon-first icon-home",
    sm_icon: "demo-icon2 icon-home",
    style: {},
  },
  {
    label: "Pagos",
    to: "/host/pagos",
    icon: "demo-icon-first  icon-money",
    sm_icon: "demo-icon2 icon-money",
    style: {},
  },
  {
    label: "Calendario",
    to: "/host/calendario",
    icon: "demo-icon-first icon-calendar2",
    sm_icon: "demo-icon2 icon-calendar2",
    style: {},
  },
  {
    label: "Chat",
    to: "/host/chat",
    icon: "demo-icon-first icon-chat",
    sm_icon: "demo-icon2 icon-comment",
    style: { },
  },
];

const Viajero = () => {
  const history = useHistory();
  const New = () => {
    history.replace({ pathname: "/new" });
  };
  const { user } = useContext(AuthContext);
  const { id } = user.logged ? user.data.user : { id: null };

  const Configuraciones = () => {
    history.replace({ pathname: "/host/configuraciones" });
  };
  return (
    <div>
      <div className="d-none d-md-none d-lg-block">
        <nav className="navbar navbar-light fixed-top">
          <img src={logo} className="logo" alt="logo" />
          <div className="d-none d-md-none d-lg-block navbar-search"></div>
          <ul className="navbar-nav navbar-first nav-pills d-none d-md-none d-lg-block">
            <li>
              {menuItems.map((el, i) => (
                <NavLink
                  key={`menu_item_${el.label}_${i}`}
                  to={el.label === "Experiencias" ? `${el.to}/${id}` : el.to}
                  className="heavy padding btn-primary-menu-first"
                  activeClassName="nav-link-active"
                  style={{ ...el.style }}
                >
                  <i className={el.icon}></i>
                  {el.label}
                </NavLink>
              ))}

              {!user.logged ? (
                <Link to="/login">
                  <button
                    type="button"
                    class="btn btn-primary-reserva 
                                        bold"
                    variant="primary"
                  >
                    Ingresar
                  </button>
                </Link>
              ) : (
                <></>
              )}
            </li>
          </ul>
          {user.logged ? (
            <MenuDesplegable rol={"Alfitrion"}></MenuDesplegable>
          ) : (
            <div className="dropdown">
              <div
                className="dropdown-menu 
                            dropdown-menu-right"
                aria-labelledby="dropdownMenu2"
              >
                <h4 className="bold text-center">Pablo Sierra</h4>
                <Link
                  onClick={Configuraciones}
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className="dropdown-item text-white bold"
                    type="button"
                  >
                    <i className="demo-icon-menu icon-settings orange"></i>
                    Configuraciones
                  </button>
                </Link>
              </div>
            </div>
          )}
        </nav>
      </div>

      <div className="d-block d-sm-none d-none d-sm-block d-md-none text-center">
        <div className="d-flex justify-content-center exp-fixed-upload">
          <button
            type="button"
            className="exp-fixed-bottom-open-host-upload"
            onClick={New}
          >
            <RiAddFill style={{ fontSize: 30 }} />
          </button>
        </div>
        <nav className="navbar-sm-host fixed-bottom">
          <ul className="navbar-nav">
            <li>
              {menuItems.map((el, i) => (
                <NavLink
                  key={`menu_item_${el.label}_${i}`}
                  to={el.label === "Experiencias" ? `${el.to}/${id}` : el.to}
                  className="bold  btn-primary-menu-first-host"
                  activeClassName="nav-host-active"
                >
                  <i className={el.sm_icon}></i>
                </NavLink>
              ))}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Viajero;
