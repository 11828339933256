import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BASE_PATH } from "../constants";
import axios from "axios";
import { Alert, Input } from "rsuite";
import "./style.css";

//Importar Recursos
import logo from "../../assets/images/icono.svg";

const Login_code = () => {
  //get phone number
  useEffect(() => {
    setPhone(sessionStorage.getItem("phone"));
  }, []);

  const history = useHistory();
  const ToBack = () => {
    history.replace({ pathname: "/login" });
  };

  const [v_code, setCode] = useState("");
  const [phone, setPhone] = useState("");

  const verificationCode = async (post) => {
    if (v_code === undefined) {
      Alert.warning("Campos requeridos vacios.", 2000);
      return;
    } else {
      try {
        let tutor = await axios.post(`${BASE_PATH}/users/receive-code`, {
          phone: phone,
          code: v_code,
        });
        if (tutor.status === 200) {
          Alert.success("Verificado correctamente", 2000);
          history.replace({ pathname: "/createuser" });
        }
      } catch (e) {
        console.log(e);
        if (e.response) {
          if (e.response.status === 404 || e.response.status === 400) {
            //  console.log(e.response.data.message);
            Alert.success("Codigo incorrecto", 2000);
            console.log(e.response.data.message);
          }
        }
      }
    }
  };
  const sendCode = async (post) => {
    if (phone === undefined) {
      Alert.warning("Campos requeridos vacios.", 2000);
      return;
    } else {
      try {
        let code = await axios.post(`${BASE_PATH}/users/send-code/`, {
          phone: phone,
        });
        if (code.status === 201) {
          Alert.success("Se ha enviado el codigo exitosamente", 2000);
          setCode("");
        }
      } catch (e) {
        console.log(e);
        if (e.response) {
          if (e.response.status === 404 || e.response.status === 400) {
            //  console.log(e.response.data.message);
            Alert.success(e.response.data.message, 2000);
          }
        }
      }
    }
  };

  return (
    <div
      className="d-flex justify-content-center
      d-flex align-items-center vh-100"
    >
      <button type="button" className="back-window fixed-top" onClick={ToBack}>
        <i className="demo-icon-exp  icon-left-arrow"></i>
      </button>
      <Card style={{ width: "18em" }} className="border-0">
        <img src={logo} className="login-logo" alt="logo" />
        <Card.Body>
          <Card.Title className="Medium login-title-code text-center">
            Introduce el código de 6 dígitos que te hemos enviado a <br />
            <text className="bold">{phone}</text>.
          </Card.Title>
          <Card.Text>
            <Form>
              <Input
                className="form-control-login-code btn-lg text-center"
                placeholder="000000"
                value={v_code}
                onChange={(value) => setCode(value)}
              />
            </Form>

            <button
              type="button"
              class="btn btn-primary-navbar
                    bold btn-login"
              onClick={verificationCode}
            >
              Verificar
            </button>
            <p
              className="lite login-text-redes text-center"
              style={{ marginTop: 20 }}
              onClick={sendCode}
            >
              Reenviar código
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login_code;
