import React, { useContext } from 'react'
import { AuthContext } from '../auth/AuthContext';
import image from '../assets/images/viajero.png'
import Viajero from './Navbars/Viajero';
import Anfitrion from "./Navbars/Anfitrión";

const NotFound = () => {

    const { user } = useContext(AuthContext);


    return (
        <>
            {
                user.logged && user.data.user.role !== "user" ? <Anfitrion /> : <Viajero />
            }

            <div className='notFound_page'>
                <div className='column1'>
                    <h1 className='notFound_text'>404</h1>
                    <h4 className='notFound_text'>Página no encontrada</h4>
                </div>
                <div className='column2' >
                    <img src={image} className="notFound_image" />
                </div>

            </div>
        </>

    )
}

export default NotFound