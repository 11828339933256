import React, { useState, useEffect, useContext, useMemo } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import { } from "react-router-dom";
import CounterInput from "react-counter-input";
import axios from "axios";
import { AuthContext } from "../../auth/AuthContext";
import { BASE_PATH } from "../constants";
import { AgregarTarjeta } from "../Configuraciones/AgregarTarjeta";
import { Alert } from "rsuite";
import { VsNetContext } from "../../contexts/VsNetContext";
import { v4 as uuidv4 } from "uuid";

const ExperienciaPagar = ({
  setPersonas,
  setMetodoPago,
  setNiños,
  experiencia,
  setCupon,
  dateTime
}) => {
  const { user } = useContext(AuthContext);
  const { setDeviceFingerprintID } = useContext(VsNetContext);
  const [listCardUser, setListCardUser] = useState([]);
  const [coupon, setcoupon] = useState(false);
  const [descuento, setdescuento] = useState(false);
  const [couponApply, setcouponApply] = useState(false);

  const uuidMemo = useMemo(() => uuidv4(), []);

  const personasReserva = (data) => {
    setPersonas(data);
  };

  const niñosReserva = (data) => {
    setNiños(data);
  };

  const metodoPagoReserva = ({ target: { value } }) => {
    let cardSelect = listCardUser.find((e) => e.id === value);
    if (cardSelect) {
      setMetodoPago(cardSelect);
    }
  };

  const saveTarget = () => {
    getCards();
  };

  const setCoupon = () => {
    let cupon = experiencia[0].price.coupon;
    if (!cupon || !cupon.discount || !cupon.code) {
      Alert.error("No hay cupon disponible en esta experiencia", 2000);
    } else {
      if (coupon != cupon.code) {
        Alert.error("Cupon invalido!", 2000);
      } else if (coupon == cupon.code) {
        setCupon(cupon.discount);
        setcouponApply(true);
        Alert.success("Se ha aplicado el cupon!", 2000);
      } else {
        Alert.error("Ha ocurrido un error", 2000);
      }
    }
  };

  const getCards = async () => {
    try {
      let respCard = await axios.get(`${BASE_PATH}/users/my-cards`, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });

      if (respCard.status === 201) {
        setListCardUser(respCard.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCards();

    if (experiencia[0]) {
      if (experiencia[0].price.discount) {
        setdescuento(experiencia[0].price.discount);
      }
    }
  }, []);

  useEffect(() => {
    setDeviceFingerprintID({ type: "deviceID", deviceFingerprintID: uuidMemo });
    const script = document.createElement("script");

    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${process.env.REACT_APP_VSNET_ORG_ID}&session_id=${process.env.REACT_APP_VSNET_MERCHANT_ID}${uuidMemo}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      setDeviceFingerprintID({
        type: "deviceID",
        deviceFingerprintID: "EXAMPLE_DEFAULT_VALUE",
      });
    };
  }, []);

  return (
    <div className="container-sm " style={{ paddingTop: "5rem" }}>
      <nav className="navbar-titles navbar-light fixed-top d-flex align-items-center"></nav>
      <text className="heavy text-titulos-reserva orange__gradient">Revisa y Paga</text>
      <Row>
      <Col lg={5} xs={12}>
        

        <h5 className="bold" style={{ marginTop: "1rem" }}>
          {dateTime}
        </h5>
        <h5 className="heavy orange" style={{ marginTop: "1rem" }}>
          ¿Cuántos vienen?
        </h5>
        <div className="cards exp-pay-card">
          <Row>
            <Col
              className="d-flex align-items-center 
                    bold"
              lg={2}
              xs={4}
            >
              Adultos
            </Col>
            <Col>
              <CounterInput
                
                min={0}
                max={100}
                onCountChange={(count) => personasReserva(count)}
              />
            </Col>
          </Row>
          <Row className="">
            <Col
              className="d-flex align-items-center 
                    bold"
              lg={2}
              xs={4}
            >
              Niños
            </Col>
            <Col>
              <CounterInput
                min={0}
                max={100}
                onCountChange={(count) => niñosReserva(count)}
              />
            </Col>
          </Row>
        </div>
        {coupon && couponApply && (
          <text style={{ fontSize: 16, color: "grey" }}>
            <text className="bold orange">Has aplicado el cupón:</text> {coupon}
          </text>
        )}
        {descuento && descuento.people && descuento.price && (
          <text style={{ fontSize: 16, color: "grey" }}>
            <text className="bold">PROMO:</text> Por la compra de{" "}
            {descuento.people} cupos, te llevas ${descuento.price} de descuento
          </text>
        )}
      </Col>
      <Col>
        <div style={{paddingTop:"0.7rem"}}></div>
        
        <Row>

          <Col lg={12} xs={12}>
            <Form>
              <Form.Group controlId="inlineFormCustomSelect">
                <Form.Control
                  as="select"
                  className="form-control-selector"
                  custom
                  name="Card"
                  onChange={metodoPagoReserva}
                >
                  <option>Seleccionar método de pago</option>

                  {listCardUser.length > 0 &&
                    listCardUser.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          **** **** **** {item.card}
                        </option>
                      );
                    })}
                  <option>Crypto (Próximamente)</option>
                </Form.Control>
              </Form.Group>
            </Form>
            <Col>
            <AgregarTarjeta
              onSave={saveTarget}
              stylesclass="btn__add text-right bold"
            />
          </Col>
          </Col>
        </Row>
        <Row>
          <Col lg={8} xs={7}>
            <Form>
              <FormControl
                type="text"
                placeholder="PROMO"
                onChange={(e) => setcoupon(e.target.value)}
                className="form-control"
                disabled={couponApply}
              />
            </Form>
          </Col>
          <Col lg={4} xs={5}>
            <button
              onClick={setCoupon}
              disabled={couponApply}
              className="btn btn__code bold"
            >
              Aplicar
            </button>
          </Col>
        </Row>
      </Col>
      </Row>
      <br />
    </div>
  );
};

export default ExperienciaPagar;
