import React, { useEffect, useState } from 'react'
import { Tarjeta } from './Tarjeta'

const Index = ({categorys, onSelected, selectes }) => {

    const [state, setstate] = useState(categorys)
    const [ids, setIds] = useState([])

    const onPick=(e)=>{
        let  newArr = [...state]
        newArr[e.index] = {... newArr[e.index], isSelected: e.isSelected}

        setstate([...newArr])

        if(e.isSelected){
            setIds([...ids, e.id])
            onSelected([...ids, e.id])

        }else{
          const id = ids.filter((b) => b !== e.id) 
          setIds(id)
          onSelected(id)
        }
    }

    useEffect(() => {
    let selectesIds = []
       const newArr = state.map((cat) =>{
            if(selectes.includes(cat.id)){
                selectesIds.push(cat.id)
                return {...cat, isSelected : true}
            }

            return cat
        })

        setIds(selectesIds)
        setstate(newArr)
    }, [selectes])
    return (
        <div className="image_picker">
            {state.map((category, i) =>(
                <Tarjeta key={category.name} category={category} index={i} onPick={onPick}></Tarjeta>
            ))}
        </div>
    )
}

export default Index
