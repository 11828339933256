import React, { useEffect, useState, useContext } from "react";
import "antd/dist/antd.css";
import { Media, Row, Col } from "react-bootstrap";
import { TabLink, TabContent, Tabs } from "react-tabs-redux";
import { Drawer } from "rsuite";
import Chat from "./chat/Chat";
import { Login } from "./Login";
import "./style.css";

//Importar Recursos
import Background from "../../assets/images/cards/background_default.jpg";
import ExperienciaGaleria from "./ExperienciaGaleria";
import ExperienciaDetails from "./ExperienciaDetails";
import ExperienciaReview from "./ExperienciaReview";
import axios from "axios";
import { BASE_PATH } from "../constants";
import { AuthContext } from "../../auth/AuthContext";
const defaultPhoto =
  "https://tugofiles.fra1.digitaloceanspaces.com/1630952868200-Icono_512x512.png";

const ExperienciaHeader = (props) => {
  const [reviews, setReviews] = useState([]);
  const [usern, setusern] = useState([]);
  const { user } = useContext(AuthContext);
  const [photos, setphotos] = useState([])
  const [experiencia, setExperiencia] = useState([]);
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    if (experiencia.length > 0) {
      setVisible(true);
    }
  };
  const onClose = () => {
    setVisible(false);
  };
  const getExperiencia = async () => {
    try {
      let res = await axios.get(`${BASE_PATH}/experiences/${props.data}`);
      if (res.status === 200) {
        let exp = [res.data];
        setExperiencia(exp);

        const pics = res.data.pictures.map((pic) => {
          return {
            url: pic.path,
            id: pic.image_id,
          };
        });

        setphotos(pics)

        if (exp[0].user.infoExtra) {
          setusern({ ...exp[0].user?.infoExtra, _id: exp[0].user?._id });
        } else {
          setusern(exp[0].user);
        }
        onReviews();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onReviews = async () => {
    try {
      let reviewss = await axios.get(
        `${BASE_PATH}/reviews?experience=${props.data}`
      );
      if (reviewss.status == 200) {
        //console.log('reviews >= ', reviewss.data.rows)
        setReviews(reviewss.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExperiencia();
  }, []);

  return (
    <div>
      <section className="exp-header fixed-top d-none d-md-none d-lg-block">
        <Row className="">
          <Col>
            <div
              className="d-flex align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="bold exp-titulo exp-container">
                <Media className="mb-1">
                  <Media.Body>
                    <h5 className="text-white">
                      {experiencia.length > 0 ? (
                        experiencia[0].direction
                      ) : (
                        <></>
                      )}
                    </h5>
                  </Media.Body>
                </Media>
                <p style={{ fontSize: "70px" }}>
                  {experiencia.length > 0 ? experiencia[0].name : <></>}
                </p>
                <div className="review">
                  <i
                    className="icon-star"
                    style={{ color: "#fe5000", fontSize: "30px" }}
                  ></i>
                  <text className="bold" style={{ fontSize: "30px" }}>
                    {experiencia.length > 0 ? experiencia[0].rating : <></>}
                  </text>
                </div>
                <div>
                  <Row className="exp-details-about" style={{ width: "600px" }}>
                    <Col className="text-center">
                      <i className="icon-face" style={{ fontSize: "45px" }}></i>
                      <p style={{ fontSize: "16px", color: "grey" }}>Cupo</p>
                      <p className="bold">
                        Hasta {experiencia.length > 0 ? experiencia[0].quotas.max : <></>}{" "}
                        personas
                      </p>
                    </Col>
                    <Col className="text-center">
                      <i className="icon-time" style={{ fontSize: "45px" }}></i>
                      <p style={{ fontSize: "16px", color: "grey" }}>
                        Duración
                      </p>
                      <p className="bold">
                        {experiencia.length > 0 ? (
                          experiencia[0].duration
                        ) : (
                          <></>
                        )}
                      </p>
                    </Col>
                    <Col className="text-center">
                      <i
                        className="icon-money"
                        style={{ fontSize: "45px" }}
                      ></i>
                      <p style={{ fontSize: "16px", color: "grey" }}>Precio</p>
                      <p className="bold">
                        Adultos $
                        {experiencia.length > 0 ? (
                          experiencia[0].price.adultPriceWithCommissions || experiencia[0].price.adultPrice
                        ) : (
                          <></>
                        )}
                        <br />
                        Niños $
                        {experiencia.length > 0 ? (
                          experiencia[0].price.childrenPriceWithCommissions || experiencia[0].price.childrenPrice
                        ) : (
                          <></>
                        )}{" "}
                        <></>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col
            className="exp-header-pic"
            lg={4}
            style={{
              backgroundImage: `url(${experiencia.length > 0
                ? experiencia[0].pictures[0]
                  ? experiencia[0].pictures[0].path
                  : Background
                : Background
                })`,
            }}
          ></Col>
        </Row>
        <i className="fi fi-br-angle-up orange icon-font-exp text-center"></i>
      </section>

      <section
        className="exp-header fixed-top d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none"
        style={{
          height: "100vh",
          width: "100%",
          backgroundImage: `url(${experiencia.length > 0
            ? experiencia[0].pictures[0]
              ? experiencia[0].pictures[0].path
              : Background
            : Background
            })`,
        }}
      >
        <div className="bold exp-titulo container-sm">
          <p className="text-left">
            {experiencia.length > 0 ? experiencia[0].name : <></>}
          </p>
        </div>
        <Media
          className="container"
          style={{ marginTop: "-2rem", position: "absolute", bottom: "2rem" }}
        >
          <Media.Body>
            <h5 className="text-white text-left mt-1">
              {experiencia.length > 0 ? experiencia[0].direction : <></>}
            </h5>
          </Media.Body>
        </Media>
      </section>
      <section className="exp-contenido">

        <Tabs className="container-sm">

          <div
            className="
                    sticky-top exp-tabs
                    {colorChange ? 'navbar colorChange' : 'navbar'}"
          >
            <TabLink
              key="tablink1_key"
              to="tab1"
              className="exp-contenido-btn exp-active"
              activeClassName="orange "
            >
              <i className="  icon-details"></i> Info
            </TabLink>
            <TabLink
              key="tablink2_key"
              to="tab2"
              className="exp-contenido-btn exp-active "
              activeClassName="orange"
            >
              <i className=" icon-pics"></i>Pics
            </TabLink>
            <TabLink
              key="tablink13key"
              to="tab3"
              className="exp-contenido-btn exp-active "
              activeClassName="orange"
            >
              <i className=" icon-star "></i>
            </TabLink>
          </div>
          <TabContent key="tab1_key" for="tab1">
            <ExperienciaDetails key="_kettab1" data={props.data} />
          </TabContent>
          <TabContent key="tab2_key" for="tab2" className="exp-galeria">
            <ExperienciaGaleria key="_kettab2" photos={photos} />
          </TabContent>
          <TabContent key="tab3_key" for="tab3">
            <ExperienciaReview key="_kettab3" data={props.data} />
          </TabContent>
        </Tabs>
        <>
          <button
            type="button"
            className="exp-chat exp-fixed-bottom btn-primary"
            onClick={showDrawer}
          >
            <i className=" icon-chat" style={{ fontSize: 26, marginTop: '0.4rem' }}></i>
          </button>
          <Drawer size="xs" onHide={onClose} show={visible}>
            <Drawer.Body className="exp-chat-open">

              {user.logged === true &&
                visible === true &&
                usern?._id != user.data.user.id && (
                  <Chat dataUser={usern} closeChat={onClose}></Chat>
                )}
             {user.logged === false && <Login></Login>}
              {user.logged === true &&
                visible === true &&
                usern?._id === user.data.user.id && (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      flexDirection: "column",
                      marginTop: "100px",
                    }}
                  >
                    <button
                      type="button"
                      className="back-window-navbar"
                      style={{ position: "absolute", margin: "20px", top: "0" }}
                      onClick={onClose}
                    >
                      <i className="icon-back icon-left-arrow"></i>
                    </button>
                    <img
                      src={defaultPhoto}
                      alt="tugo image"
                      style={{
                        maxWidth: "250px",
                        width: "100%",
                        margin: "auto",
                      }}
                    />
                    <h2 style={{ fontSize: "18px", textAlign: "center" }}>
                      Chat no disponible en esta experiencia
                    </h2>
                  </div>
                )}
            </Drawer.Body>
          </Drawer>
        </>
      </section>
    </div>
  );
};

export default ExperienciaHeader;