

import React, { Component, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Icon } from 'rsuite';

const AnyReactComponent = ({ text }) => 
  <div >
      <Icon style={{ color: '#fe5000' }} icon='dot-circle-o' size="3x" />
  </div>;
  
const SimpleMapA = ({ isCenter }) => {

  const [coordenates, setCoordenates] = useState({
    lat: 14.6349149,
    lng: -90.5068824
    })
  

  useEffect(()=>{
    //console.log('maapa =>' , center)
    if(isCenter){

      setCoordenates(isCenter)
    }
  }, [isCenter])

  return (
    <div className="map_full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBfQuTQ_6HNpzWYc0nDeIpitHEDK9SznI8" }}
        center={coordenates}
        defaultZoom={11}
      >
        <AnyReactComponent
          lat={coordenates.lat}
          lng={coordenates.lng}
          text="Mi ubicación"
        />
      </GoogleMapReact>
    </div>
  )
}

export default SimpleMapA;