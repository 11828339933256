import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { BASE_PATH } from "../constants";
import { Avatar } from "antd";
import logo from "../../assets/images/logo.svg";
import { Alert } from "rsuite";

const Verify = (props) => {
  const history = useHistory();
  const refInterval = useRef();
  const [user, setuser] = useState(false);
  const [bntLocked, setbntLocked] = useState(true);
  const [seconds, setSeconds] = useState(90);
  const getUser = async () => {
    try {
      const request = await axios.get(
        `${BASE_PATH}/users/${props?.match?.params?.user}`
      );

      //console.log(request);
      if (request.status === 200) {
        setuser(request.data);
        setbntLocked(false);
      }
    } catch (error) {
      Alert.error("Ha ocurrido un error en el servidor");
      console.log(error);
    }
  };

  const handleSendEmail = async () => {
    setbntLocked(true);
    try {
      const request = await axios.post(`${BASE_PATH}/users/send-verify-email`, {
        email: user?.email,
        originUrl: window.location.origin,
      });
      //console.log(request)
      if (request.status === 200 && request.data.emailSended) {
        Alert.success("Correo reenviado exitosamente!");
        setbntLocked(false);
        setSeconds(90)
        refInterval.current = setInterval(countdown, 1000);
      }
    } catch (error) {
      Alert.error("Ha ocurrido un error en el servidor");
      console.log(error);
      setbntLocked(false);
    }
  };

  useEffect(() => {
    if (!props?.match?.params?.user) {
      history.replace({ pathname: "/" });
    } else {
      getUser();
    }
  }, []);

  const countdown = () => setSeconds((seconds) => seconds - 1);

  useEffect(() => {
    refInterval.current = setInterval(countdown, 1000);

    return () => {
      clearInterval(refInterval.current);
    };
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      clearInterval(refInterval.current);
    }
  }, [seconds]);

  return (
    <>
      <div
        style={{
          marginTop: "150px",
          width: "100%",
          textAlign: "center",
          padding: "50px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="verify-email-container">
        <img src={logo} className="logo" />
        <h2> Hola {user?.name}!</h2>
        <h4>Te enviamos un correo de verificación a</h4>
        <b>{user?.email}</b>

        <button
          style={{ display: "block", maxWidth: "200px", margin: "20px" }}
          type="button"
          className="btn btn-primary btn-auth "
          onClick={handleSendEmail}
          disabled={bntLocked || seconds > 0}
        >
          Reenviar {seconds > 0 ? `(${seconds}s)` : ''}
        </button>
      </div>
    </>
  );
};

export default Verify;
