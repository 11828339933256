import React, { useContext, useEffect, useState } from "react";
import {  useHistory } from "react-router-dom";
import { Form, FormControl, Row, Col } from "react-bootstrap";
import { Alert, Input, DatePicker } from "rsuite";
import "../../assets/css/App.css";
import "antd/dist/antd.css";
import axios from "axios";
import { types } from "../../types/types";
import { BASE_PATH, API_COUNTRIES } from "../constants";

//Importar Recursos
import { AuthContext } from "../../auth/AuthContext";

const InfoViajero = () => {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [countries, setcountries] = useState([]);
  const [umovil, setumovil] = useState(false);
  const [uemail, setuemail] = useState(false);
  let initForm = {
    name: "",
    alias: "",
    email: "",
    phone: "",
    gender: "",
    description: "",
    birthday: new Date(),
    direction: "",
    picture: "",
  };
  const [form, setForm] = useState(initForm);

  const Back = () => {
    if (!user.data.user.state || user.data.user.state === 0) {
      history.replace({ pathname: "/configuraciones" });
    } else {
      history.replace({ pathname: "/host/configuraciones" });
    }
  };

  const getCountries = async () => {
    let response = await axios.get(`${API_COUNTRIES}`);
    let allcountries = response.data.reduce((a, b) => a.concat(b), []);
    let allNames = allcountries.map((el) => {
      return el.name.common;
    });
    // console.log(allNames.sort());
    setcountries(allNames.sort());
  };

  const updateOwnerInfo = async () => {
    //console.log(user);

    try {
      let updateUser = await axios.put(
        `${BASE_PATH}/users/${user.data.user.id}`,
        {
          access_token: user.data.token,
          name: user.data.user.name ? user.data.user.name : form.name + "_",
          phone: user.data.user.phone ? user.data.user.phone : form.phone,
          description: user.data.user.description
            ? user.data.user.description
            : form.description + "_",
          birthday: user.data.user.birthday,
          gender: user.data.user.gender,
          direction: user.data.user.direction,
          alias: user.data.user.alias ? user.data.user.alias : form.alias + "_",
          picture: user.data.user.picture,
          email: user.data.user.email ? user.data.user.email : form.email,
          state: user.data.user.state,
          infoExtra: {
            name: form.name,
            phone: form.phone,
            description: form.description,
            birthday: form.birthday,
            gender: form.gender,
            direction: form.direction,
            alias: form.alias,
            picture: form.picture,
            email: form.email,
          },
        }
      );

      if (updateUser.status === 200) {
        // console.log(updateUser);
        dispatch({
          type: types.login,
          payload: {
            data: {
              token: user.data.token,
              user: updateUser.data,
            },
          },
        });
        Alert.success("Datos actualizados con exito", 5000);
      } else {
        //console.log("No se completo");
        Alert.error("No se pudieron actualizar los datos, intente de nuevo");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateInfoUser = async () => {
    try {
      let updateUser = await axios.put(`${BASE_PATH}/users/${form.id}`, {
        access_token: user.data.token,
        name: form.name,
        phone: form.phone,
        description: form.description,
        birthday: form.birthday,
        gender: form.gender,
        direction: form.direction,
        alias: form.alias,
        picture: form.picture,
        email: form.email,
        state: form.state,
        infoExtra: user.data.user.infoExtra,
      });

      if (updateUser.status === 200) {
        dispatch({
          type: types.login,
          payload: {
            data: {
              token: user.data.token,
              user: updateUser.data,
            },
          },
        });
        Alert.success("Datos actualizados con exito", 5000);
      } else {
        //console.log("No se completo");
        Alert.error("No se pudieron actualizar los datos, intente de nuevo");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const actionUpdate = () => {
    if (
      validateData("name") &&
      validateData("phone") &&
      validateData("email") &&
      validateData("alias")
    ) {
      if (!user.data.user.state || user.data.user.state === 0) {
        updateInfoUser();
      } else if (user.data.user.state === 1) {
        updateOwnerInfo();
      }
    } else {
      Alert.error("Faltan datos por llenar");
    }
  };

  const change = ({ target: { value, name } }) =>
    setForm({ ...form, [name]: value });

  const validateData = (name) => {
    let data = form[name];
    if (data.trim() === "") {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    //console.log(user);
    getCountries();
    if (user) {
      if (user.data.user.state === 0 || !user.data.user.state) {
        setForm({ ...initForm, ...user.data.user });
        //console.log("user");
        user.data.user.phone ? setumovil(true) : setumovil(false);
        user.data.user.email ? setuemail(true) : setuemail(false);
      } else if (user.data.user.state === 1) {
        setForm({ ...initForm, ...user.data.user.infoExtra });
        //console.log("owner");
      }
    } else {
      history.replace("/");
    }
  }, []);

  return (
    <div className="container-sm">
      <nav className="navbar-titles navbar-light d-flex align-items-center">
        <button type="button" className="back-window-navbar" onClick={Back}>
          <i className="  icon-left-arrow"></i>
        </button>
        <h5 className="bold">Información Básica</h5>
      </nav>
      <Form>
        <Row style={{ paddingTop: "6rem", paddingBottom: "5rem" }}>
          <Col xs={12} lg={6}>
            <Form.Group>
              <FormControl
                type="text"
                placeholder="Nombre y Apellido"
                name={"name"}
                onChange={change}
                className="form-control"
                value={form.name}
              />
            </Form.Group>
            <Form.Group>
              <FormControl
                type="text"
                placeholder="@alias"
                name={"alias"}
                className="form-control"
                value={form.alias}
                onChange={change}
              />
            </Form.Group>

            <Form.Group>
              <Form.Group>
                <FormControl
                  type="email"
                  placeholder="Email"
                  name={"email"}
                  className="form-control"
                  value={form.email}
                  disabled={uemail}
                  onChange={change}
                />
              </Form.Group>
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="select"
                className="form-control-selector form-control"
                name={"direction"}
                value={form.direction}
                onChange={change}
              >
                <option>País donde vives</option>
                {countries.map((el, i) => {
                  return <option key={`${el}_${i}`}>{el}</option>;
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="select"
                name={"gender"}
                className="form-control-selector form-control"
                value={form.gender}
                onChange={change}
              >
                <option>Género</option>
                <option>Hombre</option>
                <option>Mujer</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group>
              <FormControl
                type="number"
                placeholder="503 0000 0000"
                name={"phone"}
                className="form-control"
                disabled={umovil}
                value={form.phone}
                onChange={change}
              />
              </Form.Group>
            <Form.Group>
              <DatePicker
                placeholder="Fecha de nacimiento"
                className="form-control-selector form-control"
                oneTap
                value={new Date(form.birthday)}
                onChange={(val) => {
                  setForm({ ...form, birthday: val });
                }}
              />
            </Form.Group>
            <Form.Group>
              <FormControl
                type="text"
                maxLength={75}
                placeholder="¿Cómo te describes?"
                name={"description"}
                className="form-control-textarea"
                as="textarea"
                value={form.description}
                onChange={change}
              />
            </Form.Group>
          </Col>
          <button
            type="button"
            onClick={actionUpdate}
            className="btn-primary
                        bold btn-login"
          >
            Guardar
          </button>
        </Row>
      </Form>
    </div>
  );
};

export default InfoViajero;
