import React from "react";
import {
  Avatar,
  Conversation,
  ConversationList,
} from "@chatscope/chat-ui-kit-react";

import MessageAlert from "./MessageAlert";

const defaultProfile =
  "https://tugofiles.fra1.digitaloceanspaces.com/1630952868200-Icono_512x512.png";
const ChatList = ({ user, data, status, select }) => {
  return (
    <ConversationList >

    <div className="conversation_empty"></div>

      {status === "success" &&
        data.docs.map((item) => {
          const { image, name, msg } = item.data();

          return (
            <Conversation
              key={item.id}
              name={name}
              info={msg}
              onClick={() => {
                select(item);
              }}
            >
              <Avatar
                src={image ? image : defaultProfile}

                //name={name }
                //status="available"
              />
            </Conversation>
          );
        })}
      {user?.logged === true && data?.docs?.length === 0 && (
        <MessageAlert
          type="info"
          title="Informacion"
          description="No hay conversaciones para mostrar"
        />
      )}
      {user?.logged === false && (
        <MessageAlert
          type="info"
          title="Informacion"
          description="Inicia sesion con tu cuenta"
        />
      )}{" "}
    </ConversationList>
  );
};
ChatList.defaultProps = {
  user: {},
  data: {},
  status: "",
  select: () => {},
};

export default ChatList;
