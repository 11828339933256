import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { } from "react-bootstrap";
import "../../assets/css/App.css";
import "antd/dist/antd.css";

//Importar Recursos
import "../../assets/css/iconos/css/fontello.css";
import ExperienciaHeader from "../Experiencias/ExperienciaHeader";
import ExperienciaCalendar from "../Experiencias/ExperienciaCalendar";
import ExperienciaPagar from "../Experiencias/ExperienciaPagar";
import ExperienciaReservada from "./ExperienciaReservada";
import { Favoritos } from "./exp/Favoritos";
import axios from "axios";
import { AuthContext } from "../../auth/AuthContext";
import {
  Col,
  Row,
  Drawer,
  Grid,
  Container,
  Alert,
  Button,
  Modal,
  Panel,
  Loader,
  Notification,
  List,
} from "rsuite";
import { } from "antd";
import { Login } from "./Login";
import { BASE_PATH } from "../constants";
import logo from "../../assets/images/logoDark.svg";
import logoC from "../../assets/images/logo.svg";
import { collection, doc, setDoc } from "@firebase/firestore";
import { useFirestore } from "reactfire";
import { VsNetContext } from "../../contexts/VsNetContext";
import { dateOptions, setTimeInDate } from "../Anfitrion/Experiencias/schedules/utils";
import moment from "moment";

const vsNetErrorsMessages = {
  EXPIRED_CARD: "Tarjeta expirada",
  "paymentInformation.card.expirationYear": "Año de expiración",
  "orderInformation.billTo.administrativeArea": "Ciudad o Estado",
  "orderInformation.billTo.postalCode": "Código Postal",
  "paymentInformation.card.expirationMonth": "Mes de expiración",
  "orderInformation.billTo.country": "País",
  "orderInformation.billTo.email": "Email",
  "orderInformation.billTo.locality": "Ciudad o Estado",
  "paymentInformation.card.number": "Tarjeta Inválida"
};

const Experiencia = (props) => {
  const { user } = useContext(AuthContext);

  const { deviceFingerprintID } = useContext(VsNetContext);

  const history = useHistory();
  const [experiencia, setExperiencia] = useState([]);

  const [adultos, setAdultos] = useState(false);
  const [nenes, setNenes] = useState(0);
  const [loading, setloading] = useState(false);

  const [metodoPago, setMetodoPago] = useState(false);
  const [expReservada, setExpReservada] = useState(false);
  const [totalReserva, setTotalReserva] = useState(0);

  const [descAplicado, setdescAplicado] = useState(false);
  const [descxpersonas, setdescxpersonas] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openPaymentModal, setopenPaymentModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [dataPreview, setdataPreview] = useState({});
  const [paymentsData, setpaymentsData] = useState([]);
  const [disabledBtn, setdisabledBtn] = useState(false);
  const [schedule, setschedule] = useState({ id: null, date: null, time: null });
  const refOwnersDoc = collection(
    useFirestore(),
    `chat/chatList/${experiencia[0]?.user?._id}/owner/list`
  );

  const refOwnerCollection = collection(
    useFirestore(),
    `chat/messages/${experiencia[0]?.user?._id}/${user?.data?.user?.id}/messagesList`
  );

  const [shareDetails, setshareDetails] = useState({
    title: "",
    text: "",
    url: "",
  });

  const selectSchedule = (data) => {
    setschedule(data);
  };

  const setCupon = (data) => {
    setdescAplicado(data / 100);
  };

  const adultosReserva = (data) => {
    const { adultPriceWithCommissions, childrenPriceWithCommissions } =
      experiencia[0].price;
    const ammountWithCmissions =
      adultPriceWithCommissions * data + childrenPriceWithCommissions * nenes;
    setTotalReserva(ammountWithCmissions);
    setAdultos(data);
  };
  const niñosReserva = (data) => {
    const { adultPriceWithCommissions, childrenPriceWithCommissions } =
      experiencia[0].price;

    const ammountWithCmissions =
      childrenPriceWithCommissions * data + adultPriceWithCommissions * adultos;

    setTotalReserva(ammountWithCmissions);

    setNenes(data);
  };
  const metodoPagoReserva = (data) => {
    setMetodoPago(data);
  };
  const steps = [
    {
      title: "",
      content: <ExperienciaHeader data={props.match.params.experiencia} />,
    },
    {
      title: "",
      content: (
        <ExperienciaCalendar
          setSchedule={selectSchedule}
          experienceId={props.match.params.experiencia}
        />
      ),
    },
    {
      title: "",
      content: (
        <ExperienciaPagar
          experiencia={experiencia}
          setPersonas={adultosReserva}
          setMetodoPago={metodoPagoReserva}
          setNiños={niñosReserva}
          setCupon={setCupon}
          dateTime={`${new Date(schedule.date).toLocaleDateString(
            "es-ES",
            dateOptions
          )}, ${schedule.time}`}
        />
      ),
    },
  ];
  const loginExp = () => {
    history.replace({ pathname: "/login" });
  };

  const HomeB = () => {
    history.replace({ pathname: "/" });
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onSuccess = () => {
    getExperiencia();
    setVisible(false);
  };
  const getExperiencia = async () => {
    try {
      let res = await axios.get(
        `${BASE_PATH}/experiences/${props.match.params.experiencia}`
      );
      if (res.status === 200) {
        const exp = [res.data];
        console.log(exp);
        setExperiencia(exp);
        setTotalReserva(
          exp[0].price.adultPriceWithCommissions || exp[0].price.adultPrice
        );
        if (exp[0].price.discount) {
          //console.log(exp[0].price.discount)
          if (exp[0].price.discount.price && exp[0].price.discount.people) {
            setdescxpersonas(exp[0].price.discount);
          }
        }

        setshareDetails({
          title: exp[0].name,
          text: "¡Échale un vitazo a esta gran experiencia! : " + exp[0].name,
          url: `${window.location.origin}/experiencia/${exp[0].id}`,
        });
      } else {
        HomeB();
      }
    } catch (e) {
      console.log(e);
      HomeB();
    }
  };

  const next = () => {
    if (current === 0) {
      setCurrent(current + 1);
    } else if (current === 1) {
      if (schedule.id) {
        setCurrent(current + 1);
      } else {
        Alert.warning("Selecciona un horario", 3000);
      }
    } else if (current === 2) {
      if (adultos != false && metodoPago != false) {

        setConfirmation();

      } else {
        Alert.warning("Campos requeridos vacios", 2000);
      }
    }
  };
  const prev = () => {
    setCurrent(current - 1);
    if (experiencia[0]?.price.adultPriceWithCommissions !== totalReserva) {
      setTotalReserva(
        experiencia[0]?.price.adultPriceWithCommissions ||
        experiencia[0]?.price.adultPrice
      );
    }
  };

  const getCodeForTransaction = async () => {
    const respCode = await axios.get(`${BASE_PATH}/income/getCode`, {
      headers: {
        Authorization: `Bearer ${user?.data?.token}`,
      },
    });
    if (respCode.status === 200) {
      return respCode?.data.code;
    } else {
      return null;
    }
  };

  const assambleObjectToPay = async () => {
    const newTransactionCode = await getCodeForTransaction();

    if (!!newTransactionCode) {
      const {
        adultPrice,
        childrenPrice = 0,
        adultPriceWithCommissions,
        childrenPriceWithCommissions,
      } = experiencia[0].price;

      let montoAdultos = adultPrice * adultos;
      let MontoAdultosWithCommisions = adultPriceWithCommissions * adultos;

      let montoNinos =
        childrenPrice == 0 ? adultPrice * nenes : childrenPrice * nenes;
      let montoNinosWithComisions =
        childrenPriceWithCommissions === 0
          ? adultPrice * nenes
          : childrenPriceWithCommissions * nenes;

      let totalWithComissions =
        MontoAdultosWithCommisions + montoNinosWithComisions;
      let total = montoAdultos + montoNinos,
        descuento = 0,
        descuentoWithComisions = 0;
      if (descxpersonas) {
        if (adultos + nenes >= descxpersonas.people) {
          total = total - parseFloat(descxpersonas.price);
          totalWithComissions =
            totalWithComissions - parseFloat(descxpersonas.price);
        }
      }
      if (descAplicado) {
        descuento = (montoAdultos + montoNinos) * descAplicado;
        descuentoWithComisions =
          (MontoAdultosWithCommisions + montoNinosWithComisions) * descAplicado;
      }

      setpaymentsData({
        // code: newTransactionCode,
        totalAmount: (total - descuento).toFixed(2),
        // currency: process.env.REACT_APP_PAYMENTS_CURRENCY,
        card: metodoPago.card,
        cardId: metodoPago.id,
      });
      setdataPreview({
        name: experiencia[0]?.name,
        date: `${new Date(schedule.date).toLocaleDateString(
          "es-ES",
          dateOptions
        )}, ${schedule.time}`,
        coupos: adultos + nenes,
        coupon: experiencia[0]?.price.coupon.code,
        discount: experiencia[0]?.price.coupon.discount,
        amount: (totalWithComissions - descuentoWithComisions).toFixed(2),
        card: {
          number: "**** **** **** " + metodoPago.card,
          type: metodoPago.type,
          person: metodoPago.name,
        },
      });
    } else {
      setopenPaymentModal(false);
      Alert.warning("Error en hacer la reserva, vuelva a intentar", 2000);
    }
  };

  const setConfirmation = async () => {
    assambleObjectToPay();
    setopenPaymentModal(true);
  };

  const validateAvailableQuotes = async () => {

    const quotas = adultos + nenes
    try {
      const bookedDateTime = new Date(setTimeInDate(schedule.time, schedule.date))
      const resp = await axios.get(`${BASE_PATH}/schedules/${experiencia[0].user._id}/${experiencia[0].id}/${schedule.id}/${bookedDateTime.getTime()}/${quotas}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.token}`,
          },
        }
      )
      if (resp.status === 200) {

        if (!resp.data.isValid) {
          setopenPaymentModal(false)
          Alert.warning(resp.data.message, 5000)
          return
        }

        createIncomePayment()

      }


    } catch (error) {
      console.log(error);
      Alert.error('Ha ocurrido un error en el servidor', 3000)

    }
  }

  const createIncomePayment = async () => {

    setdisabledBtn(true);
    setloading(true);

    if (paymentsData.totalAmount > 0) {
      axios
        .post(
          `${BASE_PATH}/income`,
          {
            ...paymentsData,
            fingerprintSessionId: deviceFingerprintID,
          },
          {
            headers: {
              Authorization: `Bearer ${user?.data?.token}`,
            },
          }
        )
        .then((incomePayment) => {
          if (
            incomePayment.status === 200 &&
            incomePayment.data &&
            incomePayment.data.status === "AUTHORIZED"
          ) {
            saveReservation(
              incomePayment.data.reconciliationId,
              incomePayment.data.totalAmount,
              incomePayment.data.commissions
            );
          }
          if (incomePayment.data.status !== "AUTHORIZED") {
            Alert.warning("Ocurrio un error al procesar la solitud", 2000);

            setdisabledBtn(false);
            setloading(false);
          }
        })
        .catch((err) => {
          const errorInfo = err.response.data.response
            ? JSON.parse(err.response.data.response.text)
            : err.response.data.errorInformation;

          let errorMessage = "";

          console.log(errorInfo);
          if (errorInfo.details) {
            const fields = errorInfo.details.map(
              (el) => vsNetErrorsMessages[el.field]
            );
            openNotification(fields);
            errorMessage = fields[0];
          } else {
            const field = vsNetErrorsMessages[errorInfo.reason];
            openNotification([field]);
            errorMessage = field;
          }

          if (!errorMessage) {
            Alert.error("Ocurrio un error al procesar la solitud", 2000);
            setdisabledBtn(false);
            setloading(false);

            return;
          }

          setdisabledBtn(false);
          setloading(false);
        });
    } else {
      const commissions = {
        tugo: 0,
        visaNet: 0,
      };
      saveReservation(deviceFingerprintID, 0, commissions);
    }
  };

  function openNotification(fields) {
    Notification["error"]({
      title: "Campos inválidos",
      description: (
        <List style={{ width: "min(100%, 300px)" }}>
          {fields.map((item, index) => (
            <List.Item
              style={{ fontSize: "12px !important", padding: "5px" }}
              key={index}
              index={index}
            >
              {item}
            </List.Item>
          ))}
        </List>
      ),
    });
  }

  const saveReservation = async (
    transactionId = "",
    totalAmount,
    commissions
  ) => {
    try {
      const bookedDateTime = new Date(setTimeInDate(schedule.time, schedule.date))
      const respNewPayment = await axios.post(`${BASE_PATH}/payments`, {
        access_token: user.data.token,
        experiences: experiencia[0].id,
        date: `${moment(bookedDateTime).format("YYYY-MM-DD")} ${schedule.time}`,
        adult: adultos,
        children: nenes,
        card: metodoPago.id,
        mount: totalAmount,
        code: paymentsData?.code,
        transactionId: transactionId,
        schedule: schedule.id,
        commissions,
        bookedDateTime: bookedDateTime.getTime()

      });
      //console.log(result);
      if (respNewPayment.status === 200) {
        // console.log(respNewPayment.data);
        sendMessage();
        setopenPaymentModal(false);
        setExpReservada(respNewPayment.data);
        setdisabledBtn(false);
        setloading(false);
      } else {
        setopenPaymentModal(false);
        Alert.warning("Error en hacer la reserva, vuelva a intentar", 2000);
        setdisabledBtn(false);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setopenPaymentModal(false);
      setdisabledBtn(false);
      setloading(false);
      Alert.error("Error en hacer la reserva, vuelva a intentar", 2000);
    }
  };

  const sendMessage = async () => {
    const dateMsg = new Date().toISOString();

    let msg = {
      active: true,
      image: "",
      text: `Ha reservado ${adultos} cupos para adultos y ${nenes} cupos para niños en tu experiencia ${experiencia[0].name
        }, para el ${new Date(schedule.date).toLocaleDateString(
          "es-ES",
          dateOptions
        )}, ${schedule.time} `,
      time: dateMsg,
      userby: user.data.user.id,
      type: "2",
      reservation: true,
    };
    await setDoc(doc(refOwnerCollection), msg).then(async () => {
      await setDoc(doc(refOwnersDoc, user?.data.user.id), {
        msg: `Ha reservado en: ${experiencia[0].name} `,
        time: dateMsg,
        name: user?.data.user.name,
        image: user?.data.user.picture,
      }).catch((err) => {
        console.log(err);
      });
    });
  };

  useEffect(() => {
    getExperiencia();
  }, []);

  return (
    <>
      {loading && (
        <div className="progress-loader">
          <div className="progress-loader-content">
            <Loader
              size="lg"
              inverse
              center
              className="progess-loader-text"
              content={<h4 style={{ color: "#fff" }}>Por favor espera...</h4>}
            />
          </div>
        </div>
      )}

      {current == 0 && (
        <div>
        <button
          style={{ zIndex: "9999" }}
          type="button"
          className="back-window fixed-top"
          onClick={HomeB}
        >
          <i className="icon-back icon-left-arrow"></i>
        </button>
        <img src={logoC} className="logo_in" alt="logo" />
        </div>
      )}
      <img src={logoC} className="logo_in3" alt="logo" />
      

      {expReservada == false && (
        <Grid fluid>
          {user.logged === true ? (
            <>
            
              <div className="fixed-top favorites">
                <Favoritos
                  idExp={props.match.params.experiencia}
                  shareDetails={shareDetails}
                ></Favoritos>
              </div>

              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action bold navbar-sm-more exp-fixed-bottom">
                <span className="orange" style={{ paddingLeft: "10px" }}>
                  ${""}
                </span>
                <span className="text-white">
                  {experiencia.length > 0 ? (
                    parseFloat(totalReserva).toFixed(2)
                  ) : (
                    <></>
                  )}
                  <span style={{ fontSize: 12 }}></span>
                </span>
                {current < steps.length - 1 &&
                  user?.data.user.id != experiencia[0]?.user?._id && (
                    <Button
                      className="bold exp-pasos btn-primary btn-reserva"
                      type="dark"
                      onClick={() => next()}
                    >
                      Reserva
                    </Button>
                  )}
                {current > 0 && (
                  <button className="back-window-book fixed-top">
                    <i onClick={() => prev()}
                      className="icon-back icon-left-arrow"></i>
                  </button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    className="bold exp-pasos btn-primary btn-reserva"
                    type="dark"
                    onClick={next}
                  >
                    Confirmar
                  </Button>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action bold navbar-sm-more exp-fixed-bottom">
                <span className="orange" style={{ paddingLeft: "10px" }}>
                  ${" "}
                </span>
                <span className="text-white">
                  {experiencia.length > 0 ? (
                    parseFloat(totalReserva).toFixed(2)
                  ) : (
                    <></>
                  )}
                  <span style={{ fontSize: 12 }}></span>
                </span>
                {current < steps.length - 1 && user?.logged && (
                  <Button
                    className="bold exp-pasos btn-primary btn-reserva"
                    type="dark"
                    onClick={() => next()}
                  >
                    Reserva
                  </Button>
                )}
                {current < steps.length - 1 && !user?.logged && (
                  <Button
                    className="bold exp-pasos btn-primary btn-reserva"
                    type="dark"
                    /*onClick={showDrawer}*/
                    onClick={loginExp}
                  >
                    Login
                  </Button>
                )}
                {current > 0 && (
                  <div className="exp-pasos-prev">
                    <i
                      style={{ margin: "-4px 0px 0 -10px" }}
                      onClick={() => prev()}
                      className="demo-icon icon-left-arrow"
                    ></i>
                  </div>
                )}
                {current === steps.length - 1 && (
                  <Button
                    className="bold exp-pasos btn-primary btn-reserva"
                    type="dark"
                    onClick={showDrawer}
                  >
                    Confirmar
                  </Button>
                )}
              </div>

              <Drawer
                size="xs"
                onHide={onClose}
                show={visible}
                style={{ background: "#171718" }}
              >
                <button
                  type="button"
                  className="back-window fixed-top"
                  onClick={onClose}
                >
                  <i className="icon-back icon-left-arrow"></i>
                </button>
                <Drawer.Body className="exp-chat-open">
                  <Container>
                    <Row>
                      <Col md={24} className="mt-4"></Col>
                    </Row>
                    <Login onSuccess={onSuccess} />
                  </Container>
                </Drawer.Body>
              </Drawer>
            </>
          )}
        </Grid>
      )}

      {expReservada != false && experiencia.length > 0 && (
        <ExperienciaReservada
          experiencia={experiencia}
          reserva={expReservada}
        ></ExperienciaReservada>
      )}

      <Modal
        show={openPaymentModal}
        onHide={() => {
          setopenPaymentModal(false);
        }}
        style={{ maxWidth: "98%" }}
        overflow={false}
      >
        <Modal.Header>
          <Modal.Title>Confirma los datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Panel /*header="Reserva"*/ bordered>
              <Row style={{ marginBottom: "5px", textAlign: "center" }}>
                <h5>{dataPreview?.name}</h5>
              </Row>
              <div>
                <Col md={10} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-face" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Cupo</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {dataPreview?.coupos} adquirido(s)
                  </p>
                </Col>
                <Col md={10} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-time" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Fecha</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {dataPreview?.date}
                  </p>
                </Col>
              </div>
            </Panel>

            <Panel style={{ marginTop: "5px" }} bordered>
              <div>
                <Col md={6} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-card" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>
                    {dataPreview?.card?.type}
                  </p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {dataPreview?.card?.number}
                  </p>
                </Col>
                <Col md={6} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-money" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Descuento</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {descAplicado
                      ? `${dataPreview?.coupon} aplicado con ${dataPreview?.discount} %`
                      : "No aplicado"}
                  </p>
                </Col>
                <Col md={6} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-money" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Total</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    ${parseFloat(dataPreview?.amount).toFixed(2)}
                    <></>
                  </p>
                </Col>
              </div>
            </Panel>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={validateAvailableQuotes}
            style={{ background: "#fe5000" }}
            appearance="primary"
            disabled={disabledBtn}
          >
            Pagar
          </Button>
          <Button
            onClick={() => {
              setopenPaymentModal(false);
            }}
            appearance="subtle"
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Experiencia;
