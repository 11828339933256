import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../assets/css/App.css";
import "antd/dist/antd.css";
import { BASE_PATH } from "../constants";
import Rive from "rive-react";

//Importar Recursos
import ImagenDone from "../../assets/images/done.riv";

const ExperienciaReservada = ({ experiencia, reserva }) => {
  const history = useHistory();
  //console.log(experiencia)

  const [tempo, settempo] = useState(5);
  //console.log(name)

  const Planes = () => {
    history.replace({ pathname: "/planes" });
  };
  const ExpReservada = () => {
    history.replace({
      pathname: `/exp/reservada/${experiencia[0].id}/${reserva.id}`,
    });
  };

  useEffect(() => {
    let count = 5;

    let interval = setInterval(() => {
      if (count === 0) {
        Planes();
      }
      if (count >= 0) {
        settempo(count);
        count--;
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="bg-orange vh-100 d-flex justify-content-center align-items-center">
      <Row className="container-sm d-flex justify-content-center align-items-center">
        <Col className="text-center">
          <Rive src={ImagenDone} />
          <h1 className="heavy" style={{ fontSize: "65px", marginTop: "1rem" }}>
            ¡Listo!
          </h1>
          <h2 className="bold">Tu experiencia ha sido reservada</h2>
          <div onClick={Planes} className="exp-reservada">
            <text>
              Redireccionando a <span className="bold">Planes</span> en {tempo}{" "}
              seg.
            </text>
          </div>
          <div className="divider-down"></div>
        </Col>
      </Row>
    </div>
  );
};

export default ExperienciaReservada;
