import React, { useContext, useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Avatar } from "antd";
import { types } from "../../types/types";
import { AuthContext } from "../../auth/AuthContext";
import { RiCamera2Fill } from "react-icons/ri";
import { Upload } from "antd";
import axios from "axios";
import { Alert, Loader } from "rsuite";
import ImgCrop from "antd-img-crop";
//Importar Recursos
import Viajero from "../Navbars/Viajero";
import Anfitrion from "../Navbars/Anfitrión";

import { BASE_PATH } from "../constants";

const Configuraciones = () => {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [anfitrion, setAnfitrion] = useState(false);
  const [alias, setAlias] = useState("Sin Alias");
  const [description, setDescription] = useState("Sin descripcion");
  const [picture, setPicture] = useState("");
  const [subiendo, setSubiendo] = useState(false);

  const [userInfo, setuserInfo] = useState({
    alias: "Sin Alias",
    description: "no hay descripcion",
    picture: null,
  });
  //console.log(user);

  const Info = () => {
    history.replace({ pathname: "/configuraciones/perfil" });
  };
  const Planes = () => {
    history.replace({ pathname: "/planes" });
  };
  const MetodoPago = () => {
    history.replace({ pathname: "/configuraciones/metodopago" });
  };
  const TusGustos = () => {
    history.replace({ pathname: "/configuraciones/gustos" });
  };
  const changeUser = async () => {
    let switchh = 0;
    if (window.location.pathname == "/configuraciones") {
      switchh = 1;
    }
    let headers = {
      Authorization: `Bearer ${user?.data?.token}`,
      "user-switch": switchh,
    };
    let change = await axios.post(
      `${BASE_PATH}/users/switchUser/`,
      {},
      {
        headers: headers,
      }
    );
    //console.log(change);
    if (change.status == 200) {
      dispatch({
        type: types.login,
        payload: change,
      });

      if (change.data.user.state == 1) {
        history.replace({ pathname: `/host/_/${user?.data?.user?.id}` });
      } else if (change.data.user.state == 0) {
        history.replace({ pathname: "/" });
      }
    }
  };
  const Calendario = () => {
    history.replace({ pathname: "/host/calendario" });
  };

  const cerrar = () => {
    history.replace("/");
    dispatch({
      type: types.logout,
    });
  };

  const newPhoto = ({ fileList: newFileList }) => {
    //console.log(newFileList[0].originFileObj);
    uploadPicture(newFileList[0].originFileObj);
    //enviar()
  };

  const uploadPicture = async (file) => {
    setSubiendo(true);
    //console.log("change photo");
    try {
      var fdata = new FormData();
      fdata.append("picture", file);
      //console.log(fdata);
      let setPhoto = await axios.post(`${BASE_PATH}/users/picture`, fdata, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });
      //console.log(setPhoto);
      if (setPhoto.status == 200) {
        setPicture(setPhoto.data.picture_path);
        let updateUser = await axios.put(
          `${BASE_PATH}/users/${user?.data?.user?.id}`,
          {
            access_token: user?.data?.token,
            name: user?.data?.user?.name,
            phone: user?.data?.user?.phone,
            description: user.data.user.description,
            birthday: user.data.user.birthday,
            gender: user.data.user.gender,
            direction: user.data.user.direction,
            alias: user.data.user.alias,
            picture: !user.data.user.state
              ? setPhoto.data.picture_path
              : user.data.user.state == 0
                ? setPhoto.data.picture_path
                : user.data.user.picture,
            email: user.data.user.email,
            state: user.data.user.state,
            infoExtra: {
              name: user.data.user.infoExtra.name,
              phone: user.data.user.infoExtra.phone,
              description: user.data.user.infoExtra.description,
              birthday: user.data.user.infoExtra.birthday,
              gender: user.data.user.infoExtra.gender,
              direction: user.data.user.infoExtra.direction,
              alias: user.data.user.infoExtra.alias,
              picture:
                user.data.user.state == 1
                  ? setPhoto.data.picture_path
                  : user.data.user.picture,
              email: user.data.user.email,
            },
          }
        );

        // console.log(updateUser);

        if (updateUser.status === 200) {
          dispatch({
            type: types.login,
            payload: {
              data: {
                token: user.data.token,
                user: updateUser.data,
              },
            },
          });

          Alert.success("Datos actualizados con exito", 5000);
          setSubiendo(false);
        } else {
          Alert.error("Ha ocurrido un error.", 5000);
        }
      }
      //console.log(setPhoto.data.picture_path);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (user) {
      if (user?.data) {
        if (user?.data?.user?.state === 0 || !user?.data?.user?.state) {
          setuserInfo({ ...userInfo, ...user?.data?.user });
        } else if (
          user?.data?.user?.state === 1 ||
          user?.data?.user?.role === "owner"
        ) {
          setAnfitrion(true);
          setuserInfo({ ...userInfo, ...user?.data?.user?.infoExtra });
        }
      } else {
        history.replace("/");
      }
    } else {
      history.replace("/");
    }
  }, [user]);

  return (
    <div className="container-sm d-flex align-items-center">
      {anfitrion ? <Anfitrion /> : <Viajero />}

      {user && (
        <Row  className="config">
          <Col  className="config-perfil">
            <div className="text-center">
              <Avatar size={170} src={userInfo.picture} />
              <ImgCrop rotate>
                <Upload
                  className="update-usr-picture"
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={newPhoto}
                >
                  <button disabled={subiendo} className="photo-edit">
                    {!subiendo ? <RiCamera2Fill /> : <Loader backdrop vertical />}
                  </button>
                </Upload>
              </ImgCrop>
              <h3 className="bold">{userInfo.alias}</h3>
              <p
                className="lite text-gray perfil-name"
              >
                {user.data ? user.data.user.name : ""}
              </p>
              <div className=" perfil-about text-left">
                <h4 className="bold orange">Sobre mi</h4>
                <p>{userInfo.description}</p>
              </div>
            </div>
          </Col>
          <div className="switch d-block d-sm-none d-none d-sm-block d-md-none">

            <button
              type="button"
              className="back-window-navbar neon_blue"
              onClick={changeUser}
            >
              <i className="icon-swap"></i>
            </button>
            <button
              type="button"
              className="back-window-navbar-logout"
              onClick={cerrar}
            >
              <i className="fi fi-br-sign-in-alt icon-font"></i>
            </button>
          </div>
          <Col className="config-menu">
            <Row>
              <Col lg={12} xs={12} >
                <div className="card3">
                  <Card onClick={Info}>
                    <Card.Body className="bold">
                      <Row>
                        <Col lg={5} xs={4}>
                          <i className="demo-icon-config icon-profile"></i>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <h6 className="bold">Perfil</h6>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </Col>


              {!anfitrion && (
                <Col lg={12} xs={12}>
                  <div className="card3">
                    <Card onClick={TusGustos}>
                      <Card.Body className="bold">
                        <Row>
                          <Col lg={5} xs={4}>
                            <i className="demo-icon-config icon-face"></i>
                          </Col>
                          <Col className="d-flex align-items-center">
                            <h6 className="bold">Gustos</h6>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>


                </Col>
              )}
              {!anfitrion && (
                <Col lg={12} xs={12}>
                  <div className="card3">
                    <Card onClick={MetodoPago}>
                      <Card.Body className="bold">
                        <Row>
                          <Col lg={4} xs={3}>
                            <i className="demo-icon-config icon-card"></i>
                          </Col>
                          <Col className="d-flex align-items-center">
                            <h6 className="bold">Método de Pago</h6>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Configuraciones;
