import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ChatBody } from "./elementos/ChatBody";
import {  Button } from "rsuite";
import { MainContainer, Sidebar } from "@chatscope/chat-ui-kit-react";
import { useWindowsSize } from "../../../hooks/WindowsSize";
import { AuthContext } from "../../../auth/AuthContext";

import {
  collection,
  query,
  orderBy,
} from "@firebase/firestore";
import { useFirestoreCollection, useFirestore } from "reactfire";
import ChatList from "./elementos/ChatList";

const ChatA = () => {
  // Set initial message input value to empty string

  const { user } = useContext(AuthContext);

  const [chatSelected, setchatSelected] = useState({
    id: "",
    image: "",
    name: "",
  });
  const [visibleClass, setvisibleClass] = useState({
    listChat: "visibleChat",
    bodyChat: "novisibleChat",
  });
  const history = useHistory();
  const [width] = useWindowsSize();
  const nodo = user?.data?.user?.rolRequest === true || user?.data?.user?.state === 1 ?  (user?.data?.user?.role ==='owner'  ? 'owner' : 'traveler') : 'traveler'

  const refMysChatsCollection = collection(
    useFirestore(),
    `chat/chatList/${user?.data?.user?.id}/${nodo}/list`
  );

  const { status, data } = useFirestoreCollection(
    query(refMysChatsCollection, orderBy("time", "desc"))
  );


  const Back = () => {
    if (user.logged) {
      if (user.data.user.role === "user") {
        history.replace({ pathname: "/"  });
      } else {
        history.replace({ pathname: `/host/_/${user.data.user.id}` });
      }
    } else {
      history.replace({ pathname: "/" });
    }
  };

  const changeVisible = () => {
    //console.log(width);
    if (width <= 700) {
      visibleClass.listChat === "visibleChat"
        ? setvisibleClass({
            listChat: "novisibleChat",
            bodyChat: "visibleChat",
          })
        : setvisibleClass({
            listChat: "visibleChat",
            bodyChat: "novisibleChat",
          });
    }
  };

  const selectChatById = (chat) => {
    const { image, name } = chat.data();
    setchatSelected({ id: chat.id, image: image, name: name });
    changeVisible();
  };

  useEffect(() => {

    if(!user){
      history.replace({ pathname: `/login` });
    }

  }, [user]);

  return (
    <div className="">
      <div className="" style={{ position: "relative", height: '100vh', maxHeight: '100vh' }}>
        <MainContainer style={{  maxHeight: '100vh' }}>
          <Sidebar
            className={visibleClass.listChat}
            position="left"
            scrollable={true}
          >
            <Button
              style={{
                marginBottom:'-2rem'
              }}
              type="button"
              className="back-window fixed-top"
              onClick={Back}
            >
              <i className="demo-icon-exp  icon-left-arrow"></i>
            </Button>

            <ChatList
              user={user}
              data={data}
              status={status}
              select={selectChatById}
            />
          </Sidebar>


          <ChatBody
            classname={visibleClass.bodyChat}
            changevisible={changeVisible}
            user={user}
            selected={chatSelected}
            statusList ={status}
          />
        </MainContainer>
      </div>
    </div>
  );
};

export default ChatA;
