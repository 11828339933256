import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { BASE_PATH } from "../constants";
import {
  Alert,
  Form,
  FormGroup,
  FormControl,
  Button,
  Icon,
  Modal,
  Input,
} from "rsuite";
import {} from "react-bootstrap";
import "./style.css";
import { AuthContext } from "../../auth/AuthContext";
import { types } from "../../types/types";

//Importar Recursos
import logo from "../../assets/images/icono.svg";
import InputPassword from "./components/InputPassword";

const Login = () => {
  const { dispatch } = useContext(AuthContext);
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openModal, setopenModal] = useState(false);
  const [openModalP, setopenModalP] = useState(false);
  const [forgotEmail, setforgotEmail] = useState("");
  const HomeB = () => {
    history.replace({ pathname: "/" });
  };
  const register = () => {
    history.replace({ pathname: "/register" });
  };

  const handleOpenModal = () => {
    openModal ? setopenModal(false) : setopenModal(true);
  };
  const handleOpenModalP = () => {
    openModalP ? setopenModalP(false) : setopenModalP(true);
  };

  const onSubmit = async () => {
    if (password == "" || email == "") {
      Alert.error("Campos requeridos vacios.", 2000);
      return;
    } else {
      //console.log(email, password);
      axios({
        method: "POST",
        url: `${BASE_PATH}/auth`,
        auth: {
          username: email,
          password: password,
        },
      })
        .then(function (response) {
          if (response.status === 201) {
            //console.log(response);
            onSuscribeNotification(response.data.user.id, response.data.token);
            sessionStorage.setItem("alerta", true);
            dispatch({
              type: types.login,
              payload: response,
            });
            Alert.success("Bienvenido de vuelta!", 2000);
            if (response.data.user.role === "owner") {
              history.replace({ pathname: `/host/_/${response.data.user.id}` });
            } else {
              history.push("/");
            }
          } else if (response.status === 200 && !response.data.emailVerified) {
            handleSendVerifyEmail(response.data.lastToken);
          }
        })
        .catch(function (error) {
          //console.log(error.response);

          if (error.response) {
            if (
              error.response.status === 404 ||
              error.response.status === 400
            ) {
              //  console.log(e.response.data.message);
              Alert.error(error.response.data.message, 2000);
            } else if (
              error.response.status === 500 ||
              error.response.status === 401
            ) {
              Alert.error("Datos incorrectos", 2000);
            }
          } else {
            Alert.error("Error en el servidor, intentalo luego", 2000);
          }
        });
    }
  };

  const responseGoogle = async (response) => {
    try {
      let Login = await axios.post(`${BASE_PATH}/auth/google`, {
        access_token: response.accessToken,
      });
      if (Login.status === 201) {
        onSuscribeNotification(Login.data.user.id, Login.data.token);
        sessionStorage.setItem("alerta", true);
        Alert.success("Bienvenido de vuelta!", 2000);
        dispatch({
          type: types.login,
          payload: Login,
        });
        if (Login.data.user.role === "owner") {
          history.replace({ pathname: `/host/_/${Login.data.user.id}` });
        } else {
          history.push("/");
        }
      }
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 404 || e.response.status === 400) {
          //  console.log(e.response.data.message);
          Alert.warning(e.response.data.message, 2000);
        }
      }
    }
  };

  const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };

  const responseFacebook = async (response) => {
    try {
      let loginFacebook = await axios.post(`${BASE_PATH}/auth/facebook`, {
        access_token: response.accessToken,
      });

      if (loginFacebook.status == 201) {
        Alert.success("Bienvenido de vuelta!", 2000);
        sessionStorage.setItem("alerta", true);
        onSuscribeNotification(
          loginFacebook.data.user.id,
          loginFacebook.data.token
        );

        dispatch({
          type: types.login,
          payload: loginFacebook,
        });
        if (loginFacebook.data.user.role === "owner") {
          history.replace({
            pathname: `/host/_/${loginFacebook.data.user.id}`,
          });
        } else {
          history.push("/");
        }
      } else {
        Alert.warning("Fallo en la inicio de sesion, vuelva a intentar", 2000);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 404 || e.response.status === 400) {
          Alert.warning(e.response.data.message, 2000);
        }
      }
    }
  };

  const handleSendVerifyEmail = async (id) => {
    try {
      const request = await axios.post(`${BASE_PATH}/users/send-verify-email`, {
        email: email,
        originUrl: window.location.origin,
      });
      //console.log(request)
      if (request.status === 200 && request.data.emailSended) {
        Alert.success("Se ha enviado un correo de verificación!");
        history.replace({ pathname: `/send-verify-email/${id}` });
      }
    } catch (error) {
      Alert.error("Ha ocurrido un error en el servidor");
      console.log(error);
    }
  };

  const forgotPassword = async () => {
    if (!!!forgotEmail) {
      return;
    }
    try {
      const request = await axios.post(`${BASE_PATH}/users/forgot-password`, {
        email: forgotEmail,
        originUrl: window.location.origin,
      });

      if (request.status === 200) {
        handleOpenModal();
        setforgotEmail("");
        Alert.success("Se ha enviado un correo de recuperación!", 5000);
      }
      //console.log(request);
    } catch (error) {
      console.log(error);
      Alert.error("Ha ocurrido un error en el servidor", 3000);
    }
  };

  const onSuscribeNotification = async (userId, token) => {
    const Ftoken = sessionStorage.getItem("notification_token");
    if (!!Ftoken) {
      const suscribe = await axios.post(
        `${BASE_PATH}/notifications/subscribe`,
        { fToken: Ftoken, userId: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  };

  useEffect(() => {
    dispatch({ type: types.logout });
  }, []);

  return (
    <div
      className="d-flex justify-content-center
    d-flex align-items-center vh-100"
    >
      <button type="button" className="back-window fixed-top" onClick={HomeB}>
        <i className="icon-back  icon-left-arrow"></i>
      </button>
      <Card style={{ width: "18em" }} className="border-0">
        <img src={logo} className="login-logo" alt="logo" />
        <Card.Body>
          <Card.Title className="bold login-title text-center"></Card.Title>

          <Form layout="horizontal" fluid>
            <Card.Title className="Medium login-title-code"></Card.Title>
            <Row className="mb-3">
              {/*
              <Col sm={8} xs={8}>
                <FacebookLogin
                  appId={appIDFacebook}
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <button
                    disabled
                      type="button"
                      className="bold btn-social text-left"
                      onClick={renderProps.onClick}
                      isDisabled={renderProps.isDisabled}
                    >
                      <i className="demo-icon-social icon-facebook text-primary"></i>
                      facebook
                    </button>
                  )}
                />
                  </Col>*/}
              <Col sm={12} xs={12}>
                <GoogleLogin
                  clientId="528668811942-ssf2q4juvjfkdru5lk5u9nfqs1nbq5nb.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      className="bold btn-social "
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <i className="icon-google text-danger"></i>
                    </button>
                  )}
                  buttonText="Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                />
              </Col>
            </Row>
            <div style={{ margin: "" }}></div>
            <p className="lite  text-center" style={{ marginTop: 11 }}>
              ó ingresa con tu cuenta de correo
            </p>
            <FormGroup>
              <FormControl
                className="form-control text-pass btn-lg "
                value={email}
                onChange={(val) => setEmail(val)}
                placeholder="Correo"
                type="email"
              />
            </FormGroup>

            <FormGroup>

              <InputPassword value={password} setValue={setPassword} />

              <Button
                className="forgot-password text-center btn-black"
                onClick={setopenModal}
              >
                ¿Olvidaste tu contraseña?
              </Button>
            </FormGroup>
            <Button
              className="bold text-center btn-primary btn-login-first"
              onClick={onSubmit}
            >
              Entrar
            </Button>
            <div className="d-flex justify-content-center">
              <Button className="text-center mt-2" onClick={register}>
                Regístrate manualmente
                <Icon
                  icon="arrow-circle-right"
                  style={{ marginLeft: "0.6rem" }}
                />
              </Button>
            </div>
          </Form>
        </Card.Body>
        <p
          className="lite  text-center"
          style={{ marginTop: 3, fontSize: "12px", lineHeight: "17px" }}
        >
          Al iniciar sesión o registrarte aceptas nuestras <br />
          <a onClick={setopenModalP} className="link">
            Políticas, Términos y Condiciones de Uso.
          </a>
        </p>
      </Card>

      <Modal
        size="xs"
        show={openModal}
        onHide={setopenModal}
        style={{ maxWidth: "98%" }}
      >
        <Modal.Header>
          <Modal.Title>Ingresa el correo de tu cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            placeholder="Correo"
            onChange={(value) => setforgotEmail(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ background: "#fe5000" }}
            onClick={forgotPassword}
            appearance="primary"
          >
            Recuperar
          </Button>
          <Button
            onClick={() => {
              handleOpenModal();
            }}
            appearance="subtle"
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={openModalP}
        onHide={setopenModalP}
        style={{ maxWidth: "98%" }}
      >
        <Modal.Footer>
          <Button
            onClick={() => {
              handleOpenModalP();
            }}
            appearance="subtle"
          >
            Cerrar
          </Button>
        </Modal.Footer>
        <Modal.Body>
          <h3 className="bold">Políticas de Privacidad y Condiciones de Uso</h3>
          Ir a enlace temporal <a href="https://tugo.app/policy.html" target="_blank">Link</a>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
