import React, {  } from 'react'
import {  } from 'react-router-dom'

//Importar Recursos


const ChatOpen = () => {

    return (
        <div className="">
            <div className="nav-bottom">
            
            </div>

        </div>
    )
}

export default ChatOpen