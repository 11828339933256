import axios from "axios";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import { BASE_PATH } from "../constants";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck, faBug} from "@fortawesome/free-solid-svg-icons";

const IsVerify = () => {

  const history = useHistory();

  const [verify, setverify] = useState(false);
  const [title, settitle] = useState('Estamos verificandote...')
  const [icon, seticon] = useState(faSpinner)


  const getToken = () => {
    const parameter = "token".replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + parameter + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const handleVerifyEmail = async () => {
    //setbntLocked(true)
    try {
      const request = await axios.post(`${BASE_PATH}/users/verify-email`, {
        token: getToken(),
      });
      //console.log(request);

      if (request.status === 200 && request.data.verified) {
        setverify(true);
        settitle('Listo! ahora puedes acceder a Tugo')
        seticon(faCheck)

      }else if (request.status === 202){
        setverify(true);
        settitle('Esta cuenta ya ha sido verificada!')
        seticon(faCheck)

      }
    } catch (error) {
      //Alert.error("Ha ocurrido un error en el servidor");
      console.log(error);
      settitle('Ha ocurrido un error')
      seticon(faBug)
      //setbntLocked(false)
    }
  };

  const goToLogin = () => {
    history.replace({ pathname: "/login" });
  };

  useEffect(() => {
    //console.log("token =>", getToken());
    if (getToken()) {
      handleVerifyEmail();
    }else{
        history.replace({ pathname: "/" });
    }
  }, []);
  return (
    <>
  

      <div
        style={{
          marginTop: "150px",
          width: "100%",
          textAlign: "center",
          padding: "50px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="verify-email-container"
      >
         <img src={logo} className="logo" />
        <h2>
          {title}
        </h2>

        <FontAwesomeIcon
          style={{ fontSize: "75px" }}
          icon={icon}
          className="orange"
        />
        <button
          style={{ display: "block", maxWidth: "200px", margin: "20px" }}
          type="button"
          className="btn btn-primary btn-auth "
          onClick={goToLogin}
          disabled={!verify}
        >
          Ir al Login
        </button>
      </div>
    </>
  );
};

export default IsVerify;
