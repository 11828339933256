import React, { useState, useEffect } from "react";

import axios from "axios";
import { BASE_PATH } from "../constants";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Image } from "antd";

const Galeria = ({ photos = [] }) => {

  const [pictures, setPictures] = useState([])

  useEffect(() => {

    const mapedPictures = photos.map((picture) => {
      return {
        src: `${picture.url}`,
        sizes: "(min-width: 36em) calc(.333 * (100vw - 12em)), 100vw",
        width: 4,
        height: 3,
        id: picture.id
      };
    })

    setPictures(mapedPictures)

  }, [photos]);

  return (
    <div>

      <Image.PreviewGroup>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 750: 2, 1200: 3 }}
        >
          <Masonry gutter='10px'>
            {pictures.map((item) => (
              <Image key={item.id} src={item.src} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Image.PreviewGroup>
    </div>
  );
};

export default Galeria;
