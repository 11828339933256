import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import axios from "axios";
import moment from "moment";
import { AuthContext } from "../../auth/AuthContext";
import { BASE_PATH } from "../constants";
import { Favoritos } from "./elementos/Favoritos";
import { Button, Alert, Modal, Checkbox, Loader, InputPicker } from "rsuite";

// Importar Recursos
import Viajero from "../Navbars/Viajero";
import logo from "../../assets/images/icono.svg";
import {
  dateOptions,
  getFormatTime,
  monthsInYear,
  setLeftZeroInNumber,
} from "../Anfitrion/Experiencias/schedules/utils";

const ESTADOS_RESERVAS = [
  {
    value: "Pendiente",
    label: "Por llegar",
  },
  {
    value: "Cancelado",
    label: "Canceladas",
  },
  {
    value: "Finalizado",
    label: "Finalizadas",
  },
];

const Planes = () => {
  const { user } = useContext(AuthContext);
  const [reserves, setReserves] = useState([]);
  const [copyAllReserves, setcopyAllReserves] = useState([]);
  const [filter, setfilter] = useState("Pendiente");

  const [openmodal, setopenmodal] = useState(false);
  const [deleteData, setdeleteData] = useState({});
  const [loading, setloading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  moment.locale("es");

  const getReservas = async () => {
    if (user.logged) {
      let result = await axios.get(
        `${BASE_PATH}/payments?id=${user.data.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );
      if (result.status === 200) {
        const filterPayments = result.data.rows.filter(
          (e) => e.experiences !== null
        );
        setcopyAllReserves(filterPayments);

        const paymentsPending = filterPayments.filter(
          (e) => e.status === "Pendiente"
        );
        setReserves(paymentsPending);
      }

      setloading(false);
    }
  };

  const ApplyFilter = (status) => {
    const paymentsFiltered = copyAllReserves.filter((e) => e.status === status);

    setReserves(paymentsFiltered);
    setfilter(status);
  };

  const deleteReserve = async () => {
    setDeleting(true);
    try {
      let result = await axios.delete(
        `${BASE_PATH}/payments/${deleteData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      if (result.status === 200) {
        if (!result.data.canceled) {
          setopenmodal(false);
          Alert.error(result.data.msg, 4000);
        } else if (result.data.transactionId) {
          setRefound(
            result.data.refundAmount,
            result.data.code,
            result.data.transactionId,
            result.data.percentage
          );
        } else {
          getReservas();
          setopenmodal(false);
          Alert.error(result.data.msg, 4000);
        }
        setDeleting(false);
      }

      // console.log(result);
    } catch (error) {
      console.log(error);
      setopenmodal(false);
      Alert.error("Ha ocurrido un error. Intentalo luego!", 2000);
      setDeleting(false);
    }
  };

  const setRefound = async (amount, code, transaction, pertentage) => {
    try {
      let result = await axios.post(
        `${BASE_PATH}/income/refound`,
        {
          amount: amount,
          currency: "GTQ",
          code: code,
          idtransaction: transaction,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      // console.log(result);
      if (result.status === 200) {
        getReservas();
        setopenmodal(false);
        Alert.success(
          `Se te reembolsará el ${pertentage}% del costo total de esta reserva`,
          4000
        );
      }
    } catch (error) {
      console.log(error);
      setopenmodal(false);
      getReservas();
    }
  };

  const notificationDelete = (id, reserve, fecha, schedule) => {
    const formatDate = new Date(fecha).toLocaleDateString("es-ES", dateOptions);

    setdeleteData({ id, reserve, fecha: formatDate, schedule });
    setopenmodal(true);
  };

  useEffect(() => {
    getReservas();
  }, []);

  return (
    <div className="container" style={{ paddingBottom: 140 }}>
      <Viajero />
      <section className="sm_nav">
        <div className="d-none d-md-block d-lg-none d-sm-block d-md-none d-block d-sm-none">
          <nav
            className="navbar-sm-more navbar-light fixed-top"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              width: "100%",
              paddingLeft: "3.5rem",
            }}
          >
            <img src={logo} className="logo_in2" alt="logo" />
            <h4 className="bold" style={{ paddingTop: "0.5rem" }}>
              Planes
            </h4>
          </nav>
        </div>
        <br />
        <br />
        <Row>
          {loading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <Loader
                inverse
                center
                size="md"
                content="Cargando..."
                className="text-white"
              />
            </div>
          )}

          <Col lg={8} xs={7}>
            <div className="categoria-parati">
              <InputPicker
                data={ESTADOS_RESERVAS}
                value={filter}
                onSelect={ApplyFilter}
                placement="bottom"
              />
            </div>

            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1200: 3 }}>
              <Masonry gutter={20}>

                {reserves.length > 0 &&
                  reserves.map((item) => {
                    let hours = new Date(item.bookedDateTime).getHours()
                    let minutes = setLeftZeroInNumber(new Date(item.bookedDateTime).getMinutes())
                    let timeFormat = `${hours}:${minutes}`
                    return (
                      <div key={item.id} style={{ position: "relative" }}>
                        {item.status === "Pendiente" && (
                          <Button
                            className="btn_cancel"
                            onClick={() => {
                              notificationDelete(
                                item.id,
                                item.experiences.name,
                                item.bookedDateTime,
                                item.schedule
                              );
                            }}
                            style={{
                              zIndex: "100",
                              position: "absolute",
                              right: "0",
                              top: "0",
                              margin: "10px",
                            }}
                          >
                            <i
                              style={{ color: "black" }}
                              className=" icon-cancel"
                            ></i>
                          </Button>
                        )}
                        {item.status !== "Pendiente" && (
                          <div
                            className="payment_banner"
                            style={{
                              zIndex: "1",
                              position: "absolute",
                              right: "0",
                              top: "0",
                              margin: "10px",
                            }}
                          >
                            {item.status}
                          </div>
                        )}

                        <Link to={`/exp/reservada/${item.experiences.id}/${item.id}`}>
                          <Card className="pointer border-0 image04">
                            <Card.Img
                              src={
                                item.experiences.pictures &&
                                  item.experiences.pictures[0]
                                  ? item.experiences.pictures[0].path
                                  : logo
                              }
                              alt="image-card"
                              title="image-card"
                            />
                            <Card.ImgOverlay>
                              <div className="card_planes">
                                <text className="orange heavy">
                                  {setLeftZeroInNumber(
                                    new Date(item.bookedDateTime).getDate()
                                  )}
                                </text>
                                <p className="text-grey bold">
                                  {
                                    monthsInYear[
                                    new Date(item.bookedDateTime).getMonth()
                                    ]
                                  }
                                </p>
                                <p className="text-grey bold" style={{ textAlign: 'center' }}>
                                  {getFormatTime(timeFormat)}
                                </p>
                              </div>
                              <div className="card-bottom">
                                <h3 className="bold text-white card-titulo">
                                  {item.experiences.name}
                                </h3>
                                <text className="text-white bold d-none d-md-none d-lg-block card-precio d-flex">
                                  <p>${parseFloat(item.mount).toFixed(2)} por reserva</p>
                                </text>
                                <p className="place">
                                  <i className="icon-marker"></i>
                                  {item.experiences.direction || " "}
                                </p>
                              </div>
                            </Card.ImgOverlay>
                          </Card>
                        </Link>
                      </div>
                    );
                  })}

              </Masonry>
            </ResponsiveMasonry>            {reserves.length === 0 && (
              <div className="planes-fav-titulo">
                <text>No hay reservas para mostrar</text>
              </div>
            )}
          </Col>
          <Col className="planes-fav">
            <div className="planes-fav-titulo">
              <text>Tus Favoritos</text>
            </div>
            <Favoritos />
          </Col>
        </Row>
      </section>

      <Modal
        show={openmodal}
        onHide={() => {
          setopenmodal(false);
        }}
        style={{ maxWidth: "98%" }}
      >
        <Modal.Header>
          <Modal.Title>Eliminar esta reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: "relative" }}>
          ¿Deseas eliminar tu reserva en {deleteData?.reserve} para el{" "}
          {deleteData?.fecha}
          <br />
          <p
            style={{
              fontSize: "10px",
              textAlign: "center",
              width: "100%",
            }}
          >
            Antes de proceder por favor lee nuestras politicas de reembolso
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            style={{ background: "#fe5000" }}
            onClick={deleteReserve}
            disabled={deleting}
          >
            Eliminar
          </Button>
          <Button
            onClick={() => {
              setopenmodal(false);
            }}
            appearance="subtle"
          >
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Planes;
