import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import "../../../assets/css/App.css";
import "antd/dist/antd.css";
import { Progress } from "rsuite";
//Importar Recursos
import ExperienciaNewContent from "../../Anfitrion/Experiencias/ExperienciaNewContent";
import { AuthContext } from "../../../auth/AuthContext";
import { Alert } from "rsuite";
import axios from "axios";
import { BASE_PATH } from "../../constants";

const ExperienciaNew = () => {

  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [current, setCurrent] = React.useState(0);

  const [form, setForm] = useState(false);
  const [ubicacion, setUbicacion] = useState(false);

  const { Circle } = Progress;


  const [loader, setLoader] = useState(false);
  const [percent, setpercent] = useState(1);

  const Datos = (form, ubicacion) => {
    setForm(form);
    setUbicacion(ubicacion);
  };

  const steps = [
    {
      title: "",
      content: (
        <ExperienciaNewContent
          newform={form}
          newubicacion={ubicacion}
          edit={false}
          nextContent={Datos}
        />
      ),
    },
  ];


  const ExpCreada = async () => {
    // console.log(duration, '=> horas');
    try {
      if (
        !!!form.name ||
        !!!form.description ||
        !!!form.start ||
        !!!form.end ||
        !!!form.minQuotas ||
        !!!form.maxQuotas ||
        !!!form.direction ||
        !!!form.duration ||
        !!!ubicacion
      ) {
        Alert.warning("Campos  requeridos vacíos.", 2000);
        return;

      }

      setLoader(true);
      setpercent(15);
      const resp = await axios.post(
        `${BASE_PATH}/experiences`,
        {
          name: form.name,
          "price": {
            "adultPrice": 0,
            "childrenPrice": 0,
            "coupon": {
              "code": "",
              "discount": ""
            },
            "discount": {
              "price": "",
              "people": "",
            },
          },
          description: form.description,
          duration: `${form.duration} horas`,
          direction: form.direction,
          lat: String(ubicacion.lat),
          long: String(ubicacion.lng),
          quotas: {
            min: form.minQuotas,
            max: form.maxQuotas
          },
          start: form.start,
          end: form.end,
          extra: form.extra,
          enabled: false
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      if (resp.status === 201) {
        setpercent(99);
        setTimeout(() => {
          setLoader(false);
          Alert.success("Se creó tu experiencia!", 2000);
          history.replace({
            pathname: `/host/creada/${resp.data.id}`,
          });
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
      setLoader(false);
      Alert.error("Ha ocurrido un error en el servidor", 3000);
    }
  };

  useEffect(() => {
    if (!user.logged || user.data.user.role !== "owner") {
      history.replace({ pathname: "/" });
    }
  }, [user]);

  return (
    <div>
      {loader && (
        // <Loader size="lg" backdrop center content="Guardando. Por favor espera..." vertical />

        <div className="progress-loader">
          <div className="progress-loader-content">
            <Circle
              percent={percent}
              strokeColor="#262DFF"
              status={percent < 99 ? "active" : "success"}
            />
          </div>
        </div>
      )}
      <>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action bold navbar-sm-more-host exp-fixed-bottom">

          <Button
            className="bold exp-pasos btn-primary-reserva-host"
            type="primary"
            onClick={ExpCreada}
          >
            Crear
          </Button>
        </div>
      </>
    </div>
  );
};

export default ExperienciaNew;
