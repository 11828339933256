import React from "react";
import { Message } from "rsuite";

const MessageAlert = ({type, title, description, showIcon}) => {
  return (
    <Message
      showIcon = {showIcon}
      type={type}
      title={title}
      description={description}
      style={{ background: "none" }}
    />
  );
};

MessageAlert.defaultProps ={
    type: 'info',
    title: '',
    description : '',
    showIcon: true
}
export default MessageAlert;
