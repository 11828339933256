import React, { useState, useEffect, useContext } from 'react';
import { Upload } from 'antd';
import axios from 'axios';
import { BASE_PATH } from '../../constants';
import 'antd/lib/style/index.css'
import { AuthContext } from '../../../auth/AuthContext';

const ExperienciaNewPhotos = ({ pictures, selectPictures, experience }) => {
  const [fileList, setFileList] = useState(pictures);
  const { user } = useContext(AuthContext);

  const onChange = ({ fileList: newFileList }) => {

    setFileList(newFileList);
    selectPictures(newFileList)

  };

  const removePhoto = async photico => {
    try {

      if (photico.id) {

        axios.delete(`${BASE_PATH}/images/${photico.id}/${experience}`,
          {
            headers: {
              Authorization: `Bearer ${user.data.token}`
            }
          })
        // console.log(deletePicture);
      }


    } catch (error) {
      console.log(error);
    }
  }
  const onPreview = async file => {

    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const uploadButton = (
    <div>
      <i className="demo-icon icon-camera neon_blue" style={{ fontSize: '25px' }}></i>
    </div>
  );


  useEffect(() => {

    console.log(pictures);

  }, [])


  return (
    <div>

      <div className="container-sm" style={{ paddingTop: '6rem' }}>
        <h5 className='bold'>Sigue estas instrucciones:</h5>
        <div className='mb-3 text-grey text-instructivo'>
          1 - Ingresa a <a href='https://www.iloveimg.com' target='_blank' className='orange'> https://www.iloveimg.com </a>, comprime y luego reduce el tamaño de tus imagenes en las opciones COMPRESS IMAGE y RESIZE IMAGE. <br />
          2 - Cuando descargues tus fotografías luego de comprimirlas, asegurate de que estén en su rotación correcta.<br />
          3 - Sube tus fotografías en lotes de 5, tienes para subir hasta 20 fotografías, ¡LISTO!.
        </div>
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          defaultFileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          beforeUpload={() => false}
          onRemove={removePhoto}
        >
          {fileList.length >= 20 ? null : uploadButton}
        </Upload>

      </div>
    </div>
  );
};

export default ExperienciaNewPhotos