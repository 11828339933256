import React, { useState, useEffect, useContext } from "react";

import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import { BASE_PATH } from "../../constants";
import axios from "axios";
import { AuthContext } from "../../../auth/AuthContext";

//Importar Recursos
import Anfitrion from "../../Navbars/Anfitrión";
import logo from "../../../assets/images/logo.svg";

const Calendario = () => {
  const { user } = useContext(AuthContext);

  const [eventos, setEventos] = useState([]);

  const getSchedules = async () => {
    try {
      const result = await axios.get(`${BASE_PATH}/users/schedules/${user.data.user.id}`, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });

      if (result.status === 200) {
        const reduceSchedules = result.data.schedules.reduce((acc, current) => {

          const { bookings, experience } = current 

          const events = bookings.map((booking) => {

            const { bookedQuotas, bookedDateTime } = booking

            return {
              title: `${experience.name || "Sin Título"} (${bookedQuotas} cupos vendidos)`,
              start: new Date(bookedDateTime),
              backgroundColor: "red",
              borderColor: "red",
            }

          })

          return [...acc, ...events]

        }, [])

        setEventos(reduceSchedules);

      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchedules();
  }, []);
  return (
    <div style={{ paddingBottom: 140 }} className="">
      <div>
        <Anfitrion />
      </div>
      <div className="search-movil d-none d-md-block d-lg-none d-sm-blockd-md-none d-block d-sm-none">
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className="navbar-sm-more navbar-light fixed-top"
        >
          <img src={logo} className="logo_in2" alt="logo" />
        </nav>
      </div>
      <section className="container page-calendario">
        <FullCalendar
          events={eventos}
          plugins={[listPlugin]}
          locale="es"
          initialView="listYear"
          headerToolbar={{
            left: "title",
            right: "prev,next",
          }}
          noEventsContent="No hay eventos para mostrar"
          className={"page-calendar-in page-calendar-movil"}
        />
      </section>
    </div>
  );
};

export default Calendario;
