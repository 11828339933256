import React from "react";
import { useHistory } from "react-router-dom";

import "../../assets/css/App.css";
import "antd/dist/antd.css";

//Importar Recursos

import { AgregarTarjeta } from "./AgregarTarjeta";

const MetodoPago = () => {
  //Numero de tarjeta de prueba > 4517 5100 0002 0087
  const history = useHistory();

  const Back = () => {
    history.replace({ pathname: "/configuraciones" });
  };


  return (
    <div className="container-sm ">
      <nav className="navbar-titles navbar-light d-flex align-items-center">
        <button type="button" className="back-window-navbar" onClick={Back}>
          <i className="  icon-left-arrow"></i>
        </button>
        <h5 className="bold">Método de Pago</h5>
      </nav>
      <div>
      <AgregarTarjeta
       listcards={true} stylesclass="exp-fixed-bottom-metodo-pago btn-primary bold" 
      /></div>
    </div>
  );
};

export default MetodoPago;
