import React, { useState } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import { Form } from "react-bootstrap";
import { Alert, Row, Col } from "rsuite";
import images from "react-payment-inputs/images";

const States = {
  US: [
    {
      name: "Alabama",
      value: "AL",
    },
    {
      name: "Alaska",
      value: "AK",
    },
    {
      name: "Arizona",
      value: "AZ",
    },
    {
      name: "Arkansas",
      value: "AR",
    },
    {
      name: "California",
      value: "CA",
    },
    {
      name: "Carolina del Norte",
      value: "NC",
    },
    {
      name: "Carolina del Sur",
      value: "SC",
    },
    {
      name: "Colorado",
      value: "CO",
    },
    {
      name: "Connecticut",
      value: "CT",
    },
    {
      name: "Dakota del Norte",
      value: "ND",
    },
    {
      name: "Dakota del Sur",
      value: "SD",
    },
    {
      name: "Delaware",
      value: "DE",
    },
    {
      name: "Florida",
      value: "FL",
    },
    {
      name: "Georgia",
      value: "GA",
    },
    {
      name: "Hawái",
      value: "HI",
    },
    {
      name: "Idaho",
      value: "ID",
    },
    {
      name: "Illinois",
      value: "IL",
    },
    {
      name: "Indiana",
      value: "IN",
    },
    {
      name: "Iowa",
      value: "IA",
    },
    {
      name: "Kansas",
      value: "KS",
    },
    {
      name: "Kentucky",
      value: "KY",
    },
    {
      name: "Luisiana",
      value: "LA",
    },
    {
      name: "Maine",
      value: "ME",
    },
    {
      name: "Maryland",
      value: "MD",
    },
    {
      name: "Massachusetts",
      value: "MA",
    },
    {
      name: "Míchigan",
      value: "MI",
    },
    {
      name: "Minnesota",
      value: "MN",
    },
    {
      name: "Misisipi",
      value: "MS",
    },
    {
      name: "Misuri",
      value: "MO",
    },
    {
      name: "Montana",
      value: "MT",
    },
    {
      name: "Nebraska",
      value: "NE",
    },
    {
      name: "Nevada",
      value: "NV",
    },
    {
      name: "Nueva Jersey",
      value: "NJ",
    },
    {
      name: "Nueva York",
      value: "NY",
    },
    {
      name: "Nuevo Hampshire",
      value: "NH",
    },
    {
      name: "Nuevo México",
      value: "NM",
    },
    {
      name: "Ohio",
      value: "OH",
    },
    {
      name: "Oklahoma",
      value: "OK",
    },
    {
      name: "Oregón",
      value: "OR",
    },
    {
      name: "Pensilvania",
      value: "PA",
    },
    {
      name: "Rhode Island",
      value: "RI",
    },
    {
      name: "Tennessee",
      value: "TN",
    },
    {
      name: "Texas",
      value: "TX",
    },
    {
      name: "Utah",
      value: "UT",
    },
    {
      name: "Vermont",
      value: "VT",
    },
    {
      name: "Virginia",
      value: "VA",
    },
    {
      name: "Virginia Occidental",
      value: "WV",
    },
    {
      name: "Washington",
      value: "WA",
    },
    {
      name: "Wisconsin",
      value: "WI",
    },
    {
      name: "Wyoming",
      value: "WY",
    },
  ],
  CA: [
    {
      name: "Ontario ",
      value: "ON",
    },
    {
      name: "Quebec",
      value: "QC",
    },
    {
      name: "Nueva Escocia",
      value: "NS",
    },
    {
      name: "Nuevo Brunswick",
      value: "NB",
    },
    {
      name: "Manitoba",
      value: "MB",
    },
    {
      name: "Columbia Británica",
      value: "BC",
    },
    {
      name: "Isla del Príncipe Eduardo",
      value: "PE",
    },
    {
      name: "Saskatchewan",
      value: "SK",
    },
    {
      name: "Alberta ",
      value: "AB",
    },
    {
      name: "Terranova y Labrador",
      value: "NL",
    },
    {
      name: "Territorios del Noroeste",
      value: "NT",
    },
    {
      name: "Yukón",
      value: "YT",
    },
    {
      name: "Nunavut",
      value: "NU",
    },
  ],
};

export const FormTarjeta = ({
  payment,
  handleChange,
  actionCard,
  action,
  countries = [],
}) => {
  const [card, setcard] = useState(payment);
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const { erroredInputs, touchedInputs, error } = meta;

  const onclick = () => {
    // console.log(error);

    if (error || !validateformata()) {
      Alert.error("Datos sin completar. revise bien los datos", 5000);
    } else {
      actionCard(card);
    }
  };

  const onchange = ({ currentTarget: { value, name } }) => {
    let type = meta.cardType ? meta.cardType.displayName : payment.type;
    setcard({ ...card, [name]: value, type });
    handleChange();

    //console.log(type);
  };
  const onFormChange = ({ target: { value, name } }) => {
    setcard({ ...card, [name]: value });
    handleChange();
  };
  const validateformata = () => {
    if (
      !validateData("first_name") ||
      !validateData("address") ||
      !validateData("last_name") ||
      !validateData("country") ||
      !validateData("postalCode") ||
      !validateData("location")
    ) {
      return false;
    } else {
      return true;
    }
  };
  const validateData = (name) => {
    let data = card[name];
    if (data?.trim() === "") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Form className="add-tarjeta2">
      <Row className="show-grid">
        <Col md={12} xs={12}>
          <Form.Group style={{ paddingTop: "5px" }}>
            <Form.Label>Nombre del titular</Form.Label>
            <Form.Control
              name="first_name"
              type="text"
              size="sm"
              placeholder="Juan"
              onChange={onFormChange}
              value={card?.first_name}
            />
          </Form.Group>
        </Col>
        <Col md={12} xs={12}>
          <Form.Group style={{ paddingTop: "5px" }}>
            <Form.Label>Apellido del titular</Form.Label>
            <Form.Control
              name="last_name"
              type="text"
              size="sm"
              placeholder="Perez"
              onChange={onFormChange}
              value={card?.last_name}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="show-grid">
        {action === "Guardar" && (
          <Col md={12} xs={24}>
            <Form.Group style={{ position: "relative" }}>
              <Form.Label>Número de Tarjeta</Form.Label>
              <Form.Control
                className="card-number"
                id="tn"
                {...getCardNumberProps({
                  onChange: onchange,
                  value: card.card,
                  name: "card",
                })}
                isInvalid={touchedInputs.cardNumber && erroredInputs.cardNumber}
                placeholder="0000 0000 0000 0000"
              />
              <svg
                {...getCardImageProps({ images })}
                style={{ position: "absolute", top: "70px", left: "10px" }}
              />

              <Form.Control.Feedback type="invalid">
                {erroredInputs.cardNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
        <Col md={8} xs={12}>
          <Form.Group>
            <Form.Label>Fecha de vto.</Form.Label>
            <Form.Control
              size="sm"
              {...getExpiryDateProps({
                onChange: onchange,
                value: card.date,
                name: "date",
              })}
              isInvalid={touchedInputs.expiryDate && erroredInputs.expiryDate}
            />
            <Form.Control.Feedback type="invalid">
              {erroredInputs.expiryDate}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        {action === "Guardar" && (
          <Col md={4} xs={12}>
            <Form.Group>
              <Form.Label>CVC</Form.Label>
              <Form.Control
                size="sm"
                {...getCVCProps({
                  onChange: onchange,
                  value: card.cvv,
                  name: "cvv",
                })}
                isInvalid={touchedInputs.cvc && erroredInputs.cvc}
                placeholder="123"
              />
              <Form.Control.Feedback type="invalid">
                {erroredInputs.cvc}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
      </Row>

      <Row className="show-grid">
        <Col md={12}>
          <Form.Group controlId="formGridCity">
            <Form.Label>Pais</Form.Label>
            <Form.Control
              name="country"
              as="select"
              onChange={onFormChange}
              value={card?.country}
            >
              <option value="">Elegir pais ...</option>
              {countries.map((el) => (
                <option key={el.value} value={el.value}>
                  {el.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group controlId="formGridState">
            <Form.Label>Ciudad / Estado / Provincia </Form.Label>

            {card?.country === "US" || card?.country === "CA" ? (
              <Form.Control
                name="location"
                as="select"
                onChange={onFormChange}
                value={card?.location}
              >
                {States[card?.country]?.map((el) => (
                  <option key={el.value} value={el.value}>
                    {el.name}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                name="location"
                type="text"
                size="sm"
                placeholder="Ciudad de Guatemala"
                onChange={onFormChange}
                value={card?.location}
              />
            )}
          </Form.Group>
        </Col>
        <Col md={20}>
          <Form.Group controlId="formGridState">
            <Form.Label>Direccion</Form.Label>
            <Form.Control
              name="address"
              type="text"
              size="sm"
              placeholder="Col. los milagros ..."
              onChange={onFormChange}
              value={card?.address}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formGridZip" className="mb-5">
            <Form.Label>Codigo postal</Form.Label>
            <Form.Control
              name="postalCode"
              type="text"
              placeholder="1108"
              onChange={onFormChange}
              value={card?.postalCode}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="show-grid" style={{ padding: "5px" }}>
        <button
          type="button"
          className="btn-primary btn-metodo-pago bold btn-login"
          onClick={onclick}
        >
          {action}
        </button>
      </Row>
    </Form>
  );
};
