import React, { useContext, useState, useEffect } from "react";
import { Card, Row, Col, Form, FormControl } from "react-bootstrap";
import { TabLink, TabContent, Tabs } from "react-tabs-redux";
import axios from "axios";
import { types } from "../../../types/types";
import { BASE_PATH } from "../../constants";
import { Doughnut } from "react-chartjs-2";
import { Modal, Alert, Button } from "rsuite";
import { AuthContext } from "../../../auth/AuthContext";

//Importar Recursos

import Anfitrion from "../../Navbars/Anfitrión";
import logo from "../../../assets/images/logo.svg";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const accountTypes = {
  ahorro: "Cuenta de ahorros",
  monetaria: "Cuenta monetaria"
}

const Pagos = () => {
  const { dispatch } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [openModal, setopenModal] = useState(false);
  const [dateToDay, setdateToDay] = useState({ month: "", year: "" });
  const [totalPerMonth, settotalPerMonth] = useState(0);
  const [totalRecord, settotalRecord] = useState(0)
  const [countsPerMonth, setcountsPerMonth] = useState({ finalized: 0, pending: 0 })
  const [bankAcount, setbanckAcount] = useState({
    titular: user?.data?.user?.bankAccount?.titular,
    numAccount: user?.data?.user?.bankAccount?.numAccount,
    bankName: user?.data?.user?.bankAccount?.bankName,
    accountType: user?.data?.user?.bankAccount?.accountType || ""
  });


  const handleChange = ({ target: { name, value } }) => {
    //console.log(name, value);
    setbanckAcount({ ...bankAcount, [name]: value });
  };

  const getPaymentsPerMonth = async () => {
    const thisYear = new Date(Date.now()).getFullYear();
    const thisMonth = months[new Date(Date.now()).getMonth()];
    //console.log(thisMonth, thisYear);
    setdateToDay({ year: thisYear, month: thisMonth });
    try {
      let payments = await axios.get(
        `${BASE_PATH}/payments/total/${user?.data.user.id}?month=${thisMonth}&year=${thisYear}`,
        {
          access_token: user?.data.token,
        }
      );

      if (payments.status === 200 && payments.data.mes) {
        settotalPerMonth(payments.data.total);
        getTotalPaymentsPerMonth(thisMonth, thisYear)
        //console.log(payments.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const getTotalPaymentsPerMonth = async (month, year) => {
    try {
      let payments = await axios.get(
        `${BASE_PATH}/payments/showBookingToMonth/${user?.data.user.id}?month=${month}&year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`
          }
        }
      );

      if (payments.status === 200) {
        setcountsPerMonth({ pending: payments.data.pendiente.total, finalized: payments.data.finalizado.total })
        getTotalRecord()
      }
    } catch (error) {
      Alert.error("Ha ocurrido un error en el servidor", 4000)
      console.log(error);
    }
  }

  const getTotalRecord = async () => {
    try {
      let record = await axios.get(
        `${BASE_PATH}/payments/historicalRecord/${user.data.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`
          }
        }
      );

      // console.log(record);
      if (record.status === 200) {
        settotalRecord(record.data.total)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClick = async () => {

    const { accountType, bankName, numAccount, titular } = bankAcount

    if (!!!titular || !!!numAccount || !!!bankName || !!!accountType) {
      handleOpenModal();

      return;
    }
    try {
      const updateUser = await axios.put(
        `${BASE_PATH}/users/${user.data.user.id}`,
        {
          access_token: user?.data.token,
          ...user.data.user,
          bankAccount: { ...bankAcount },
        }
      );

      if (updateUser.status === 200) {
        // console.log(updateUser);
        dispatch({
          type: types.login,
          payload: {
            data: {
              token: user.data.token,
              user: updateUser.data,
            },
          },
        });
        handleOpenModal();
        Alert.success("Datos actualizados con exito", 5000);
        setbanckAcount({ titular: "", numAccount: "", accountType: "", bankName: "" });
      } else {
        //console.log("No se completo");
        handleOpenModal();
        Alert.error("No se pudieron actualizar los datos, intente de nuevo");
      }
    } catch (e) {
      console.log(e);
      handleOpenModal();
    }
  };

  const handleOpenModal = () => {
    openModal ? setopenModal(false) : setopenModal(true);
  };

  useEffect(() => {
    getPaymentsPerMonth();
  }, []);

  return (
    <div style={{ paddingBottom: 140 }} className="page-pagos-host vh-100">
      <div>
        <Anfitrion />
      </div>
      <div className="search-movil d-none d-md-block d-lg-none d-sm-blockd-md-none d-block d-sm-none">
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className="navbar-sm-more navbar-light fixed-top"
        >
          <img src={logo} className="logo_in2" alt="logo" />
        </nav>
      </div>
      <Tabs className="container-sm mt-2">
        <div className="sticky-top exp-tabs exp-tabs-host">
          <TabLink
            to="tab1"
            className="exp-contenido-btn exp-active"
            activeClassName="neon_blue"
          >
            <i className="demo-icon-exp icon-"></i> Balance
          </TabLink>
          <TabLink
            to="tab2"
            className="exp-contenido-btn exp-active"
            activeClassName="neon_blue"
          >
            <i className="demo-icon-exp icon-"></i>Bancos
          </TabLink>
        </div>
        <div className="d-flex justify-content-center">
          <TabContent for="tab1">
            <Row>
              <Col lg={6} xs={12}>
                <Card className="card-balance text-right">
                  <text style={{ marginBottom: "0.5rem" }} className="pagos">
                    Ingreso Total
                  </text>
                  <p className="bold pagos-balance">
                    {totalRecord} <span className="lite">USD</span>
                  </p>

                  <text
                    style={{ marginBottom: "0.5rem", marginTop: "-1.4rem" }}
                    className="pagos"
                  >
                    Ingreso Mensual
                  </text>
                  <p
                    style={{ fontSize: "25px" }}
                    className="bold pagos-balance"
                  >
                    {totalPerMonth} <span className="lite">USD</span>
                  </p>
                  <text
                    style={{ position: "absolute", bottom: "1rem" }}
                    className="text-left pagos"
                  >
                    {dateToDay.month} {dateToDay.year}
                  </text>
                </Card>
              </Col>
              <Col lg={6} xs={12}>
                <div className="mt-4">
                  <Doughnut
                    className="pagos-graficas"
                    data={{
                      labels: ["Pendiente", "Activo"],
                      datasets: [
                        {
                          label: "# of Votes",
                          data: [countsPerMonth.pending, countsPerMonth.finalized],
                          backgroundColor: ["#2a2b2c", "green"],
                          borderColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                          ],
                          borderWidth: 0,
                        },
                      ],
                    }}
                  />
                </div>
              </Col>
            </Row>
          </TabContent>
          <TabContent for="tab2">
            <Form className="pagos-bancos">
              <Form.Group>
                <Form.Label>Titular de la Cuenta</Form.Label>
                <FormControl
                  name="titular"
                  onChange={handleChange}
                  type="text"
                  value={bankAcount?.titular}
                  placeholder="Jairo Hernández"
                  className="form-control"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Número de Cuenta</Form.Label>
                <FormControl
                  name="numAccount"
                  onChange={handleChange}
                  type="text"
                  value={bankAcount?.numAccount}
                  placeholder="0000000 000  00"
                  className="form-control"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre de la institución</Form.Label>
                <FormControl
                  name="bankName"
                  onChange={handleChange}
                  type="text"
                  value={bankAcount?.bankName}
                  placeholder="Nombre de la institución bancaria"
                  className="form-control"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tipo de cuenta</Form.Label>
                <FormControl
                  as="select"
                  name="accountType"
                  onChange={handleChange}
                  value={bankAcount?.accountType}
                  defaultValue=""
                  placeholder="Seleccionar"
                  className="form-control"
                >
                  <option value="">Seleccionar</option>
                  <option value="Cuenta de ahorros">Cuenta de ahorros</option>
                  <option value="Cuenta monetaria">Cuenta monetaria</option>

                </FormControl>
              </Form.Group>
            </Form>
            <button
              type="button"
              onClick={handleOpenModal}
              className="btn btn__bancos bold"
            >
              Guardar
            </button>

            <div className="card-bancos mt-5">
              <text>Cuenta No.</text>
              <p className="medium">
                {user?.data?.user?.bankAccount?.numAccount}
              </p>

              <text>Titular</text>
              <p className="medium">{user?.data?.user?.bankAccount?.titular}</p>

              <text>Banco</text>
              <p className="medium">{user?.data?.user?.bankAccount?.bankName}</p>

              <text>Tipo de cuenta</text>
              <p className="medium">{user?.data?.user?.bankAccount?.accountType}</p>

            </div>
          </TabContent>
        </div>
      </Tabs>

      <Modal
        backdrop="static"
        role="alertdialog"
        size="xs"
        show={openModal}
        onHide={handleOpenModal}
      >
        <Modal.Header>
          <Modal.Title>Se modificaran los datos de cuenta</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h6>¿Estás seguro de cambiar estos datos?</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClick}
            appearance="primary"
            style={{ background: "#fe5000" }}
          >
            De acuerdo
          </Button>
          <Button onClick={handleOpenModal} appearance="subtle">
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Pagos;
