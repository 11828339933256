import React, { useContext, useState } from "react";
import { ListGroup, Form } from "react-bootstrap";
import { Modal, Icon, Button, Alert } from "rsuite";

import axios from "axios";
import { BASE_PATH } from "../../constants";
import { AuthContext } from "../../../auth/AuthContext";

const CardItem = ({ dataCard, changeCard, selectCard }) => {
  const { user } = useContext(AuthContext);
  const { type, name, card, id } = dataCard;

  const [modalState, setModalState] = useState(false);

  const openModal = () => {
    setModalState(true);
  };

  const closeModal = () => {
    setModalState(false);
  };

  const deleteCard = async () => {
    try {
      let resp = await axios.delete(`${BASE_PATH}/cards/${id}`, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });
      if (resp.status === 204) {
        //console.log('Eliminado con exito')
        closeModal();
        refreshCard();
        Alert.success("This is a successful message.", 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refreshCard = () => {
    changeCard();
  };

  const select = () => {
    selectCard(id, card);
  };

  return (
    <ListGroup>
      <ListGroup.Item as="li" className="noBackground">
        <div onClick={select}>
          <Form.Label>{type} </Form.Label> <br></br>
          <Form.Label>**** **** **** {card}</Form.Label> <br></br>
          <Form.Label>{name}</Form.Label>
        </div>
        <Button
          onClick={openModal}
          className="btn_cancel btn-cancel-cards"
          style={{ zIndex: "100" }}
        >
          <i className=" icon-cancel"></i>
        </Button>

        <Modal
          backdrop="static"
          show={modalState}
          onHide={closeModal}
          size="xs"
        >
          <div className="modalConfirmacion">
            <Modal.Body>
              <Icon
                icon="remind"
                style={{
                  color: "#ffb300",
                  fontSize: 24,
                }}
              />
              Esta seguro de eliminar el metodo de pago?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={deleteCard} appearance="default">
                Eliminar
              </Button>
              <Button onClick={closeModal} appearance="primary" color="red">
                No eliminar Tarjeta
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default CardItem;
