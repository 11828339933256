import React, { useEffect, useState } from 'react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
  import useOnclickOutside from "react-cool-onclickoutside";
  import '../../../assets/css/App.css';
import { Alert, Col, Row } from 'rsuite';
import SimpleMap from '../../Experiencias/mapsAdd';

const AgregarUbicacion = ({dataUb, direccionn}) => {

  const [ubicacion, setUbicacion] = useState({
    lat: 14.6349149,
    lng: -90.5068824
    })
  const [direccion, setDireccion] = useState(false)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
      () => {
        setValue(description, false);
        clearSuggestions();
  
        getGeocode({ address: description })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            // console.log("📍 Coordinates: ", { lat, lng });
            setUbicacion({lat: lat, lng: lng}) 
            setDireccion(description)
          })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      };
  
    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
  
        return (
          <li key={place_id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });


  const agregar = () => {
    // console.log(direccionn);
    direccion === false ?
      Alert.warning('Seleccionar una dirección', 2000)
      : direccionn(ubicacion)
  }

  useEffect(() => {

    // console.log(dataUb)

    if(dataUb){
      // console.log('editando mapa =>  ', dataUb);
      setUbicacion({lat: parseFloat(dataUb.lat), lng: parseFloat(dataUb.lng)})
    }
}, [dataUb])

  return (
    <>

          <div ref={ref} className="text_map">
            <input
              className="form-map"
              value={value}
              onChange={handleInput}
              disabled={!ready}
              placeholder="Busca la ubicación"
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && <ul>{renderSuggestions()}</ul>}
          </div>
          <div>
            <SimpleMap isCenter ={ubicacion}/> 
          </div>
      <Row>
        <Col md={24} className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
          <button type="button" onClick={agregar}
            className="bold  btn-map">
            Agregar
          </button>
        </Col>
      </Row>
    </>
  );
};

export default AgregarUbicacion;