


import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Explora from "../componentes/Explora/Explora";
import Planes from "../componentes/Planes/Planes";
import Chat from "../componentes/Anfitrion/Chat/Chat";
import Parati from "../componentes/Experiencias/Parati";
import ExperienciaGaleria from "../componentes/Experiencias/ExperienciaGaleria";
import VR from "../componentes/Experiencias/vr";
import Experiencia from "../componentes/Experiencias/Experiencia";
import ExperienciaHeader from "../componentes/Experiencias/ExperienciaHeader";
import ExperienciaDetails from "../componentes/Experiencias/ExperienciaDetails";
import Pay from "../componentes/Experiencias/ExperienciaPagar";
import Login from "../componentes/Login/Login";
import LoginCode from "../componentes/Login/LoginCode";
import LoginSocial from "../componentes/Login/LoginSocial";
import CreateUser from "../componentes/Login/CreateUser";
import Register from "../componentes/Login/Register";
import Maps from "../componentes/Experiencias/maps";
import ChatOpen from "../componentes/Chat/ChatOpen";
import ConfigViajero from "../componentes/Configuraciones/Configuraciones";

import ExperienciaOpen from "../componentes/Experiencias/ExperienciaOpen";
import ExperienciaReservada from "../componentes/Experiencias/ExperienciaReservada";
import InfoViajero from "../componentes/Configuraciones/InfoViajero";
import MetodoPago from "../componentes/Configuraciones/MetodoPago";
import TusGustos from "../componentes/Configuraciones/TusGustos";
import TusExperiencias from "../componentes/Anfitrion/Experiencias/TusExperiencias";
import ExperienciaHost from "../componentes/Anfitrion/Experiencias/Experiencia";
import Pagos from "../componentes/Anfitrion/Pagos/Pagos";
import Calendario from "../componentes/Anfitrion/Calendario/Calendario";
import ExperienciaNew from "../componentes/Anfitrion/Experiencias/ExperienciaNew";
import { ExperienciaEditar } from "../componentes/Anfitrion/Experiencias/ExperienciaEditar";

import ChatA from "../componentes/Anfitrion/Chat/Chat";
import ExperienciaCreada from "../componentes/Anfitrion/Experiencias/ExperienciaCreada";
import Calendar from "../componentes/Experiencias/Calendar";


import Verify from "../componentes/VerifyEmail/Verify";
import IsVerify from "../componentes/Verify/IsVerify";
import Tutorial from "../componentes/Experiencias/tutorial";
import LandingPage from "../componentes/Info/Landing";
import ForgotPassword from "../componentes/ForgotPassword/ForgotPassword";
import NotFound from '../componentes/404';
import Schedules from '../componentes/Anfitrion/Experiencias/schedules';
import NFT from '../componentes/NFT/NFT';

const Routes = () => {
    return (

        <Router>
            <div>
                <Switch>
                    <Route path="/nft" exact>
                        <NFT/>
                    </Route>
                    <Route path="/tuto" exact>
                        <Tutorial />
                    </Route>
                    <Route path="/host/creada/:id" exact>
                        <ExperienciaCreada />
                    </Route>
                    <Route component={ExperienciaNew} path={"/new"} />
                    <Route path="/calendario" exact>
                        <Calendar />
                    </Route>
                    <Route path="/host" exact />
                    <Route path="/host/_/:id" exact>
                        <TusExperiencias />
                    </Route>
                    <Route path="/host/experiencia/_/:experienciaid" exact>
                        <ExperienciaHost />
                    </Route>
                    <Route path="/host/editar/_/:experienciaid" exact>
                        <ExperienciaEditar />
                    </Route>
                    <Route path="/experiencia/horarios/_/:experienciaId" exact>
                        <Schedules />
                    </Route>
                    <Route path="/host/pagos" exact>
                        <Pagos />
                    </Route>
                    <Route path="/host/calendario" exact>
                        <Calendario />
                    </Route>
                    <Route path="/host/chat" exact>
                        <ChatA />
                    </Route>
                    <Route path="/host/configuraciones" exact>
                        <ConfigViajero />
                    </Route>

                    <Route path="/configuraciones/gustos" exact>
                        <TusGustos />
                    </Route>
                    <Route path="/configuraciones/metodopago" exact>
                        <MetodoPago />
                    </Route>
                    <Route
                        component={InfoViajero}
                        path={"/configuraciones/perfil"}
                    />
                    <Route path="/exp/ok" exact>
                        <ExperienciaReservada />
                    </Route>
                    <Route
                        path="/send-verify-email/:user"
                        component={Verify}
                        exact
                    ></Route>
                    <Route
                        path="/forgot-password"
                        component={ForgotPassword}
                        exact
                    ></Route>
                    <Route path="/verify-email" component={IsVerify} exact></Route>
                    <Route
                        component={ExperienciaOpen}
                        path="/exp/reservada/:id/:idReserva"
                        exact
                    ></Route>
                    <Route path="/configuraciones" exact>
                        <ConfigViajero />
                    </Route>
                    <Route path="/pay" exact>
                        <Pay />
                    </Route>
                    <Route component={Maps} path={"/maps"} />
                    <Route path="/loginsocial" exact>
                        <LoginSocial />
                    </Route>
                    <Route path="/logincode" exact>
                        <LoginCode />
                    </Route>
                    <Route path="/login" exact>
                        <Login />
                    </Route>
                    <Route path="/createuser" exact>
                        <CreateUser />
                    </Route>
                    <Route path="/register" exact>
                        <Register />
                    </Route>
                    <Route
                        component={Experiencia}
                        path={"/experiencia/:experiencia"}
                    />
                    <Route
                        component={ExperienciaDetails}
                        path={"/experienciadetails"}
                    />
                    <Route path="/vr" exact>
                        <VR />
                    </Route>
                    <Route component={ExperienciaGaleria} path={"/galeria"} />
                    <Route component={ExperienciaHeader} path={"/expheader"} />
                    <Route path="/explora" exact>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Explora />
                    </Route>
                    <Route path="/planes" exact>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Planes />
                    </Route>
                    <Route path="/chatopen" exact>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <ChatOpen />
                    </Route>
                    <Route path="/chat" exact>
                        <Chat />
                    </Route>
                    <Route path="/info" exact>
                        <LandingPage />
                    </Route>
                    <Route path="/" exact>
                        <Parati />
                    </Route>

                    <Route component={NotFound} />

                </Switch>
            </div>
        </Router>
    )
}

export default Routes