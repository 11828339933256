import React from 'react'

export const Tarjeta = ({category, index, onPick}) => {

    const selected = () => {
        onPick({id:category.id, index, isSelected:!category.isSelected})
    }

    return (
        <div className={category.isSelected ? 'ip_tarjeta selected' : 'ip_tarjeta'} onClick={selected}>
            <img  src={category.image} alt="icon" />
        </div>
    )
}
