import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../../assets/css/App.css";
import "./style.css";
// import "antd/dist/antd.css";

import { AuthContext } from "../../../auth/AuthContext";
import {
  ControlLabel,
  Form,
  FormGroup,
  FormControl,
  Col,
  Row,
  InputNumber,
  CheckPicker,
  Drawer,
  Grid,
  Schema,
  Alert,
} from "rsuite";
import AgregarUbicacion from "./AgregarUbicacion";
import { StringType, ArrayType } from "schema-typed";

const initForm = {
  minQuotas: "",
  maxQuotas: "",
  name: "",
  description: "",
  extra: [],
  start: "",
  end: "",
  direction: "",
};

const ExperienciaNewContent = ({
  edit,
  exp,
  nextContent,
  newform,
  newubicacion,
}) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const [ubicacion, setUbicacion] = useState(newubicacion);
  const formRef = useRef();

  const [form, setForm] = useState(newform ? newform : initForm);
  const [visible, setVisible] = useState(false);

  const model = Schema.Model({
    name: StringType().isRequired("Campo obligatorio."),
    description: StringType().isRequired("Campo obligatorio."),
    extra: ArrayType().isRequired("Campo obligatorio."),
    minQuotas: StringType().isRequired("Campo obligatorio."),
    maxQuotas: StringType().isRequired("Campo obligatorio."),
    start: StringType().isRequired("Campo obligatorio."),
    end: StringType().isRequired("Campo obligatorio."),
    direction: StringType().isRequired("Campo obligatorio."),
  });


  const Back = () => {
    history.replace({ pathname: `/host/_/${user.data.user.id}` });
  };

  const showDrawer = () => setVisible(true);

  const onClose = () => {
    setVisible(false);
  };


  const dataExtras = [
    {
      label: "Transporte",
      value: { icon: "bus", valor: "Transporte" },
      icon: "bus",
    },
    {
      label: "Almuerzo",
      value: { icon: "cutlery", valor: "Almuerzo" },
      icon: "cutlery",
    },
    {
      label: "Hotel",
      value: { icon: "home", valor: "Hotel" },
      icon: "home",
    },
    {
      label: "Bicicleta",
      value: { icon: "bicycle", valor: "Bicicleta" },
      icon: "bicycle",
    },
    {
      label: "Pieza de Jade",
      value: { icon: "creative", valor: "Pieza de Jade" },
      icon: "creative",
    },
    {
      label: "Equipo de Protección",
      value: { icon: "shield", valor: "Protección" },
      icon: "shield",
    },
    {
      label: "Bebida",
      value: { icon: "beer", valor: "Bebida" },
      icon: "beer",
    },
    {
      label: "Souvenir",
      value: { icon: "tag-authorize", valor: "Souvenir" },
      icon: "tag-authorize",
    },
    {
      label: "Chocolate",
      value: { icon: "hand-peace-o", valor: "Chocolate" },
      icon: "hand-peace-o",
    },
    {
      label: "Herramientas",
      value: { icon: "gears2", valor: "Herramientas" },
      icon: "gears2",
    },
  ];


  const onDireccion = (data, frm = form) => {
    //console.log(nextContent);
    onClose();
    setUbicacion(data);
    nextContent(frm, data);
  };

  useEffect(() => {

    if (exp) {
      let selectes = [];
      exp.extra.forEach((item) => {
        selectes.push(item);
      });
      let duration = exp.duration.split(" ")[0]
      setForm({ ...exp, extra: selectes, minQuotas: exp.quotas.min, maxQuotas: exp.quotas.max, duration });
      setUbicacion({ lat: parseFloat(exp.lat), lng: parseFloat(exp.long) });
      nextContent(
        { ...exp, extra: selectes, minQuotas: exp.quotas.min, maxQuotas: exp.quotas.max, duration },
        { lat: parseFloat(exp.lat), lng: parseFloat(exp.long) }
      );
    }
  }, []);

  return (
    <div className="container-sm">
      {!edit && (
        <nav
          className="navbar-titles navbar-light 
            d-flex 
            align-items-center"
        >
          <button type="button" className="back-window-navbar" onClick={Back}>
            <i className="s icon-left-arrow"></i>
          </button>
          <h5 className="bold">¡Empecemos!</h5>
        </nav>
      )}

      <Form
        fluid
        ref={formRef}
        model={model}
        onChange={(formValue) => {
          setForm(formValue);

          nextContent(formValue, ubicacion);
        }}
        formValue={form}
      >
        <Row style={{ paddingTop: "6rem", paddingBottom: "5rem" }}>
          <Col md={14}>
            <FormGroup>
              <ControlLabel>Ponle nombre a tu experiencia</ControlLabel>
              <FormControl
                className="form-control"
                name={"name"}
                value={form.name}
                placeholder={"Un nombre super cool"}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Donde comienza</ControlLabel>
              <FormControl
                className="form-control"
                name={"start"}
                value={form.start}
                placeholder={
                  "Destino donde recibirás o recogerás a tus viajeros"
                }
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>¿Cuantas horas dura?</ControlLabel>
              <FormControl
                className="form-control"
                name="duration"
                value={form.duration}
                placeholder="2 h"
                min={0}
                max={24}
                accepter={InputNumber}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>¿Cuéntanos que haremos?</ControlLabel>
              <FormControl
                className="form-control-textarea-host"
                name={"description"}
                value={form.description}
                placeholder={"Qué osadías experimentaremos"}
                componentClass="textarea"

              />
            </FormGroup>
          </Col>
          <Col md={10}>
            <FormGroup>
              <ControlLabel>¿Qué incluye?</ControlLabel>
              <FormControl
                className="color form-control"
                value={form.extra}
                name={"extra"}
                // onSelect={(label, item) => datos(item.icon)}
                placeholder={"Transporte, Alimentación"}
                accepter={CheckPicker}
                data={dataExtras}
                searchable={true}
                renderMenuItem={(label, item) => {
                  return (
                    <div key={label}>
                      <i className={`rs-icon rs-icon-${item.icon}`} /> {label}
                    </div>
                  );
                }}
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel>Donde termina</ControlLabel>
              <FormControl
                className="form-control"
                name={"end"}
                value={form.end}
                placeholder={"Destino donde terminará la experiencia"}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Cupo mínimo</ControlLabel>
              <Col
                md={12}
                className="form-control rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mb-2"
              >
                <FormControl
                  className=""
                  name="minQuotas"
                  value={form.minQuotas}
                  placeholder={"0"}
                  max={50}
                  min={0}
                  accepter={InputNumber}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Cupo máximo</ControlLabel>
              <Col
                md={12}
                className="form-control rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mb-2"
              >
                <FormControl
                  className=""
                  name="maxQuotas"
                  value={form.maxQuotas}
                  placeholder={"0"}
                  max={50}
                  min={0}
                  accepter={InputNumber}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <ControlLabel>País</ControlLabel>
              <FormControl
                className="form-control"
                name={"direction"}
                value={form.direction}
                placeholder={"Localidad donde se vivirá la experiencia"}
              />
            </FormGroup>
            <button
              type="button"
              onClick={showDrawer}
              className="btn btn__bancos bold"
            >
              <i className="demo-icon-first icon-compass"></i>
              Agregar ubicación
            </button>
            <Drawer style={{ width: "100%" }} show={visible} onHide={onClose}>
              <Drawer.Body>
                <Grid fluid>
                  <AgregarUbicacion
                    dataUb={ubicacion}
                    direccionn={onDireccion}
                  />
                </Grid>
              </Drawer.Body>
            </Drawer>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ExperienciaNewContent;
