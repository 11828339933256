import React, { useContext, useState, useEffect } from "react";
import { Avatar } from "antd";
import { Modal, Button, Alert, Input } from "rsuite";
import axios from "axios";
// Importacion de recursos
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_PATH } from "../../constants";
// import { updateReturn } from "typescript";

export const MenuDesplegable = ({ rol }) => {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [colorMenu, setColorMenu] = useState("neon_blue");
  const [colorReverse, setColorReverse] = useState("neon_blue");
  const [picture, setPicture] = useState("");
  const [changeRol, setChangeRol] = useState("");
  const [name, setname] = useState("");
  const [petition, setpetition] = useState("");

  const Configuraciones = () => {
    if (rol === "Viajero") {
      history.replace({ pathname: "/configuraciones" });
    } else {
      history.replace({ pathname: "/host/configuraciones" });
    }
  };

  const changeUser = async () => {
    if (user.data.user.rolRequest) {
      let switchh = 0;
      if (rol === "Viajero") {
        switchh = 1;
      }
      let headers = {
        Authorization: `Bearer ${user.data.token}`,
        "user-switch": switchh,
      };
      let change = await axios.post(
        `${BASE_PATH}/users/switchUser/`,
        {},
        { headers: headers }
      );
      //console.log(change);
      if (change.status == 200) {
        dispatch({
          type: types.login,
          payload: {
            data: {
              token: user.data.token,
              user: change.data.user,
            },
          },
        });
        if (change.data.user.state == 1) {
          history.replace({ pathname: `/host/_/${user.data.user.id}` });
        } else if (change.data.user.state == 0) {
          history.replace({ pathname: "/" });
        }
      }
    } else if (user.data.user.rolRequest === undefined) {
      setOpenModal(true);
    } else if (user.data.user.rolRequest === false) {
      Alert.info("Su solicitud está siendo evaluada.", 2000);
    }
  };

  const setPetition = async () => {
    if (petition.length > 10) {
      let request = await axios.post(
        `${BASE_PATH}/users/switch-request/`,
        {
          email: "josue9aparicio@gmail.com",
          reason: petition,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );
      if (request.status === 200) {
        Alert.success("Se ha enviado la solicitud!", 2000);
        setOpenModal(false);
        //console.log(request);
        dispatch({ type: types.login, payload: request });
      } else {
        Alert.error("Intentalo de nuevo.", 2000);
      }
    } else {
      Alert.info("Agrega un texto mas largo.", 2000);
    }
  };
  const cerrar = () => {
    history.replace("/");
    dispatch({ type: types.logout });
  };

  useEffect(() => {
    if (rol === "Viajero") {
      setColorMenu("orange");
      setColorReverse("neon_blue");
      setChangeRol("Anfitrión");
    } else {
      setColorMenu("neon_blue");
      setColorReverse("orange");
      setChangeRol("Viajero");
    }

    if (user.data.user) {
      if (user.data.user.state == 1) {
        setname(user.data.user.infoExtra.name);
        user.data.user.infoExtra
          ? user.data.user.infoExtra.picture
            ? setPicture(user.data.user.infoExtra.picture)
            : setPicture(user.data.user.picture)
          : setPicture(user.data.user.picture);
      } else if (!user.data.user.state || user.data.user.state == 0) {
        setPicture(user.data.user.picture);
        setname(user.data.user.name);
      }
    }
  }, [user]);

  return (
    <>
      <div className="dropdown">
        <button
          type="button"
          className="dropdown-avatar image04"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Avatar size={53} src={picture} />
        </button>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenu2"
        >
          <h4 className="bold text-center">
            {name ? name : user.data.user.name}{" "}
          </h4>
          <button
            onClick={Configuraciones}
            className="dropdown-item text-white bold"
            type="button"
          >
            <i className={`demo-icon-menu icon-settings ${colorMenu}`}></i>
            Configuraciones
          </button>
          <button
            onClick={changeUser}
            className="dropdown-item text-white bold"
            type="button"
          >
            <i className={`demo-icon-menu icon-swap ${colorReverse}`}></i>
            {changeRol}{" "}
          </button>
          <li className="dropdown-divider"></li>
          <button
            onClick={cerrar}
            className="dropdown-item text-white bold"
            type="button"
          >
            <i className={`demo-icon-menu icon-settings ${colorMenu}`}></i>
            Cerrar Sesión
          </button>
        </div>
      </div>
      <Modal show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header>
          <Modal.Title>Solicitud de permisos de Anfitrion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "12px" }}>
            Describe brevemente el motivo que tienes para ser Anfitrion en
            Tugo!, recuerda nuestro primer filtro será tienes algún Certificado
            para ser Guía Local o de tu especialidad
          </p>

          <Input
            as="textarea"
            rows={3}
            placeholder="Breve motivo..."
            onChange={(value) => setpetition(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={setPetition} style={{ background: "#fe5000" }}>
            Enviar
          </Button>
          <Button onClick={() => setOpenModal(false)} appearance="subtle">
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
