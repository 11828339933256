import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import { } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

const ExperienciaNewPagar = ({ exp, nextContent, descuento, cupon }) => {
  const [adulto, setAdulto] = useState();
  const [nino, setnino] = useState();
  const [coupon, setcoupon] = useState({ discount: "", code: "" });
  const [discount, setdiscount] = useState({ price: "", people: "" });
  const [pricesWithComisions, setpricesWithComisions] = useState({
    adult: 0,
    children: 0,
  });


  const calculatedComissions = (price) => {

    const parsePrice  = parseFloat(price)
    const comisionVSNet = parsePrice + (parsePrice * parseFloat(process.env.REACT_APP_VSNET_COMISION)) / 100;
    const total = comisionVSNet + (comisionVSNet * parseFloat(process.env.REACT_APP_TUGO_COMISION)) / 100;

    return total

  }

  const handleChange = (value, name) => {

    const totalComissions = calculatedComissions(value)

    const evaluateTotalComissions = totalComissions ? totalComissions.toFixed(2) : 0;

    if (name === "adult") {
      setAdulto(value);
      nextContent({ adultos: value, ninos: nino });
      setpricesWithComisions({
        ...pricesWithComisions,
        adult: evaluateTotalComissions,
      });
    } else if (name === "children") {
      setnino(value);
      nextContent({ adultos: adulto, ninos: value });
      setpricesWithComisions({
        ...pricesWithComisions,
        children: evaluateTotalComissions,
      });
    }
  };

  useEffect(() => {
    //console.log(exp)

    if (exp) {
      //setEditData(exp)
      let ecupon = {
        discount: exp.price.coupon
          ? exp.price.coupon.discount
            ? exp.price.coupon.discount
            : ""
          : "",
        code: exp.price.coupon
          ? exp.price.coupon.code
            ? exp.price.coupon.code
            : ""
          : "",
      };
      let edescuento = {
        price: exp.price.discount
          ? exp.price.discount.price
            ? exp.price.discount.price
            : ""
          : "",
        people: exp.price.discount
          ? exp.price.discount.people
            ? exp.price.discount.people
            : ""
          : "",
      };
      //console.log('precios ...', exp.price);
      setAdulto(exp.price.adultPrice);
      setnino(exp.price.childrenPrice);
      setpricesWithComisions({
        children: exp.price.childrenPriceWithCommissions,
        adult: exp.price.adultPriceWithCommissions,
      });
      nextContent({
        adultos: exp.price.adultPrice,
        ninos: exp.price.childrenPrice,
      });
      setcoupon(ecupon);
      cupon(ecupon);
      setdiscount(edescuento);
      descuento(edescuento);
      //setNewPrecios({adutos : exp.prices.adultPrice, ninos : exp.prices.childrenPrice})
    }
  }, []);

  return (
    <div className="container-sm page_pay">

      <section lg={10} xs={12}>
        <h5 className="bold">Pon precio a tu experiencia</h5>
        <text style={{ fontSize: 16 }}>
          Si no varian los precios de edades puedes dejar el campo vacio, en tu
          precio toma en cuenta que nos dejarás una comisión del 15%.
        </text>
        <Row className="mt-4">
          <Col lg={6} xs={6}>
            <Form>
              <Form.Label>
                Adultos <text className="text-grey" style={{fontSize:'16px'}}>+ Comisión Tugo <text className="bold">15%</text> = ${pricesWithComisions.adult || 0}</text>
              </Form.Label>
              <CurrencyInput
                className="form-control"
                id="input-example-adulto"
                name="adult"
                placeholder="USD$ 25.20"
                value={adulto}
                prefix={"$"}
                decimalsLimit={2}
                onValueChange={handleChange}
              />
            </Form>
          </Col>
          <Col lg={6} xs={6}>
            <Form className="">
              <Form.Label>
                Niños <text className="text-grey" style={{fontSize:'16px'}}>+ Comisión Tugo <text className="bold">15%</text> = ${pricesWithComisions.children || 0}</text>
              </Form.Label>
              <CurrencyInput
                className="form-control"
                id="input-example-nino"
                name="children"
                value={nino}
                prefix={"$"}
                placeholder="USD$ 18.20"
                decimalsLimit={2}
                onValueChange={handleChange}
              />
            </Form>
          </Col>
          {/*<Col lg={6} xs={6}>
            <Form className="mt-3">
              <Form.Label>Bebes</Form.Label>
              <CurrencyInput
                className="form-control"
                id="input-example"
                name="input-name"
                prefix={"$"}
                placeholder="USD$ 5.20"
                decimalsLimit={2}
                onValueChange={(value, name) => console.log(value, name)}
              />
            </Form>
  </Col>*/}

          <Col lg={6}>
            <Form className="mt-5">
              <Form.Label className="bold">Cantidad de personas</Form.Label>
              <p style={{ fontSize: 16 }}>
                Agrega la cantidad minima de personas para aplicar el descuento
              </p>
              <CurrencyInput
                className="form-control"
                name="input-name"
                value={discount.people}
                placeholder="10 personas"
                decimalsLimit={2}
                onValueChange={(value) => {
                  setdiscount({ ...discount, people: value });
                  descuento({ price: discount.price, people: value });
                }}
              />
            </Form>
          </Col>
          <Col lg={6}>
            <Form className="mt-5">
              <Form.Label className="bold">Aplica descuento </Form.Label>
              <p style={{ fontSize: 16 }}>
                Descuento a aplicar desde los {discount.people} cupos reservados
              </p>
              {/* <CurrencyInput
                    className="form-control"
                    id="input-example"
                    name="input-name"
                    placeholder="USD$ 2.20 "
                    decimalsLimit={2}
                    
                    onValueChange={(value, name) => console.log(value, name)}
                  /> */}
              <CurrencyInput
                className="form-control"
                name="input-name"
                value={discount.price}
                prefix={"$"}
                placeholder="USD$ 5.25"
                decimalsLimit={2}
                onValueChange={(value) => {
                  setdiscount({ ...discount, price: value });
                  descuento({ price: value, people: discount.people });
                }}
              />
            </Form>
          </Col>
          <Col lg={6}>
            <Form className="mt-5">
              <Form.Label className="bold">Cupón</Form.Label>
              <p style={{ fontSize: 16 }}>
                Puedes generar cupones de descuento para compartir.
              </p>
              <FormControl
                type="text"
                placeholder="Ej: PROMOSURFGT"
                maxLength={11}
                minLength={11}
                className="form-control"
                value={coupon.code}
                onChange={(e) => {
                  setcoupon({ ...coupon, code: e.target.value });
                  cupon({ discount: coupon.discount, code: e.target.value });
                }}
              />
            </Form>
          </Col>
          <Col lg={6}>
            <Form className="mt-5">
              <Form.Label className="bold">Porcentaje</Form.Label>
              <p style={{ fontSize: 16 }}>
                Agrega el porcentaje de descuento del cupón
              </p>
              <CurrencyInput
                max={100}
                className="form-control"
                name="input-name"
                value={coupon.discount}
                placeholder="15%"
                prefix={"% "}
                decimalsLimit={2}
                onValueChange={(value) => {
                  setcoupon({ ...coupon, discount: value });
                  cupon({ discount: value, code: coupon.code });
                }}
              />
            </Form>
          </Col>
        </Row>
      </section>
      <br />
    </div>
  );
};

export default ExperienciaNewPagar;
