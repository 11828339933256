import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/App.css";
import "antd/dist/antd.css";
import { Search } from "@chatscope/chat-ui-kit-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Avatar } from "antd";

import TranslateButton from '../GoogleTraslate/TranslateButton';


//Importar Recursos
import logo from "../../assets/images/logoDark.svg";
import nft from "../../assets/images/money-bag.png"
import "../../assets/css/iconos/css/fontello.css";
import { AuthContext } from "../../auth/AuthContext";

import { MenuDesplegable } from "./elementos/MenuDesplegable";
import NFT from "../NFT/NFT";



const Viajero = ({ searchExp = "" }) => {
  const { user } = useContext(AuthContext);

  const search = (value) => {
    //console.log(value)
    searchExp(value);
  };

  const history = useHistory();

  const NFT = () => {
    history.replace({ pathname: "/nft" });
  };

  return (
    <div className="menu-desp">
      <div className="d-none d-md-none d-lg-block">
        <nav className="navbar navbar-light fixed-top">
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          <div className="d-none d-md-none d-lg-block navbar-search">
            <Link to="explora" style={{ textDecoration: "none" }}>
              <Search
                placeholder="Prueba ..."
                onChange={search}
                onClearClick={() => searchExp("")}
              />
            </Link>
          </div>
          <ul className="navbar-nav navbar-first nav-pills d-none d-md-none d-lg-block">
            <li>

              {user.logged && (
                <>
                  <NavLink
                    to="/"
                    exact
                    className="heavy padding btn-primary-menu-first"
                    activeClassName="nav-link-active"
                  >
                    <i className="demo-icon-first icon-home"></i>
                    Para tí
                  </NavLink>
                  <NavLink
                    to="/planes"
                    className="heavy padding btn-primary-menu-first"
                    activeClassName="nav-link-active"
                  >
                    <i className="demo-icon-first icon-luggage "></i>
                    Planes
                  </NavLink>
                  <NavLink
                    to="/chat"
                    className="heavy btn-primary-menu-first padding"
                    activeClassName="nav-link-active"
                  /*</>style={{ marginRight: "1.7rem" }}*/
                  >
                    <i className="demo-icon-first icon-chat"></i>
                    Chat
                  </NavLink>


                </>
              )}
              {user.logged === false ? (
                <div>
                  <TranslateButton />
                  {/*<NavLink to="/tuto"
                    className="heavy padding btn-primary-menu-first"
                    activeClassName="nav-link-active">
                    ¿Qué es Tugo?
              </NavLink>*/}

                  <Link to="/login">
                    <button
                      type="button"
                      className="btn btn-primary-login heavy"
                      variant="primary"
                    >
                      Ingresar
                    </button>
                  </Link>
                  {/*<button className="exp-fixed-bottom-help  bold" onClick={Tutorial}>
                    ¿Qué es Tugo?
                  </button>*/}
                </div>
              ) : (
                <></>
              )}
            </li>
          </ul>
          {user.logged === true ? (
            <MenuDesplegable rol={"Viajero"}></MenuDesplegable>
          ) : (
            <div className="dropdown">
              <div
                className="dropdown-menu 
                            dropdown-menu-right"
                aria-labelledby="dropdownMenu2"
              >
                <h4 className="bold text-center">Pablo Sierra</h4>
                <Link to="/configviajero" style={{ textDecoration: "none" }}>
                  <button
                    className="dropdown-item text-white bold"
                    type="button"
                  >
                    <i className="demo-icon-menu icon-settings orange"></i>
                    Configuraciones
                  </button>
                </Link>
              </div>
            </div>
          )}
        </nav>
      </div>

      {user.logged && (
        <>
          <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none text-center">
            <nav className="navbar-sm fixed-bottom">
              {/*<button className="btn-crypto bold" onClick={NFT}>
            <img src={nft} className="nft" />
          </button>*/}
              <ul className="navbar-nav">
                <li>
                  <NavLink
                    to="/"
                    className="bold btn-primary-menu-first-sm"
                    activeClassName="nav-link-active-sm"
                  >
                    <i className="demo-icon icon-home"></i>
                  </NavLink>

                  <NavLink
                    to="/explora"
                    className="bold btn-primary-menu-first-sm"
                    activeClassName="nav-link-active-sm"
                  >
                    <i className="demo-icon icon-search"></i>
                  </NavLink>


                  <NavLink
                    to="/planes"
                    className="bold btn-primary-menu-first-sm"
                    activeClassName="nav-link-active-sm"
                  >
                    <i className="demo-icon icon-luggage "></i>
                  </NavLink>



                  <NavLink
                    to="/chat"
                    className="bold btn-primary-menu-first-sm"
                    activeClassName="nav-link-active-sm"
                  >
                    <i className="demo-icon icon-comment"></i>
                  </NavLink>


                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default Viajero;
