import React, { useEffect } from 'react';
import './TranslateButton.css';

import en from "../../assets/images/en.png";
import es from "../../assets/images/es.png";

const TranslateButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(script);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        includedLanguages: 'en,es,fr,pt',
        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
      }, 'google_translate_element');
    };
  }, []);

  const updateLanguage = (value) => {
    const selectElement = document.querySelector('#google_translate_element .goog-te-combo');

    if (selectElement) {
      selectElement.value = value;
      selectElement.dispatchEvent(new Event('change'));
    }
  };

  const handleLanguageClick = (value) => {
    updateLanguage(value);
  };

  return (
    <span className='lenguages bold'>
      <div id="google_translate_element" ></div>
      <button onClick={() => handleLanguageClick('en')} className="languageOption" data-lang="en">
        <img src={en} style={{marginRight:'0.4rem'}}/>EN
      </button>
      <button onClick={() => handleLanguageClick('es')} className="languageOption" data-lang="es">
        <img src={es} style={{marginRight:'0.4rem'}}/>ES
      </button>
      {/*<button onClick={() => handleLanguageClick('fr')} className="languageOption" data-lang="fr">Frances</button>
      <button onClick={() => handleLanguageClick('pt')} className="languageOption" data-lang="pt">Portugués</button>*/}
    </span>

  );
};

export default TranslateButton;
