import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_PATH } from "../constants";
import { Grid, Row } from "rsuite";
import { Skeleton } from "antd";
import {
    DaysInMonth,
    daysOfWeekObject,
    getAvailableMonths,
    isIncludedDayInFirstWeek,
    monthsInYear,
    setLeftZeroInNumber,
    setTimeInDate,
} from "../Anfitrion/Experiencias/schedules/utils";
import MonthCard from "./schedules/month";
import ScheduleCard from "./schedules/scheduleCard";

const scheduleSelectInit = { id: null, date: null, time: null }

const ExperienciaCalendar = ({ setSchedule, experienceId }) => {

    const [schedules, setschedules] = useState([]);
    const [showSelect, setshowSelect] = useState(0);
    const [loading, setloading] = useState(true);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [availableSchedules, setAvailableSchedules] = useState([]);
    const [scheduleSelected, setscheduleSelected] = useState(null)
    const [filterSchedules, setfilterSchedules] = useState({
        year: null,
        month: null,
        week: null,
    });

    const backSelect = () => {

        if (scheduleSelected) {
            setSchedule(scheduleSelectInit)
            setscheduleSelected(null)
        }

        setshowSelect(showSelect - 1);
    }

    const onSelectSchedule = (schedule, index, isSelected) => {

        let newArr = [...availableSchedules.map((el) => {
            return {
                ...el,
                isSelected: false
            }
        })]

        newArr[index] = { ...newArr[index], isSelected }
        setAvailableSchedules(newArr)

        if (!isSelected) {
            setSchedule(scheduleSelectInit)
            setscheduleSelected(null)

        } else {
            setscheduleSelected(schedule)
            setSchedule({ id: schedule.id, date: schedule.date, time: schedule.time })
        }
    }

    const selectMonth = ({ year, month }) => {
        setloading(true);
        setshowSelect(1);
        setAvailableSchedules([])

        const lastMonthDay = DaysInMonth(year, month)
        const initMonthDate = new Date(year, month, 0)
        const lastMonthDate = new Date(year, month, lastMonthDay)

        let currentMonthDate = initMonthDate
        const daysInSelectMonth = []

        while (currentMonthDate.getTime() < lastMonthDate.getTime()) {

            let newCurrentDay = currentMonthDate.setDate(currentMonthDate.getDate() + 1)
            let newCurrentMonthDate = new Date(newCurrentDay)
            daysInSelectMonth.push(newCurrentMonthDate)
        }

        lastMonthDate.setHours(23)
        lastMonthDate.setMinutes(59)

        daysInSelectMonth[daysInSelectMonth.length - 1] = lastMonthDate

        const schedulesInSelectMonth = schedules.reduce((acc, current) => {

            const { date, dueDate, periodicity, id, start } = current
            const { days, repeat } = periodicity

            const initScheduleDate = new Date(date)
            const dueScheduleDate = new Date(dueDate)
            initScheduleDate.setHours(0)
            initScheduleDate.setMinutes(0)
            dueScheduleDate.setHours(23)
            dueScheduleDate.setMinutes(59)

            const daysAvailablesInMonth = daysInSelectMonth.filter((dayDate) => {
                const currentDayDateTime = new Date(dayDate).getTime()

                return currentDayDateTime >= initScheduleDate.getTime() && currentDayDateTime <= dueScheduleDate.getTime()

            }).filter((dayAvailable) => days.includes(new Date(dayAvailable).getDay()))

            const integrateSchedules = []

            for (const dayAvailable of daysAvailablesInMonth) {

                if (repeat === 'monthly') {

                    const isFisrtWeek = isIncludedDayInFirstWeek(new Date(dayAvailable).getDate())

                    if (isFisrtWeek) {

                        integrateSchedules.push({
                            id,
                            date: new Date(setTimeInDate(start, dayAvailable)),
                            time: start,
                            repeat,
                            isSelected: false
                        })
                    }

                } else {
                    integrateSchedules.push({
                        id,
                        date: new Date(setTimeInDate(start, dayAvailable)),
                        time: start,
                        repeat,
                        isSelected: false
                    })
                }
            }

            return acc.concat(integrateSchedules)

        }, [])

        setfilterSchedules({ ...filterSchedules, month, year });
        const sortedSchedulesInSelectMonth = schedulesInSelectMonth.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        setAvailableSchedules(sortedSchedulesInSelectMonth)
        setloading(false);

    };

    const getSchedules = async () => {
        try {
            let res = await axios.get(
                `${BASE_PATH}/schedules?experience=${experienceId}`
            );
            if (res.status === 200) {
                const currentDate = new Date().getTime();
                const filterAvailableDates = res.data.rows.filter(
                    ({ dueDate, isEnabled }) => dueDate && new Date(dueDate).getTime() >= currentDate && isEnabled
                );

                setschedules(filterAvailableDates);
                setloading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        setAvailableMonths(getAvailableMonths());
        getSchedules();
    }, []);


    return (
        <Grid fluid className="container-sm">
            <nav className="navbar-titles navbar-light d-flex align-items-center"></nav>

            <Row className="calendar container-fluid" style={{ paddingTop: "5rem" }}>
                <text className="heavy text-titulos-reserva orange__gradient">Escoge tu fecha</text>
                <div className="schedule-page-subtitlex">
                    {showSelect > 0 && (
                        <button
                            onClick={backSelect}
                            className="back-window">
                            <i className=" icon-left-arrow"></i>
                        </button>
                    )}
                    <text className="bold orange" style={{ marginLeft: '1rem' }}>
                        {showSelect === 0 && ""}
        
                        {showSelect === 1 &&
                            `¡${monthsInYear[filterSchedules.month]}, ${filterSchedules.year}!`}
                    </text>
                </div>

                <div
                    style={{ marginBottom: "15rem", textAlign: "center" }}
                    className="justify-content-center"
                >

                    {loading && (
                        <div className="schedules-page-content-user-grid">
                            {
                                [1, 2, 2, 3, 4, 5,].map((_, index) => (
                                    <article className="schedule-item-loader" key={index}>
                                        <Skeleton title={false} loading={loading} active />
                                    </article>
                                ))}
                        </div>
                    )}

                    {!loading && showSelect === 0 && schedules.length > 0 && (
                        <div className="schedules-page-content-user-grid">
                            {availableMonths.map((props, i) => (
                                <MonthCard
                                    key={`month_key_${i}`}
                                    {...props}
                                    onSelect={() => selectMonth(props)}
                                />
                            ))}
                        </div>

                    )}

                    {!loading && showSelect === 1 && (
                        <div className="schedules-page-content-user-grid-4">
                            {availableSchedules.map((schedule, i) => (
                                <ScheduleCard
                                    key={`schedule_key_${schedule.id}_${i}`}
                                    dayString={daysOfWeekObject[new Date(schedule.date).getDay()]}
                                    dayNumber={setLeftZeroInNumber(new Date(schedule.date).getDate())}
                                    time={schedule.time}
                                    isSelected={schedule.isSelected}
                                    onSelect={() => onSelectSchedule(schedule, i, !schedule.isSelected)}
                                />
                            ))}
                        </div>
                    )}


                    {
                        !loading && showSelect === 0 && schedules.length === 0 && (
                            <div className="schedules-no-items">
                                <text className="bold ">
                                    No hay horarios disponibles para esta experiencia
                                </text>
                            </div>)
                    }

                    {
                        !loading && showSelect === 1 && availableSchedules.length === 0 && (
                            <div className="schedules-no-items">
                                <text className="bold ">
                                    {`No hay horarios disponibles para ${monthsInYear[filterSchedules.month]}, ${filterSchedules.year}`}
                                </text>

                                <button onClick={backSelect} className="bact-to-week-btn">Otro mes</button>
                            </div>)
                    }

                </div>
            </Row>
        </Grid>
    );
};

export default ExperienciaCalendar;