import React, { useState } from "react";
import { FormControl, Icon, IconButton, InputGroup } from "rsuite";

const InputPassword = ({ value, setValue, placeholder, name, changeEvent }) => {
  const [show, setshow] = useState(false);

  const handleShowPassword = () => setshow(!show);

  return (
    <InputGroup inside>
      <FormControl
        className="form-control text-pass btn-lg "
        value={value}
        onChange={changeEvent ? (val) => setValue(val) : () => {}}
        placeholder={placeholder}
        type={show ? "text" : "password"}
        name={name}
      />
      <InputGroup.Addon style={{ height: "100%", background: "transparent" }}>
        <IconButton
          onClick={handleShowPassword}
          icon={<Icon icon={show ? "eye-slash" : "eye"} />}
          circle
          size="xs"
        />
      </InputGroup.Addon>
    </InputGroup>
  );
};

InputPassword.defaultProps = {
  value: "",
  setValue: () => {},
  placeholder: "Contraseña",
  name: "input-name",
  changeEvent: true,
};

export default InputPassword;
